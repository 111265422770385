import React, {Component} from 'react';
import { createStyles, Theme, withStyles, Dialog, Link } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import Loading from '../loading/Loading';
import Error from '../error/Error';
import IApplication from '../../../../../core/lib/entities/IApplication';
import {IAppCredentials} from '../../../../../core/lib/entities/IAppCredentials';
import AppCredentialsUseCase from '../../../../../core/lib/useCases/AppCredentialsUseCase';
import AppResetPasswordUseCase from '../../../../../core/lib/useCases/AppResetPasswordUseCase';
import AppResetApiKeyUseCase from '../../../../../core/lib/useCases/AppResetApiKeyUseCase';
import DownloadManager from '../../../../../core/lib/frameworks/downloadmanager/DownloadManager';
import { IAppPassword } from '../../../../../core/lib/entities/IAppPassword';

const styles = (theme: Theme) =>
  createStyles({
    dialogTitle: {
      height: "44px",
      backgroundColor: "#222e3e",
      textAlign: "center",
      color: "#ffffff",
      alignItems: "center",
      justifyContent: "center",
      display: "flex"
    },
    dialogContainer: {
      width: "100%",
      backgroundColor: "#d8d8d8"
    },
    labelTop: {
      display: "flex",
      fontSize: "18px",
      color: "#ffffff",
      width: "100%",
      justifyContent: "center"
    },
    content: {
      height: "220px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "16px",
      fontWeight: "bold"
    },
    buttonDef: {
      borderRadius: "5px",
      margin: "8px",
      border: "solid 1px #ad8d40",
      boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.75)",
      height: "60%",
      fontSize: "12px",
      width: "163px",
      textTransform: "capitalize",
      backgroundImage: "linear-gradient(to bottom, #ffffff, #f7dea0 9%, #f1c24f)",
      "&:hover": {
        boxShadow: "1px 1px 3px #ccc"
      }
    },
    footerActions: {
      display: "flex",
      justifyContent: "center"
    },
    contentcontainer: {
      width: "450px",
      minHeight: "200px",
      backgroundColor: "#fff",
      boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.5)",
      marginTop: "10px",
      borderRadius: "8px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column"
    },
    contentbox: {
      width: "550px",
      minHeight: "200px",
      backgroundColor: "#fff",
      boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.5)",
      marginTop: "10px",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column"
    },
    emptyresponse: {
      fontWeight: "normal",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "13px",
      textAlign: "center",
      width: "100%",
      height: "200px",
      flexDirection: "column"
    },
    boxdetails: {
      marginLeft: "10px",
      marginTop: "10px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: 'center',
      textAlign: "center"
    },
    appname: {
      fontSize: "26px"
    },
    downloadcredentials: {
      marginTop: "20px",
      fontSize: "14px",
      cursor: "pointer"
    },
    secretinfo: {
      cursor: "text",
      fontSize: "14px",
      fontWeight: "normal"
    },
    secretvalue: {
      userSelect: "all"
    },
    containerreset: {
      marginTop: "20px"
    },
    confirmbox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      height: "200px"
    },
    confirmboxbuttons: {
      display: "flex",
      flexDirection: "row",
      marginTop: "20px"
    }
  });

interface IProps {
  classes: any,
  isOpen: boolean,
  application: IApplication,
  handleClose: () => void
}

class APIConfigModal extends Component<IProps> {
  public state = {isLoading: false, hasErrors: false, emptyResponse: false, confirmresetapi: false, confirmresetpass: false, showPassword: false};
  private credentials = {} as IAppCredentials;
  private tempPassword: any;

  public componentDidMount() {
    this.loadConfig();
  }

  public loadConfig = async () => {
    this.setState({isLoading: true});

    const {application} = this.props;
    const useCase = new AppCredentialsUseCase();
    const result = await useCase.execute(application.applicationId);

    if (result.ok) {
      this.setState({hasErrors: false});

      if ((result.data as any).apiKey !== undefined) {
        this.credentials = result.data as IAppCredentials;
        this.setState({hasErrors: false, emptyResponse: false});
      } else {
        this.setState({hasErrors: false, emptyResponse: true});
      }
    } else {
      this.setState({hasErrors: true});
    }

    this.setState({confirmresetapi: false, confirmresetpass: false});
    this.setState({isLoading: false});
  }

  private downloadCredentials = () => {
    const downloadManager = new DownloadManager();
    downloadManager.download(JSON.stringify(this.credentials), "credentials.json");
  }

  private openResetAPI = () => {
    this.setState({confirmresetapi: true});
  }

  private openResetPass = () => {
    this.setState({confirmresetpass: true});
  }

  private hideResetAPI = () => {
    this.setState({confirmresetapi: false});
  }

  private hideResetPass = () => {
    this.setState({confirmresetpass: false});
  }

  private resetPass = async () => {
    const {application} = this.props;
    this.setState({isLoading:  true});
    const useCase = new AppResetPasswordUseCase();
    const result = await useCase.execute(application.applicationId);
    if (result.ok) {
      this.tempPassword = (result.data as IAppPassword).newPassword;
      this.setState({showPassword:  true});
    }
    this.loadConfig();
  }

  private resetApiKey = async () => {
    const {application} = this.props;
    this.setState({isLoading:  true});
    const useCase = new AppResetApiKeyUseCase();
    await useCase.execute(application.applicationId);
    this.loadConfig();
  }

  public render() {
    const {classes, isOpen, application, handleClose} = this.props;
    const {isLoading, hasErrors, emptyResponse, confirmresetapi, confirmresetpass, showPassword} = this.state;

    const hidePassword = () => {
      this.tempPassword = "";
      this.setState({showPassword: false});
    }

    return (
      <Dialog classes={{paper: classes.dialogContainer}} open={isOpen} fullWidth={true}>
          <DialogTitle className={classes.dialogTitle}>
          <div className={classes.labelTop}>
            <FormattedMessage id="app.btnapidetails" />
          </div>
        </DialogTitle>

        <DialogContent className={classes.content}>
          <div className={classes.contentbox}>
            {
              isLoading && (
                <Loading />
              )
            }

            {
              !isLoading && hasErrors && (
                <Error />
              )
            }

            {
              !isLoading && !hasErrors && emptyResponse && (
                <div className={classes.emptyresponse}>
                  <div><FormattedMessage id="app.resettopmsg" /></div>
                  <div><FormattedMessage id="app.resetbottommsg" /></div>
                </div>
              )
            }

            {
              !isLoading && !hasErrors && !emptyResponse && confirmresetpass && (
                <div className={classes.confirmbox}>
                    <div><FormattedMessage id="app.resetpassword"/></div>
                    <div className={classes.confirmboxbuttons}>
                      <Button
                        className={classes.buttonDef}
                        onClick={this.resetPass}>
                        <FormattedMessage id="app.btnyes" />
                      </Button>

                      <Button
                        className={classes.buttonDef}
                        onClick={this.hideResetPass}>
                        <FormattedMessage id="app.btnno" />
                      </Button>
                    </div>
                </div>
              )
            }

            {
              !isLoading && !hasErrors && !emptyResponse && confirmresetapi && (
                <div className={classes.confirmbox}>
                  <div><FormattedMessage id="app.resetapikey"/></div>
                  <div className={classes.confirmboxbuttons}>
                    <Button
                      className={classes.buttonDef}
                      onClick={this.resetApiKey}>
                      <FormattedMessage id="app.btnyes" />
                    </Button>

                    <Button
                      className={classes.buttonDef}
                      onClick={this.hideResetAPI}>
                      <FormattedMessage id="app.btnno" />
                    </Button>
                  </div>
                </div>
              )
            }

            {
              !isLoading && !hasErrors && !emptyResponse && !confirmresetapi && !confirmresetpass && (
                <div className={classes.boxdetails}>
                  <div className={classes.appname}>
                    {application.name}
                  </div>
                  <div className={classes.downloadcredentials}>
                    <Link onClick={this.downloadCredentials}><FormattedMessage id="app.downloadcredentials"/></Link>
                  </div>
                  {
                    showPassword && (
                        <div className={classes.secretinfo}>
                          <b><FormattedMessage id="app.newpassword" /></b> <span className={classes.secretvalue}>{this.tempPassword}</span>
                        </div>
                    )
                  }
                  <div className={classes.containerreset}>
                    <Button
                      className={classes.buttonDef}
                      onClick={this.openResetAPI}>
                      <FormattedMessage id="app.btnresetapikey" />
                    </Button>
                    <Button
                      className={classes.buttonDef}
                      onClick={this.openResetPass}>
                      <FormattedMessage id="app.btnresetpass" />
                    </Button>
                  </div>
                </div>
              )
            }
          </div>
        </DialogContent>

        <DialogActions className={classes.footerActions}>
          <Button
            className={classes.buttonDef}
            onClick={(e) => {
              hidePassword();
              handleClose();
            }}
            disabled={confirmresetapi === true || confirmresetpass === true}>
            Ok
          </Button>
        </DialogActions>

      </Dialog>
    );
  }
}

export default withStyles(styles)(APIConfigModal);
