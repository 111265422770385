import AppCredentialsService from '../services/AppCredentialsService';

class AppCredentialsUseCase {
  public execute = async (applicationId: string) => {
    const appCredentials = new AppCredentialsService();
    const result = await appCredentials.getCredentials(applicationId);
    return result;
  }
}

export default AppCredentialsUseCase;