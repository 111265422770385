import SubscriptionService from '../services/SubscriptionService';

class GetSubscriptionsUseCase {
  public execute = async () => {
    const subscriptionService = new SubscriptionService();
    const result = await subscriptionService.getSubscriptions();

    console.log("GetSubscriptionsUseCase.result", 
    result,
    result.ok,
    result.data)

    const subcriptions = (result.ok && result.data)
    return subcriptions;
  }
}

export default GetSubscriptionsUseCase