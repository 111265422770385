import React, {Component} from 'react';
import { IAPIStage } from '../../../../../core/lib/entities/IAPIDetails';
import DocsModal from '../docsmodal/DocsModal';
import { createStyles, Theme, withStyles, Link, Button, Checkbox, FormControlLabel, Input } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import LaunchOutlined from '@material-ui/icons/LaunchOutlined';
import APITooltipDetails from '../apitooltip/APITooltipDetails';
import { IMethod, IUsagePlan } from '../../../../../core/lib/entities/IApplicationDetails';
import uuid from 'uuid';
import StageManagerStore from '../../../../../core/lib/stores/StageManagerStore';
import {observer} from 'mobx-react';
import Tooltip from '@material-ui/core/Tooltip';

interface IProps {
  classes?: any,
  intl?: any,
  stage: IAPIStage
  appId: string,
  handleSave: (data: StageManagerStore) => void
}

const styles = (theme: Theme) =>
createStyles({
  buttonDocs: {
    backgroundImage: "linear-gradient(to bottom, #fcfcfd, #f5f6f8 9%, #c4c5c6)",
    border: "1px solid #ADB1B8",
    textTransform: "capitalize",
    "&:hover": {
      boxShadow: "1px 1px 3px #ccc"
    }
  },
  urlendpoint: {
    fontSize: "15px",
    marginTop: "30px"
  },
  boxplans: {
    marginTop: "30px"
  },
  boxplanstitle: {
    fontSize: "20px"
  },
  boxplansoptions: {
    fontSize: "13px",
    marginTop: "10px",
    display: "flex",
    flexDirection: "row"
  },
  planitem: {
    marginRight: "30px"
  },
  boxmethodstitle: {
    fontSize: "20px",
    marginTop: "20px"
  },
  boxmethods: {
    marginTop: "20px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  cardmethod: {
    width: "250px",
    height: "330px",
    backgroundColor: "#fff",
    boxShadow: "1px 1px 7px 1px rgba(0, 0, 0, 0.3)",
    border: "solid 1px #ccc",
    marginRight: "20px",
    padding: "20px",
    marginBottom: "20px"
  },
  cardtitle: {
    fontSize: "20px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "inline-block",
    overflow: "hidden",
    maxWidth: "95%"
  },
  post: {
    backgroundColor: "#49CC90"
  },
  get: {
    backgroundColor: "#62AFFE"
  },
  delete: {
    backgroundColor: "#FF3333"
  },
  put: {
    backgroundColor: "#FCA12F"
  },
  cardhttpmethod: {
    width: "60px",
    height: "30px",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "4px",
    color: "#fff"
  },
  cardpath: {
    fontSize: "14px",
    marginTop: "10px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "inline-block",
    overflow: "hidden",
    maxWidth: "95%"
  },
  carddescription: {
    fontSize: "13px",
    marginTop: "4px",
    color: "#bbb"
  },
  cardplans: {
    width: "100%",
    height: "120px",
    marginTop: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  emptyplans: {
    fontSize: "12px",
    color: "#999"
  },
  plantitle: {
    width: "100px",
    fontSize: "13px"
  },
  input: {
    width: "100px"
  },
  footer: {
    width: "100%",
    height: "60px",
    borderTop: "solid 2px #bbb",
    position: "fixed",
    left: "0",
    bottom: "0px",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  buttonDef: {
    borderRadius: "5px",
    margin: "8px",
    border: "solid 1px #ad8d40",
    boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.75)",
    height: "60%",
    fontSize: "12px",
    width: "183px",
    textTransform: "capitalize",
    backgroundImage: "linear-gradient(to bottom, #ffffff, #f7dea0 9%, #f1c24f)",
    "&:hover": {
      boxShadow: "1px 1px 3px #ccc"
    }
  }
});

@observer
class StageManager extends Component<IProps> {
  private store = new StageManagerStore()
  private inputFocus = ""

  public componentDidMount() {
    const usagePlans = this.props.stage.usagePlans;
    this.store.setPlans(usagePlans ? usagePlans.filter(p => p.isShared === true) : []);
    this.store.setMethods(this.props.stage.methods)

    var methodvalues = Array<{key: string; value: number}>()

    if(this.props.stage.methods){
      this.props.stage.methods.map(m => {
        const methodId = m.httpMethod + "|" + m.resourceId
        m.usagePlans.map(u => {
          if (u.value !== null) {
            methodvalues.push({key: methodId+"|"+u.usagePlanId, value: u.value})
          }
          return null
        })
        return null
      })
    }

    this.store.setMethodValues(methodvalues)
  }

  private handleCloseDocsModal = () => {
    this.store.setOpenDocsModal(false)
  }

  private openDocsModal = () => {
    this.store.setOpenDocsModal(true)
  }

  private renderPlans = (plan: IUsagePlan) => {
    const {classes} = this.props
    const isChecked = this.store.plans.find(p => p.id === plan.id) !== undefined

    return (
      <div className={classes.planitem}>
        <FormControlLabel
          control={<Checkbox checked={isChecked} value={plan.id} onChange={this.handleChangePlan}/>}
          label={plan.name} />

        <APITooltipDetails
          plan={plan} />
      </div>
    )
  }

  private handleChangePlan = (event: any) => {
    if (!event.target.checked) {
      this.store.setPlans(this.store.plans.filter(p => p.id !== event.target.value))
    } else {
      const plans = this.store.plans
      const plan = this.props.stage.usagePlans.find(p => p.id === event.target.value)
      plans.push(plan as IUsagePlan)
      this.store.setPlans(plans)
    }
  }

  private handleChangePlanValue = (event: any) => {
    if ((!isNaN(event.target.value)) && (event.target.value.length < 4)) {
      const key = event.target.id
      var search = this.store.methodvalues.find(p => p.key === key)

      if (search) {
        search.value = event.target.value
      } else {
        search = {key: key, value: event.target.value}
      }

      var methodvalues = this.store.methodvalues.filter(p => p.key !== key)
      methodvalues.push(search)
      this.inputFocus = key
      this.store.setMethodValues(methodvalues)
    }
  }

  private renderMethodPlans = (method: IMethod, planId: string) => {
    const {classes} = this.props
    const key = method.httpMethod + "|" + method.resourceId + "|" + planId
    const plan = this.store.plans.find(plan => plan.id === planId) as IUsagePlan
    const planValueObj = this.store.methodvalues.find(planvalue => planvalue.key === key)
    const planValue = planValueObj && planValueObj.value;
    const planValueSafe = (planValue == null) ? "" : planValue;

    return (
      (plan !== undefined) && (
        <tr>
            <td className={classes.plantitle}><b>{plan.name} (U$)</b>:</td>
            <td><Input id={key} key={uuid()} value={planValueSafe} className={classes.input} onChange={this.handleChangePlanValue} autoFocus={this.inputFocus === key}/></td>
        </tr>
      )
    )
  }

  private renderHttpMethod = (method: IMethod) => {
    const {classes} = this.props

    return (
      <div className={classes.cardhttpmethod + " " +  this.getMethodColor(method.httpMethod)}>
        {method.httpMethod.toUpperCase()}
      </div>
    )
  }

  public getMethodColor = (httpmethod: string) => {
    const {classes} = this.props;

    switch (httpmethod) {
      case "POST":
        return classes.post;
      case "GET":
        return classes.get;
      case "DELETE":
        return classes.delete;
      case "PUT":
        return classes.put;
    }
  }

  private renderMethodCard = (method: IMethod) => {
    const {classes} = this.props

    return (
      <div className={classes.cardmethod} key={uuid()}>
        <Tooltip title={method.operationName} placement="top">
          <div className={classes.cardtitle}>
            {method.operationName}
          </div>
        </Tooltip>
        {this.renderHttpMethod(method)}
        <Tooltip title={method.operationName} placement="bottom">
          <div className={classes.cardpath}>
            <b>Path</b>:&nbsp; {method.path}
          </div>
        </Tooltip>
        <div className={classes.carddescription}>
          {method.properties !== null && (method.properties.summary)}
        </div>
        <div className={classes.cardplans}>
          {
            (method.usagePlans.length > 0) && (
              <div>
                {
                  method.usagePlans.map(plan => (
                    <table key={uuid()}>
                      <tbody>
                        {this.renderMethodPlans(method, plan.usagePlanId)}
                      </tbody>
                    </table>
                  ))

                }
              </div>
            )
          }
          {
            (this.store.plans.length === 0) && (
              <div className={classes.emptyplans}><FormattedMessage id="api.selectplan"/></div>
            )
          }
        </div>
      </div>
    )
  }

  public render() {
    const {stage, appId, classes} = this.props
    const {openDocsModal} = this.store

    return (
      <div>
        <Button
          className={classes.buttonDocs}
          onClick={this.openDocsModal}>
          <FormattedMessage id="appitem.accessdocs" />
          <LaunchOutlined className={classes.launch} />
        </Button>

        <div className={classes.urlendpoint}>
          <FormattedMessage id="api.urlendpoint" />:<br/>
          {
            stage.endpoint && stage.endpoint.map(endpoint => (
              <div key={uuid()}>
                <Link
                href={endpoint}
                target="_blank">
                {endpoint}
                </Link>
              </div>
            ))
          }
        </div>

        <div className={classes.boxplans}>
          <div className={classes.boxplanstitle}>
            <FormattedMessage id="api.availableplans" />
          </div>
          <div className={classes.boxplansoptions}>
            {
              stage.usagePlans && stage.usagePlans.map(plan => (
                <div key={uuid()}>{this.renderPlans(plan)}</div>
              ))
            }
          </div>
          <div className={classes.boxmethodstitle}>
            <FormattedMessage id="api.availablemethods" />
          </div>
          <div className={classes.boxmethods}>
            {
              this.store.methods && this.store.methods.map(m => (
                this.renderMethodCard(m)
              ))
            }
          </div>
        </div>

        <div className={classes.footer}>
          <Button
            className={classes.buttonDef}
            onClick={() => this.props.handleSave(this.store)}>
            <FormattedMessage id="api.btnsave"/> ({this.props.stage.stageName && this.props.stage.stageName.toUpperCase()})
          </Button>
        </div>

       <DocsModal
          isOpen={openDocsModal}
          handleClose={this.handleCloseDocsModal}
          appId={appId}
          stage={stage.stageName}/>
      </div>
    )
  }
}

export default withStyles(styles)(StageManager);
