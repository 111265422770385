import Api from '../frameworks/api/Api';

class RequestMethodService {
  private Api = new Api();

  public requestMethods = (payload: {}) => {
    return this.Api.post("access/request", payload)
  }
}

export default RequestMethodService;
