import {observable, action} from 'mobx';

const selected = {opacity: 1};
const notSelected = {opacity: 0.4};

class IntlBarStore {
  @observable
  public en_class = notSelected;

  @observable
  public ptBR_class = notSelected;
  
  @observable
  public es_class = notSelected;

  @action 
  public init = () => {
    this.en_class = notSelected;
    this.ptBR_class = notSelected;
    this.es_class = notSelected;
  }

  constructor(locale: string) {
    this.setLocale(locale);
  }

  @action
  public setLocale = (locale: String) => {
    this.init();
    switch(locale) {
      case "en-US": 
        this.en_class = selected;
      break;
      case "pt-BR": 
        this.ptBR_class = selected;
      break;
      case "es": 
        this.es_class = selected;
      break;
      default: 
        this.en_class = selected;
    }
  }
}

export default IntlBarStore;