import React, { Component } from 'react';
import { createStyles, Theme, withStyles } from '@material-ui/core';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

interface IProps {
  classes?: any,
  list: Array<string>,
  itemClicked: Function,
  clickedItem?: any
}

const styles = (theme: Theme) =>
  createStyles({
    list: {
      width: "100%",
    },
    item: {
      textAlign: "center",
      backgroundColor: "#fff",
      borderBottom: "1px solid #ccc",
      "&.Mui-selected, &.Mui-selected:hover, &:hover": {
        backgroundColor: "#4f719c",
        color: "#fff"
      },
    }
  });


class SimpleList extends Component<IProps> {
  handleListItemClick = (item: any) => {
    const { itemClicked } = this.props;
    itemClicked(item)
  };
  public render() {
    const { classes, list, clickedItem } = this.props;
    return (
      <List className={classes.list}>
        {
          list && list.map((item, index) => (

            <ListItem
              key={index}
              className={classes.item}
              button
              selected={clickedItem === item}
              onClick={(event) => this.handleListItemClick(item)}
            >
              <ListItemText primary={item} />
            </ListItem>
          )
          )
        }
      </List>
    );
  }
}

export default withStyles(styles)(SimpleList);