import { observer, inject } from 'mobx-react';
import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import Modal from '../modal/Modal';
import SimpleList from '../simplelist/SimpleList';
import GetSubscriptionsUseCase from '../../../../../core/lib/useCases/GetSubscriptionsUseCase';

import { Auth } from 'aws-amplify';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "contents"
    }
  });

interface IProps {
  classes?: any;
  intl: any;
  children?: React.ReactNode;
  stores?: any;
}

@inject('stores')
@observer
class SelectSubscriptionModal extends PureComponent<IProps> {
  public state = {
    enableButton: false,
    clickedSubs: "",
    subsList: []
  };

  componentDidMount() {
    this.refreshSubsList()
  }

  public componentDidUpdate = () => {
  };

  public refreshSubsList = () => {
    this.getSubscriptions()
      .then((data: any) => {
        console.log("refreshSubsList", data)
        if (data.length === 0)
          Auth.signOut()
        this.setState({ subsList: data })
      })
  }

  public getSubscriptions = async () => {
    const useCase = new GetSubscriptionsUseCase();
    const response = await useCase.execute();

    return response;
  }

  public closeModal = () => {
    const { setSelectedSubscription } = this.props.stores.headerContextStore;
    const { clickedSubs } = this.state;
    setSelectedSubscription(clickedSubs)
  }

  public itemClicked = (item: any) => {
    this.setState({
      enableButton: true,
      clickedSubs: item
    })
  }

  public render() {
    const { children, classes, stores, intl } = this.props;
    const { enableButton, clickedSubs, subsList } = this.state;

    const selectedSubscription = stores.headerContextStore.selectedSubscription;

    return (
      <div className={classes.root}>
        {selectedSubscription.length === 0 &&
          <Modal
            msg={(<SimpleList list={subsList} itemClicked={this.itemClicked} clickedItem={clickedSubs} />)}
            title={intl.formatMessage({ id: "modalselect.subscription" })}
            enableButton={enableButton}
            isOpen={true}
            handleClose={this.closeModal}
          />
        }
        {selectedSubscription.length > 0 && children}
      </div>
    )
  }
}

export default withStyles(styles)(injectIntl(SelectSubscriptionModal));
