import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { EPaths } from '../../../core/lib/settings/Constants';
import Login from '../components/general/login/Login';
import ConsumerMyApis from '../components/consumer/myapis/ConsumerMyApis';
import ConsumerHome from '../components/consumer/home/ConsumerHome';
import ConsumerDashboard from '../components/consumer/dashboard/ConsumerDashboard';
import Header from '../components/general/headers/Header';
import SelectSubscriptionModal from '../components/general/selectSubscriptionModal/SelectSubscriptionModal';

const title = "Consumer - Open API Portal"
const ConsumerRoutes = () => (
  <React.Fragment>
    <Helmet>
      <title>Consumer - Open API Portal</title>
    </Helmet>
    <Login title={title}>
      <SelectSubscriptionModal>
        <Header title={title} />
        <Switch>
          <Route exact={true} path={EPaths.ROOT} component={ConsumerHome} />
          <Route path={EPaths.AUTH} component={ConsumerHome} />
          <Route path={EPaths.LOGOUT} component={ConsumerHome} />
          <Route path={EPaths.MY_APPLICATIONS} component={ConsumerHome} />
          <Route path={`${EPaths.MY_APIS}/:id`} component={ConsumerMyApis} />
          <Route path={EPaths.DASHBOARD} component={ConsumerDashboard} />
        </Switch>
      </SelectSubscriptionModal>
    </Login>
  </React.Fragment>
);

export default ConsumerRoutes;
