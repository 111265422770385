import { createStyles, Theme, Typography, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import APITooltip from './APITooltip';

interface IProps {
  classes?: any;
  plan: any;
}

const styles = (theme: Theme) =>
  createStyles({
    divisor: {
      margin: "0.5rem 0"
    },
    title: {
      fontSize: "10px",
      fontWeight: "bold",
      color: "white",
      display: "inline"
    },
    description: {
      fontSize: "10px",
      color: "white",
      display: "inline"
    },
    details: {
      display: "block",
      fontSize: "8px",
      color: "white"
    }
  });
class APITooltipDetails extends Component<IProps> {
  public render() {
    const { classes, plan } = this.props;

    return plan && (
      <APITooltip>
        <>
          <div className={classes.divisor}>
            <Typography className={classes.title}>
              <FormattedMessage id="cost.name" />:{" "}
            </Typography>
            <Typography className={classes.description}>{plan && plan.name}</Typography>
          </div>

          <div className={classes.divisor}>
            <Typography className={classes.title}>
              <FormattedMessage id="cost.description" />:{" "}
            </Typography>
            <Typography className={classes.description}>{plan && plan.description}</Typography>
          </div>

          {plan && plan.throttle && (
            <>
              <div className={classes.divisor}>
                <Typography className={classes.title}>
                  <FormattedMessage id="cost.rate" />:{" "}
                </Typography>
                <Typography className={classes.description}>
                  {plan.throttle && plan.throttle.rateLimit}{" "}
                  <FormattedMessage id="cost.requestspersecond" />
                </Typography>
                {/* <Typography className={classes.details}>yyyyy</Typography> */}
              </div>
              <div className={classes.divisor}>
                <Typography className={classes.title}>
                  <FormattedMessage id="cost.pike" />:{" "}
                </Typography>
                <Typography className={classes.description}>
                  {plan.throttle.burstLimit} <FormattedMessage id="cost.requests" />
                </Typography>
              </div>
            </>
          )}
        </>
      </APITooltip>
    );
  }
}

export default withStyles(styles)(APITooltipDetails);
