import {observable, action} from 'mobx';
import {IApplicationDetails} from '../entities/IApplicationDetails';

class ApiItemStore {
  @observable
  public isExpanded = false;

  @action
  public setExpanded(isExpanded: boolean) {
    this.isExpanded = isExpanded;
  }

  @observable
  public isLoading = false;

  @action
  public setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @observable
  public appDetails = {} as IApplicationDetails;

  @action
  public setAppDetails = (appDetails: {}) => {
    this.appDetails = appDetails as IApplicationDetails;
  }

  @observable
  public success = true;

  @action
  public setSuccess = (success: boolean) => {
    this.success = success;
  }
}

export default ApiItemStore;