import APIDetailsService from '../services/APIDetailsService';

class BlockApiUseCase {
  public execute = async (
    apiId: string, 
    locked: boolean,
    subscription: any,
    region: string,
    context: string) => {
    const service = new APIDetailsService();
    const result = await service.updateAPIAccess(apiId, !locked, subscription, region, context);
    return result;
  };
}

export default BlockApiUseCase;
