import {observable, action} from 'mobx';
import IApplication from '../entities/IApplication';

class ConsumerHomeStore {
  @observable 
  public isLoading = true;

  @action 
  public setLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  }

  @observable
  public applicationList = new Array<IApplication>();

  @action
  public setApplicationList = (apps: []) => {
    this.applicationList = apps;
  }

  @observable
  public success = true;

  @action
  public setSuccess(success: boolean) {
    this.success = success;
  }
}

export default ConsumerHomeStore