import React from 'react';
import { ExpansionPanel } from '@material-ui/core';

interface IProps {
  summary: React.ReactElement<any>;
  details: React.ReactElement<any>;
}

interface IState {
  expanded: boolean;
}

class LazyExpansionPanel extends React.PureComponent<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      expanded: false,
    };
  }

  public handleChange = (event: any, expanded: boolean) => {
    this.setState({
      expanded,
    });
  };

  public render() {
    return (
      <ExpansionPanel expanded={this.state.expanded} onChange={this.handleChange}>
        {this.props.summary}
        {this.state.expanded && this.props.details}
      </ExpansionPanel>
    );
  }
}

export default LazyExpansionPanel;
