import { observable, action } from 'mobx';
import { IProviderAPIList } from '../entities/IProviderAPIList';
import { IConsumer } from '../entities/IConsumer';

class ProviderBlocksStore {
  @observable
  public loading = false;

  @observable
  public error: any = null;

  @observable
  public apis: IProviderAPIList[] = [];

  @observable
  public consumers: IConsumer[] = [];

  @observable
  public consumerSearchTerm = '';

  @action
  public loadingStart = () => {
    this.loading = true;
    this.error = null;
  };

  @action
  public loadingError = (error: any) => {
    this.loading = false;
    this.error = error || true;
  };

  @action
  public loadingSuccess = (apis: IProviderAPIList[], consumers: IConsumer[]) => {
    this.loading = false;
    this.error = null;
    this.apis = apis;
    this.consumers = consumers;
  };

  @action
  public changeConsumerSearchTerm = (term: string) => {
    this.consumerSearchTerm = term;
  };

  @action
  public updateConsumers = (consumers: IConsumer[]) => {
    this.consumers = consumers;
  };

  @action
  public updateApis = (apis: IProviderAPIList[]) => {
    this.apis = apis;
  };
}

export default ProviderBlocksStore;
