import React from 'react';
import { createStyles, withStyles } from '@material-ui/core';

import imgLogo from '../../../../src/assets/img/rchlo.png';

const styles = () =>
  createStyles({
    imgLogo: {
    }
  });

interface IProps {
  classes?: any;
  width?: string;
}

const Logo = (props: IProps) => (
  <img src={imgLogo} alt=""
    className={props.classes.imgLogo} width={props.width || "auto"} />
)

export default withStyles(styles)(Logo);