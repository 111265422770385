import React, {Component} from 'react';

class ConsumerDashboard extends Component {
  public render() {
    return (
      <h1>Consumer - Dashboard</h1>
    )
  }
}

export default ConsumerDashboard;