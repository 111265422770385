import { observable, action, computed } from 'mobx';
import { ISDK } from '../entities/ISDKType';

class SdkDownloaderStore {
  @observable
  public loading = false;

  @observable
  public error: any = null;

  @observable
  public downloading = false;

  @observable
  public downloadError: any = null;

  @observable
  public availableSdks = Array<ISDK>();

  @observable
  public selectedSdkId: string = '';

  @observable
  public parameterValues = new Map()

  @computed
  public get selectedSdk() {
    return this.availableSdks.find(sdk => sdk.id === this.selectedSdkId);
  }

  @computed
  public get readyForDownload() {
    const sdk = this.selectedSdk;

    if (!sdk) {
      // no sdk selected, not ready
      return false;
    }

    if (!sdk.configurationProperties) {
      // no configuration options are necessary, ready to download
      return true;
    }

    const numberOfMissingValues = sdk.configurationProperties.reduce((count, param) => {
      return (param.required && !this.parameterValues.get(param.name)) ? 1 : 0;
    }, 0);

    return numberOfMissingValues === 0;
  }

  @action
  public setLoading(loading: boolean) {
    this.loading = loading;
  }

  @action
  public setError(error: any) {
    this.error = error;
  }

  @action
  public setAvailableSdks(sdks: ISDK[]) {
    this.availableSdks = sdks;
    this.parameterValues.clear();
  }

  @action
  public setSelectedSdkId = (id: string) => {
    this.selectedSdkId = id;
    this.parameterValues.clear();
  }

  @action
  public setParameterValue = (key: string, value: string) => {
    this.parameterValues.set(key, value);
  }

  @action
  public loadingStart() {
    this.loading = true;
    this.error = null;
  }

  @action
  public loadingComplete() {
    this.loading = false;
  }

  @action
  public loadingError(error?: any) {
    this.loading = false;
    this.error = error || true;
  }

  @action
  public downloadingStart() {
    this.downloading = true;
    this.downloadError = null;
  }

  @action
  public downloadingCompleted() {
    this.downloading = false;
  }

  @action
  public downloadingError(error?: any) {
    this.downloading = false;
    this.downloadError = error || true;
  }
}

export default SdkDownloaderStore;
