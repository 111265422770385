import {observable, action} from 'mobx';
import {common_en_US, common_es_ES, common_pt_BR} from '../frameworks/translations/app/index';

class InternationalizationStore {
  constructor(lang: string) {
    this.setLanguage(lang)
  }

  public languages = () => {
    return new Map([
      ['en-US', common_en_US],
      ['es', common_es_ES],
      ['pt-BR', common_pt_BR]
    ]);
  }

  @observable 
  public locale = "";

  @observable 
  public messages: any;

  @action
  public setLanguage(locale: string) {
    if ((locale !== 'en-US') && (locale !== 'es') && (locale !== 'pt-BR')) {
      this.messages = common_en_US;
    } else {
      this.locale = locale;
      this.messages = this.languages().get(locale);
    }
  }
}

export default InternationalizationStore;