import Api from '../frameworks/api/Api';

class SaveStageConfigService {
  private Api = new Api();

  public save = (
    apiId: string, 
    payload: {}, 
    subscription: string, 
    region: string,
    headers?: any,
    ) => {
    const query = {subscription, region}
    return this.Api.post(`api/${apiId}`, payload, query, headers)
  }
}

export default SaveStageConfigService;
