import DownloadDocService from '../services/DownloadDocService';

class DownloadDocServiceUseCase {
  public execute = async(apiId: string, stage: string, type: string, extensions: string, format: string) => {
    const downloadDoc = new DownloadDocService();
    const result = await downloadDoc.download(apiId, stage, type, extensions, format);
    return result;
  }
}

export default DownloadDocServiceUseCase;