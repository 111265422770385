enum EColors {
  white = "#f0f0f0",
  whiteTwo = "#f2f2f2",
  black = "#000000",
  weirdGreen = "#47cd8f",
  mango = "#fba12f",
  tangerine = "#ff9a00",
  dark = "#222e3e",
  ice = "#eaeded",
  skyBlue = "#61affe",
  eggShell = "#fae9c0",
  coral = "#fa3f3e",
  tealBlue = "#007eb9",
  blue = "#445264",
  warmGrey = "#999",
  grey = "#aaa",
  veryDarkBrown = "#111100",
  tomato = "#e02020"
}

export default EColors;
