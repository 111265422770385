import React, {Component} from 'react';
import IApplication from '../../../../../core/lib/entities/IApplication';
import { Button, createStyles, Link, Theme, withStyles } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as SvgSeguir } from '../../../assets/svgs/seguir.svg';
import ImgArrowDown from '../../../assets/img/arrow_down.png';
import ImgArrowUp from '../../../assets/img/arrow_up.png';
import { Link as RouterLink } from 'react-router-dom';
import {EPaths} from '../../../../../core/lib/settings/Constants';
import Loading from '../../general/loading/Loading';
import {observer} from 'mobx-react';
import ApiItemStore from '../../../../../core/lib/stores/ApiItemStore';
import ApplicationDetailsUseCase from '../../../../../core/lib/useCases/ApplicationDetailsUseCase';
import Error from '../error/Error';
import {IApplicationDetails} from '../../../../../core/lib/entities/IApplicationDetails';
import APIDetails from '../apidetails/APIDetails';
import LaunchOutlined from '@material-ui/icons/LaunchOutlined';
import APIConfigModal from '../apiconfigmodal/APIConfigModal';
import { FormattedMessage } from 'react-intl';

interface IProps {
  application: IApplication;
  classes: any
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      marginTop: "1rem"
    },
    apiName: {
      flexBasis: "300px"
    },
    summaryContent: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    buttonsContainer: {
      flexBasis: 200,
      justifyContent: "flex-end",
      alignItems: "flex-end",
      display: "flex",
      "& > button": {
        height: "45px"
      }
    },
    detailsContainer: {
      display: "flex",
      flexDirection: "column",
      borderTop: "2px solid #ccc"
    },
    btn: {
      display: 'inline-block',
      padding: 3,
      minHeight: 0,
      minWidth: 0,
      marginRight: "6px"
    },
    tabs: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      height: "35px",
      textTransform: "uppercase"
    },
    tabUnselected: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      width: "100%",
      height: "100%",
    },
    tab: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      width: "100%",
      height: "100%",
      borderBottom: "solid 2px orange"
    },
    buttonDetails: {
      backgroundImage: "linear-gradient(to bottom, #fcfcfd, #f5f6f8 9%, #c4c5c6)",
      textTransform: "none",
      border: "1px solid #ADB1B8",
      "&:hover": {
        boxShadow: "1px 1px 3px #ccc"
      }
    },
    boxbtndetails: {
      marginTop: "20px",
      marginBottom: "20px"
    },
    launch: {
      marginLeft: "10px"
    }
  });

@observer
class ApiItem extends Component<IProps> {
  public state = {openConfigModal: false};
  private store = new ApiItemStore();

  private changeExpanded = async () => {
    this.store.setExpanded(!this.store.isExpanded);

    if ((!this.store.isLoading) && (this.store.isExpanded)) {
      this.store.setSuccess(true);
      this.loadAppDetails();
    }
  }

  private loadAppDetails = async () => {
    const {application} = this.props;
    this.store.setLoading(true);
    const apiDetails = new ApplicationDetailsUseCase();
    var result = await apiDetails.execute(application.applicationId);
    this.store.setSuccess(result.ok);

    if (result.ok) {
      this.store.setAppDetails(((result.data) as any).application as IApplicationDetails);
    }

    this.store.setLoading(false);
  }

  public handleCloseConfig = () => {
    this.setState({openConfigModal: false});
  }

  public showConfig = () => {
    this.setState({openConfigModal: true});
  }

  public render() {
    const {classes, application} = this.props;
    const {openConfigModal} = this.state;
    const canExpand = (application.accesses != null);
    const imgArrow = (this.store.isExpanded) ? ImgArrowUp : ImgArrowDown;

    return (
      <div className={classes.container}>
        <ExpansionPanel expanded={this.store.isExpanded}>
          <ExpansionPanelSummary classes={{ content: classes.summaryContent }}>
            <div>
              <Typography variant="h1">
                {application.name}
              </Typography>
            </div>

            <div className={classes.buttonsContainer}>
            <Button className={classes.btn}>
                <Link component={RouterLink} to={`${EPaths.MY_APIS}/${application.applicationId}`}>
                  <SvgSeguir />
                </Link>
              </Button>
              {
                canExpand && (
                  <Button className={classes.btn} onClick={this.changeExpanded}>
                    <img src={imgArrow} alt=""/>
                  </Button>
                )
              }
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails classes={{ root: classes.detailsContainer }}>
            { this.store.isLoading && (<Loading/>) }
            { !this.store.success && (<Error/>) }

            {
              !this.store.isLoading && this.store.success && this.store.appDetails.accesses !== undefined && (
                <div className={classes.boxbtndetails}>
                  <Button
                    className={classes.buttonDetails}
                    onClick={this.showConfig}>
                    <FormattedMessage id="app.btnapidetails" />
                    <LaunchOutlined className={classes.launch} />
                  </Button>

                  <APIConfigModal
                    isOpen={openConfigModal}
                    handleClose={this.handleCloseConfig}
                    application={application} />
                </div>
              )
            }

            {
              !this.store.isLoading && this.store.success && this.store.appDetails.accesses !== undefined && (
                this.store.appDetails.accesses.map(access => (
                  <APIDetails
                    appDetails={access}
                    key={access.apiId}
                    application={application}
                  />
                ))
              )
            }
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    )
  }
}

export default withStyles(styles)(ApiItem);
