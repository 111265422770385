import React from 'react';
import { IconButton } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Loading from '../loading/Loading';

interface IProps {
  disabled?: boolean;
  locked?: boolean;
  onChange?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    locked: boolean,
  ) => Promise<void> | void;
  apiContext?: string;
}

interface IState {
  loading: boolean;
}

class LockButton extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  public handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { locked, onChange } = this.props;

    if (typeof onChange !== 'function') {
      return;
    }

    const result = onChange(event, !locked);

    if (typeof result === 'object' && typeof result.then === 'function') {
      this.setState({
        loading: true,
      });

      result.then(() => {
        this.setState({
          loading: false,
        });
      });
    }
  };

  public render() {
    const { locked, disabled } = this.props;
    const { loading } = this.state;

    const Icon = locked ? LockIcon : LockOpenIcon;

    if (loading) {
      return (
        <IconButton>
          <Loading size={'1em'} />
        </IconButton>
      );
    }

    return (
      <IconButton disabled={disabled} onClick={this.handleClick}>
        <Icon fontSize="inherit" color={locked && !disabled ? 'secondary' : undefined} />
      </IconButton>
    );
  }
}

export default LockButton;
