import Api from '../frameworks/api/Api';
import { APP_BASE } from '../settings/Constants';

class APIListService {
  private Api = new Api();

  public getAPIList = (subscription: string, region: string, headers?: any) => {
    const query = {subscription, region, type:APP_BASE}
    return this.Api.get("api", query, headers);
  }
}

export default APIListService;