import ConsumerService from '../services/ConsumerService';

class BlockConsumerUseCase {
  public execute = async (consumerId: string, locked: boolean) => {
    const service = new ConsumerService();
    const result = await service.updateConsumerAccess(consumerId, !locked);
    return result;
  };
}

export default BlockConsumerUseCase;
