import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

import EColors from './Colors';

export const mainTheme = createMuiTheme({
  palette: {
    primary: {
      light: EColors.mango,
      main: EColors.tealBlue,
    },
    secondary: {
      light: EColors.mango,
      main: EColors.tangerine,
      dark: EColors.mango,
    },
    background: {
      default: EColors.ice,
    },
  },

  typography: {
    fontFamily: ['PT Sans', 'Helvetica'].join(','),
    h1: {
      fontSize: 22,
    },
    h2: {
      fontSize: 19,
      fontWeight: 'bold',
    },
    h3: {
      fontSize: 16,
    },
    h4: {
      fontSize: 14,
    },
    h5: {
      fontSize: 12,
    },
    h6: {
      fontSize: 10,
    },
    body1: {
      fontSize: 16,
      fontFamily: 'Helvetica',
    },
    body2: {
      fontSize: 14,
    },
  },
  overrides: {
    MuiTypography: {
      paragraph: {
        '&strong': {
          fontWeight: 'bolder',
        },
      },
    },
  },
});
