import Api from '../frameworks/api/Api';

class DownloadSdkService {
  private Api = new Api();

  public download = (apiId: string, stage: string, sdkId: string, parameters: object = {}) => {
    const body = {
      parameters
    };

    return this.Api.post(`api/${apiId}/stage/${stage}/sdk?type=${sdkId}`, body);
  }
}

export default DownloadSdkService;