export default class DownloadManager {
  public download = (content: any, fileName: string) => {
    let blob = new Blob([content], {type: "application/octet-stream"});
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
    link.remove();
    window.URL.revokeObjectURL(link.href);
  }
}