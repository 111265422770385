import React, { Component } from 'react';
import { createStyles, Theme, withStyles, Link, Button } from '@material-ui/core';
import LaunchOutlined from '@material-ui/icons/LaunchOutlined';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import Loading from '../../general/loading/Loading';
import SearchApiList from '../../general/searchApiList/SearchApiList';
import { observer, inject } from 'mobx-react';
import Error from '../../general/error/Error';
import InfoBox from '../../general/infoBox/InfoBox'
import Stores from '../../../../../core/lib/stores/Stores';
import ProviderAPIListUseCase from '../../../../../core/lib/useCases/ProviderAPIListUseCase';
import { IProviderAPIList } from '../../../../../core/lib/entities/IProviderAPIList';
import { injectIntl } from 'react-intl';
import uuid from 'uuid';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { EPaths } from '../../../../../core/lib/settings/Constants';
import ImgWarning from '../../../assets/img/warning.png';

import { intercept } from 'mobx'

const styles = (theme: Theme) =>
  createStyles({
    container: {
      width: "75%",
      flexGrow: 1,
      margin: "3rem auto",
      backgroundColor: "#eaeded",
      marginTop: "100px",
      marginBottom: "20px"
    },
    content: {
      marginTop: "40px",
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      gridAutoRows: "auto",
      gridGap: "1rem",
    },
    boxapi: {
      backgroundColor: "#fff",
      boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.75)",
      borderRadius: "4px",
      minHeight: "240px",
      padding: "20px",
    },
    apiTitle: {
      fontSize: "20px",
      display: "flex",
      flexDirection: "row"
    },
    fieldtype: {
      marginLeft: "auto"
    },
    apistatus: {
      marginLeft: "auto",
      textAlign: "end",
    },
    apiinfos: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "10px",
      width: "100%",
    },
    apidescription: {
      minHeight: "40px",
      fontSize: "13px",
      backgroundColor: "#eee",
      borderRadius: "4px",
      marginTop: "20px",
      padding: "10px",
      color: "#555",
      verticalAlign: "center",
      display: "normal",
      alignItems: "center",
      maxHeight: "100px",
      overflow: "auto",
    },
    apidetails: {
      alignItems: "center",
      display: "flex",
      fontSize: "13px",
      justifyContent: "center",
      marginTop: "20px",
      width: "100%",
    },
    apiType: {
      display: "flex",
      justifyContent: "center",
    },
    divider: {
      height: "1px",
      backgroundColor: "#eee",
      width: "100%",
      marginTop: "20px"
    },
    apilink: {
      width: "100%",
      marginTop: "14px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "18px"
    },
    routerLink: {
      color: "#007eb9",
      display: "flex",
      justifyContent: "space-between"
    },
    outLined: {
      marginLeft: "5px"
    },
    visibilityOn: {
      borderRadius: "0",
      background: "#ff9a00",
      color: "#fff",
      fontSize: "2rem",
      padding: "5px"
    },
    visibilityOff: {
      borderRadius: "0",
      background: "#999",
      color: "#fff",
      fontSize: "2rem",
      padding: "5px"
    },
    noUsagePlanMessage: {
      background: "#f5dada",
      margin: "auto",
      marginTop: "16px",
      textAlign: "center",
      padding: "4px 5px",
      width: "fit-content",
    },
    warningIcon: {
      height: "10px",
      padding: "0 5px",
    }
  });

interface IProps {
  classes: any,
  stores: Stores,
  intl: any
}

@inject('stores')
@observer
class ProviderHome extends Component<IProps>{

  public componentDidMount() {
    const { stores } = this.props
    intercept(stores.headerContextStore, "selectedSubscription", (change: any) => {
      this.loadAPIs(change.newValue)
      console.log("intercept", change, stores.headerContextStore.selectedSubscription)
      return change
    })

    intercept(stores.headerContextStore, "selectedRegion", (change: any) => {
      this.loadAPIs(undefined, change.newValue)
      console.log("intercept", change, stores.headerContextStore.selectedSubscription)
      return change
    })

    this.loadAPIs()
  }

  public loadAPIs = async (newSubscription?: string, newRegion?: string) => {
    const { stores } = this.props
    stores.providerHomeStore.setLoading(true)
    const { selectedSubscription, selectedRegion } = stores.headerContextStore
    const useCase = new ProviderAPIListUseCase()
    const result = await useCase.execute(
      newSubscription || selectedSubscription,
      newRegion || selectedRegion)

    if (result.ok) {
      stores.providerHomeStore.setAPIList(result.data as IProviderAPIList[])
    } else {
      stores.providerHomeStore.setSuccess(false)
    }

    stores.providerHomeStore.setLoading(false)
  }

  private handleChangeType = (event: any) => {
    const { stores } = this.props
    stores.providerHomeStore.setSearchType(event.target.value)
  }

  private renderApis = () => {
    const { stores, classes } = this.props

    var filteredApis = stores.providerHomeStore.apis

    if (stores.providerHomeStore.searchtype === "1") {
      filteredApis = stores.providerHomeStore.apis.filter(item => item.isShared === true)
    }

    if (stores.providerHomeStore.searchtype === "2") {
      filteredApis = stores.providerHomeStore.apis.filter(item => item.isShared === false)
    }

    if ((stores.providerHomeStore.searchkey !== "") && (stores.providerHomeStore.searchkey !== undefined)) {
      filteredApis = filteredApis.filter(
        item => item.name.toLocaleLowerCase().indexOf(stores.providerHomeStore.searchkey) > -1)
    }

    if (filteredApis !== undefined) {
      return (
        filteredApis.map(api => (
          <div className={classes.boxapi} key={uuid()}>
            <div className={classes.apiinfos}>
              <InfoBox
                content={stores.headerContextStore.selectedSubscription}
                styleType="subscription"
                ></InfoBox>
              <InfoBox
                content={api.region}
                styleType="region"
              ></InfoBox>
            </div>
            <div className={classes.apiTitle}>
              <div>{api.name}</div>
              <div className={classes.apistatus}>
                {
                  api.isShared && (
                    <Visibility classes={{ root: classes.visibilityOn }} />
                  )
                }
                {
                  !api.isShared && (
                    <VisibilityOff classes={{ root: classes.visibilityOff }} />
                  )
                }
              </div>
            </div>
            <div className={classes.apidescription}>
              {api.description !== undefined && api.description !== "" && (api.description)}
              {api.description === undefined && (<div><FormattedMessage id="api.nodescription" /></div>)}
            </div>
            <div className={classes.apidetails}>
              <b><FormattedMessage id="api.datecreated" /></b>:
              {api.createdDate.substring(0, 10)}&nbsp;-&nbsp;
            </div>
            <div className={classes.apiType}>
              <b>{api.endpointConfiguration.types[0].toString()}</b>
            </div>
            <div className={classes.divider}></div>
            {api.hasUsagePlan && (
              <div className={classes.apilink}>
                <Button color="primary" className={classes.button}>
                  <Link
                    className={classes.routerLink}
                    component={RouterLink}
                    to={{
                      pathname: `${EPaths.API_MANAGER}/${api.id}`,
                      state: {
                        apiContext: api.context,
                        region: api.region,
                        subscription: stores.headerContextStore.selectedSubscription,
                      }
                    }}
                  >
                    <FormattedMessage id="api.managemethods" />
                    <LaunchOutlined className={classes.outLined} />
                  </Link>
                </Button>
              </div>
            )}
            {
              !api.hasUsagePlan &&
              (
                <div className={classes.noUsagePlanMessage}>
                  <img src={ImgWarning} alt="" className={classes.warningIcon} />
                  <FormattedMessage id="api.nousageplan" />
                </div>
              )
            }
          </div>
        ))
      )
    } else {
      return (<div></div>)
    }
  }

  private handleChangeSearchKey = (event: any) => {
    const { stores } = this.props
    stores.providerHomeStore.setSearchKey(event.target.value)
  }

  public render() {
    const { classes, stores } = this.props

    if (stores.providerHomeStore.isLoading) {
      return <Loading />
    }

    if (!stores.providerHomeStore.success) {
      return <Error />;
    }

    return (
      <div className={classes.container}>
        <SearchApiList
          handleChangeType={this.handleChangeType}
          handleChangeSearchKey={this.handleChangeSearchKey}
          searchtype={stores.providerHomeStore.searchtype}
        ></SearchApiList>

        <div className={classes.content}>
          {this.renderApis()}
        </div>
      </div>
    );
  }
}

export default injectIntl(withStyles(styles)(ProviderHome));
