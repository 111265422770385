import AppResetApiKeyService from '../services/AppResetApiKeyService';

class AppResetApiKeyUseCase {
  public execute = async (applicationId: string) => {
    const appResetApiKey = new AppResetApiKeyService();
    const result = await appResetApiKey.reset(applicationId);
    return result;
  }
}

export default AppResetApiKeyUseCase;