import React, { Component } from 'react';
import { IRequestApplication, IRequestDetails } from '../../../../../core/lib/entities/IRequests';
import { createStyles, Theme, withStyles, Button, IconButton } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ImgArrowDown from '../../../assets/img/arrow_down.png';
import ImgArrowUp from '../../../assets/img/arrow_up.png';
import SvgThumbsUp from '../../../assets/svgs/ic-thumbs-up.svg';
import SvgThumbsDown from '../../../assets/svgs/ic-thumbs-down.svg';
import { FormattedMessage } from 'react-intl';
import uuid from 'uuid';

interface IProps {
  classes?: any;
  consumerName: string;
  consumerId: string;
  application: IRequestApplication;
  handleChangeAll: (consumerId: string, application: IRequestApplication, approved: boolean) => void;
  handleChangeOne: (consumerId: string, applicationId: string, requestId: string, approved: boolean) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    content: {
      marginBottom: "10px"
    },
    summaryContent: {
      display: "grid",
      flexDirection: "row",
      alignItems: "center",
      fontSize: "16px",
      height: "50px",
      margin: "12px 0"
    },
    detailsContainer: {
      display: "flex",
      flexDirection: "column",
      borderTop: "2px solid #ccc"
    },
    btn: {
      display: 'inline-block',
      padding: 3,
      minHeight: 0,
      minWidth: 0,
      marginRight: "6px"
    },
    boxcustomer: {
      width: "60%",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    boxapp: {
      width: "60%",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    boxapplyall: {
      right: 100,
      top: 14,
      position: "absolute",
      fontSize: "14px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    boxapplyitem: {
      position: "absolute",
      right: 50,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center"
    },
    boxbtnexpand: {
      float: "right",
      position: "absolute",
      right: "0",
      top: "25%",
    },
    btndef: {
      maxWidth: "20px",
      height: "100%"
    },
    methodbox: {
      width: "100%",
      borderRadius: "4px",
      marginBottom: "10px",
      height: "60px",
      display: "flex",
      alignItems: "center"
    },
    methodboxpost: {
      backgroundColor: "#EEF9F4",
      border: "solid 1px #49CC90"
    },
    methodboxget: {
      backgroundColor: "#F0F7FF",
      border: "solid 1px #62AFFE"
    },
    methodboxput: {
      backgroundColor: "#F0F7FF",
      border: "solid 1px #FCA12F"
    },
    methodboxdelete: {
      backgroundColor: "##ff9999",
      border: "solid 1px #FF3333"
    },
    httpmethod: {
      width: "50px",
      height: "50px",
      backgroundColor: "red",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      color: "#fff",
      fontWeight: "bold",
      fontSize: "14px",
      borderRadius: "4px",
      marginLeft: "4px"
    },
    post: {
      backgroundColor: "#49CC90"
    },
    get: {
      backgroundColor: "#62AFFE"
    },
    delete: {
      backgroundColor: "#FF3333"
    },
    put: {
      backgroundColor: "#FCA12F"
    },
    operationname: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      paddingLeft: "10px"
    },
    operationtitle: {
      fontSize: "16px",
      fontWeight: "bold"
    },
    operationdetails: {
      fontSize: "14px"
    },
    boxcontaineritems: {
      marginTop: "20px"
    }
  })

class RequestPanel extends Component<IProps> {
  public state = { isExpanded: false }

  public getMethodColor = (httpmethod: string) => {
    const { classes } = this.props;

    switch (httpmethod) {
      case "POST":
        return classes.post;
      case "GET":
        return classes.get;
      case "DELETE":
        return classes.delete;
      case "PUT":
        return classes.put;
    }
  }

  public getMethodBoxColor = (httpmethod: string) => {
    const { classes } = this.props;

    switch (httpmethod) {
      case "POST":
        return classes.methodboxpost;
      case "GET":
        return classes.methodboxget;
      case "DELETE":
        return classes.methodboxdelete;
      case "PUT":
        return classes.methodboxput;
    }
  }

  private changeExpanded = () => {
    this.setState({ isExpanded: !this.state.isExpanded })
  }

  private onChangeAll = (approved: boolean) => {
    const { handleChangeAll, consumerId, application } = this.props
    handleChangeAll(consumerId, application, approved)
  }

  private onChangeOne = (requestId: string, approved: boolean) => {
    const { handleChangeOne, consumerId, application } = this.props
    handleChangeOne(consumerId, application.applicationId, requestId, approved)
  }

  private renderItems = (details: IRequestDetails) => {
    const { classes } = this.props

    return (
      <div className={classes.methodbox + " " + this.getMethodBoxColor(details.httpMethod)}>
        <div className={classes.httpmethod + " " + this.getMethodColor(details.httpMethod)}>
          {details.httpMethod}
        </div>
        <div className={classes.operationname}>
          <div className={classes.operationtitle}>
            {details.applicationName}
          </div>
          <div className={classes.operationdetails}>
            {details.httpMethodName}&nbsp;|&nbsp;{details.stageName}&nbsp;|&nbsp;{details.usagePlanName}
          </div>
          <div className={classes.boxapplyitem}>

            <IconButton onClick={() => this.onChangeOne(details.requestId, true)}>
              <img src={SvgThumbsUp} width="22" height="22" alt="" />
            </IconButton>

            <IconButton onClick={() => this.onChangeOne(details.requestId, false)}>
              <img src={SvgThumbsDown} width="22" height="22" alt="" />
            </IconButton>

          </div>
        </div>
      </div>
    )
  }

  public render() {
    const { classes, consumerName, application } = this.props
    const { isExpanded } = this.state

    const imgArrow = (isExpanded) ? ImgArrowUp : ImgArrowDown;

    var sorted = application.requests

    sorted = sorted.slice().sort((a, b) => {
      if (a.applicationName < b.applicationName) { return -1; }
      if (a.applicationName > b.applicationName) { return 1; }
      return 0;
    })

    return (
      <div className={classes.content}>
        <ExpansionPanel expanded={isExpanded}>
          <ExpansionPanelSummary classes={{ content: classes.summaryContent }}>
            <div className={classes.boxcustomer}>
              <b>
                <FormattedMessage id="requests.client" />:</b>&nbsp;
              {consumerName}
              </div>
            <div className={classes.boxapp}>
              <b>
                <FormattedMessage id="requests.app" />:
              </b>&nbsp;
              {application.name}
            </div>
            <div className={classes.boxapplyall}>
              <FormattedMessage id="requests.approveall" /> ?

                <IconButton onClick={() => this.onChangeAll(true)}>
                <img src={SvgThumbsUp} width="25" height="25" alt="" />
              </IconButton>

              <IconButton onClick={() => this.onChangeAll(false)}>
                <img src={SvgThumbsDown} width="25" height="25" alt="" />
              </IconButton>

            </div>
            <div className={classes.boxbtnexpand}>
              <Button className={classes.btn} onClick={this.changeExpanded}>
                <img src={imgArrow} alt="" />
              </Button>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails classes={{ root: classes.detailsContainer }}>
            {
              <div className={classes.boxcontaineritems}>
                {
                  sorted.map(m => (
                    <div key={uuid()}>{this.renderItems(m)}</div>
                  ))
                }
              </div>
            }
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    )
  }
}

export default withStyles(styles)(RequestPanel);
