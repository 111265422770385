import React, { Component } from 'react';
import { createStyles, Theme, withStyles, Select, MenuItem } from '@material-ui/core';
import Stores from '../../../../../core/lib/stores/Stores';
import { inject, observer } from 'mobx-react';
import Error from '../../general/error/Error';
import Loading from '../../general/loading/Loading';
import RequestListUseCase from '../../../../../core/lib/useCases/RequestListUseCase';
import { IRequests, IRequestApplication } from '../../../../../core/lib/entities/IRequests';
import RequestPanel from '../../general/requestpanel/RequestPanel';
import RequestMethodUseCase from '../../../../../core/lib/useCases/RequestMethodUseCase';
import { FormattedMessage } from 'react-intl';
import uuid from 'uuid';
import NavMenu from '../../general/navmenu/NavMenu';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      width: "75%",
      flexGrow: 1,
      margin: "3rem auto",
      backgroundColor: "#eaeded",
      marginTop: "100px",
      marginBottom: "20px"
    },
    boxfilter: {
      display: "flex",
      flexDirection: "row",
      marginTop: "30px",
      fontSize: "16px",
      backgroundColor: "#ddd",
      padding: "10px",
      borderRadius: "10px",
      alignItems: "center"
    },
    title: {
      fontSize: "28px",
    },
    filter: {
      marginLeft: "20px",
      fontSize: "14px"
    },
    boxpanels: {
      marginTop: "26px",
      backroundColor: "yellow",
      display: "flex",
      flexDirection: "column"
    }
  })

interface IProps {
  classes?: any,
  stores: Stores
}

@inject("stores")
@observer
class ProviderRequests extends Component<IProps> {

  public componentDidMount() {
    this.loadRequests()
  }

  private loadRequests = async () => {
    const { stores } = this.props
    stores.providerRequestsStore.setLoading(true)

    const { selectedSubscription, selectedRegion } = stores.headerContextStore;
    const { selectedFilter } = stores.providerRequestsStore;

    const useCase = new RequestListUseCase()
    const result = await useCase.execute(selectedFilter, selectedSubscription, selectedRegion)

    if (result.ok) {
      stores.providerRequestsStore.setSuccess(true)
      stores.providerRequestsStore.setRequests(result.data as IRequests)
    } else {
      stores.providerRequestsStore.setSuccess(false)
    }

    stores.providerRequestsStore.setLoading(false)
  }

  private onChangeFilter = (event: any) => {
    this.props.stores.providerRequestsStore.setSelectedFilter(event.target.value)
    this.loadRequests()
  }

  private handleChangeAll = async (consumerId: string, application: IRequestApplication, approved: boolean) => {
    const { stores } = this.props
    stores.providerRequestsStore.setLoading(true)
    var requestList = Array<{ requestId: string; approved: boolean }>();

    application.requests.map(r => {
      requestList.push({ requestId: r.requestId, approved: approved })
      return null
    })

    var payload = { applications: [{ applicationId: application.applicationId, consumerId: consumerId, requests: requestList }] }
    const useCase = new RequestMethodUseCase()
    const result = await useCase.execute(payload)

    if (result.ok) {
      stores.providerRequestsStore.setSuccess(true)
      this.loadRequests()
    } else {
      stores.providerRequestsStore.setSuccess(false)
      stores.providerRequestsStore.setLoading(true)
    }
  }

  private handleChangeOne = async (consumerId: string, applicationId: string, requestId: string, approved: boolean) => {
    const { stores } = this.props
    stores.providerRequestsStore.setLoading(true)

    var payload = { applications: [{ applicationId: applicationId, consumerId: consumerId, requests: [{ requestId: requestId, approved: approved }] }] }
    const useCase = new RequestMethodUseCase()
    const result = await useCase.execute(payload)

    if (result.ok) {
      stores.providerRequestsStore.setSuccess(true)
      const { selectedSubscription, selectedRegion } = stores.headerContextStore;
      const { selectedFilter } = stores.providerRequestsStore;

      const useCase = new RequestListUseCase()
      const result = await useCase.execute(selectedFilter, selectedSubscription, selectedRegion)

      if (result.ok) {
        stores.providerRequestsStore.setSuccess(true)
        stores.providerRequestsStore.setRequests(result.data as IRequests)
      } else {
        stores.providerRequestsStore.setSuccess(false)
      }
    } else {
      stores.providerRequestsStore.setSuccess(false)
      stores.providerRequestsStore.setLoading(true)
    }
    stores.providerRequestsStore.setLoading(false)
  }

  public render() {
    const { classes, stores } = this.props
    const requests = stores.providerRequestsStore.requestList.requests

    if (stores.providerRequestsStore.isLoading) {
      return (
        <NavMenu>
        <Loading />
        </NavMenu>
        )
    }

    if (!stores.providerRequestsStore.success) {
      return (
        <NavMenu>
        <Error />
        </NavMenu>
      )
    }

    return (
      <NavMenu>
        <div className={classes.container}>
          <div className={classes.title}>
            <b><FormattedMessage id="requests.title" /></b>
          </div>

          <div className={classes.boxfilter}>
            <FormattedMessage id="filter.by" />:
            <div className={classes.filter}>
              <Select
                value={stores.providerRequestsStore.selectedFilter}
                onChange={this.onChangeFilter}>
                <MenuItem value="pending"><FormattedMessage id="filter.pending" /></MenuItem>
                <MenuItem value="approved"><FormattedMessage id="filter.approved" /></MenuItem>
                <MenuItem value="denied"><FormattedMessage id="filter.denied" /></MenuItem>
              </Select>
            </div>
          </div>

          <div className={classes.boxpanels}>
            {
              requests && (
                requests.map(r => (
                  r.applications.map(a => (
                    <RequestPanel
                      key={uuid()}
                      consumerName={r.name}
                      consumerId={r.consumerId}
                      application={a}
                      handleChangeAll={this.handleChangeAll}
                      handleChangeOne={this.handleChangeOne} />
                  ))
                ))
              )
            }
          </div>
        </div>
      </NavMenu>
    )
  }
}

export default withStyles(styles)(ProviderRequests);
