import ConsumerService from '../services/ConsumerService';

class ConsumerListUseCase {
  public execute = async () => {
    const consumerListService = new ConsumerService();
    const result = await consumerListService.getConsumers();
    return result;
  };
}

export default ConsumerListUseCase;
