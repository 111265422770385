import {observable, action} from 'mobx';
import {IMethod, IUsagePlan} from '../entities/IApplicationDetails';

class StageManagerStore {

  @observable
  public openDocsModal = false

  @observable
  public plans = Array<IUsagePlan>()

  @observable
  public methods = Array<IMethod>()

  @observable
  public methodvalues = Array<{key: string; value: number}>()

  @action
  public setOpenDocsModal = (open: boolean) => {
    this.openDocsModal = open
  }

  @action
  public setPlans = (plans: Array<IUsagePlan>) => {
    this.plans = plans
  }

  @action
  public setMethods = (methods: Array<IMethod>) => {
    this.methods = methods
  }

  @action
  public setMethodValues = (methodvalues: Array<{key: string; value: number}>) => {
    this.methodvalues = methodvalues
  }

}

export default StageManagerStore
