import Api from '../frameworks/api/Api';

class ProviderAPIListService {
  private Api = new Api();

  public loadAPIList = (
    subscription: string,
    region: string,
    headers?: any
  ) => {
    const query = {
      "subscription": subscription,
      "region": region
    }
    return this.Api.get("api", query, headers)
  }
}

export default ProviderAPIListService;