import APIDetailsService from '../services/APIDetailsService';

class BlockApiMethodUseCase {
  public execute = async (
    apiId: string,
    stageName: string,
    httpMethod: string,
    resourceId: string,
    locked: boolean,
    subscription: string, 
    region: string, 
    context: string,
  ) => {
    const service = new APIDetailsService();
    return service.updateAPIMethodAccess(
      apiId, 
      stageName, 
      httpMethod, 
      resourceId, 
      !locked,
      subscription, 
      region, 
      context
      );
  };
}

export default BlockApiMethodUseCase;
