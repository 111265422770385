export enum EAppBase {
  CONSUMER = 'CONSUMER',
  PROVIDER = 'PROVIDER',
}

/**
 * ROUTES
 */
export enum EPaths {
  ROOT = '/',
  LOGIN = '/login',
  AUTH = '/auth',
  LOGOUT = '/logout',

  DASHBOARD = '/dashboard',
  REQUESTS = '/dashboard/requests',
  BLOCKS = '/dashboard/blocks',

  API_LIST = '/api-list',
  API_MANAGER = '/api-manager',

  MY_APPLICATIONS = '/my-applications',
  MY_APIS = '/my-apis',
}

/**
 * LANGS
 */
export enum ELangs {
  PT_BR = 'pt-BR',
  EN_US = 'en-US',
  ES_ES = 'es-ES',
}

const AWS_PROVIDER_AD = {
  region: process.env.REACT_APP_AWS_PROVIDER_REGION,
  userPoolId: process.env.REACT_APP_AWS_PROVIDER_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_PROVIDER_WEB_CLIENT_ID,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  oauth: {
    domain: process.env.REACT_APP_AWS_AUTH_DOMAIN,
    redirectSignIn: `${window.location.origin}/`,
    redirectSignOut: `${window.location.origin}${EPaths.LOGOUT}/`,
    responseType: 'code'
  }
};

const AWS_CONSUMER_AD = {
  region: process.env.REACT_APP_AWS_CONSUMER_REGION,
  userPoolId: process.env.REACT_APP_AWS_CONSUMER_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_CONSUMER_WEB_CLIENT_ID,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  oauth: {
    domain: process.env.REACT_APP_AWS_AUTH_DOMAIN,
    redirectSignIn: `${window.location.origin}/`,
    redirectSignOut: `${window.location.origin}${EPaths.LOGOUT}/`,
    responseType: 'code'
  }
};

export const APP_BASE = process.env.REACT_APP_BASE || ''; // process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BASE : (EAppBase.PROVIDER as any);

export const CUSTOM_LOGO_URL = process.env.REACT_APP_CUSTOM_LOGO_URL;

enum EPrefixGroup {
  CONSUMER = "CONSUMERS_",
  PROVIDER = "PROVIDERS_",
}

export const PREFIX_GROUP = APP_BASE === EAppBase.PROVIDER ? EPrefixGroup.PROVIDER : EPrefixGroup.CONSUMER

if (!APP_BASE) {
  throw new Error('APP_BASE IS NOT DEFINED, SELECT BETWEEN CONSUMER OR PROVIDER');
}

export const AWS_CONFIG_AD = () => {
  const CONFIG = APP_BASE === EAppBase.PROVIDER ? AWS_PROVIDER_AD : AWS_CONSUMER_AD;
  return CONFIG
};

export const API_BASE =
  APP_BASE === EAppBase.CONSUMER ?
    process.env.REACT_APP_API_BASEURL_CONSUMER :
    process.env.REACT_APP_API_BASEURL_PROVIDER;

export const costumerXApiKey = process.env.REACT_APP_CONSUMER_X_API_KEY;
export const providerXApiKey = process.env.REACT_APP_PROVIDER_X_API_KEY;
