import { observer, inject } from 'mobx-react';
import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

const styles = (theme: Theme) =>
  createStyles({
    content:{
      zIndex: 99,
    },
    btnChange: {
      height: "32px",
      margin: "5px 8px 5px 5px",
    },
    btnInfo: {
      // fontWeight: "bold",
      backgroundImage: "linear-gradient(to bottom, #ffffff, #f7dea0 9%, #f1c24f)",
      color: "black",
      "&:hover": {
        boxShadow: "1px 1px 3px #ccc",
      },
      padding: "0px 10px",
    },
    btnIcon: {
      backgroundImage: "linear-gradient(to bottom, #ffffff, #f7dea0 9%, #f1c24f)",
      color: "black",
      "&:hover": {
        boxShadow: "1px 1px 3px #ccc",
      },
      minWidth: "0",
      width: "25px",
    },
    dropDownItem: {
      borderBottom: "1px solid #f1f1f1",
      padding: "4px 15px",
    }
  });

interface IProps {
  classes?: any;
  intl: any;
  children?: React.ReactNode;
  stores?: any;
  options: Array<string>;
  selectedItem: string;
  handleItemCLick: any,
  buttonName: string;
}

@inject('stores')
@observer
class SelectGroupButton extends PureComponent<IProps> {
  public state = {
    open: false,
    anchorEl: null,
    placement: undefined
  };

  componentDidMount() {
  };

  componentDidUpdate() {
  };

  public handleMenuItemClick = (event: any) => {
    const { buttonName } = this.props;
    document.getElementById(buttonName)?.click()
  };

  public handleToggle = (event: any) => {
    const { open } = this.state;
    this.setState({
      open: !open,
      anchorEl: event.currentTarget,
      placement: 'bottom-end'
    })
  };

  public handleClose = () => {
    this.setState({ open: false })
  };

  private handleItemClick = (event: any) => {
    const { handleItemCLick} = this.props;
    
    this.setState({ open: false })
    handleItemCLick(event)
  }

  public render() {
    const { open, anchorEl, placement } = this.state;
    const {
      options,
      classes,
      selectedItem,
      buttonName
    } = this.props;

    console.log("SelectGroupButton.render", options)
    return (
      <div className={classes.content}>

        <ButtonGroup
          className={classes.btnChange}
          variant="contained"
          aria-label="split button">
          <Button
            className={classes.btnInfo}
            id={buttonName}
            onClick={this.handleToggle}
          >{selectedItem}</Button>
          <Button
            className={classes.btnIcon}
            onClick={this.handleMenuItemClick}
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorEl} role={undefined} transition disablePortal placement={placement}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList id="split-button-menu">
                    {options && options.map((option, index) => (
                      <MenuItem
                        key={option}
                        disabled={option === selectedItem}
                        selected={option === selectedItem}
                        onClick={(event) => this.handleItemClick(option)}
                        className={classes.dropDownItem}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    )
  }
}

export default withStyles(styles)(injectIntl(SelectGroupButton));
