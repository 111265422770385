import React from 'react';
import { createStyles, Theme, withStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    region: {
      display: "flex",
      height: "fit-content",
    },
    regionText: {
      fontSize: "12px",
      fontWeight: "normal",
      padding: "2px 3px",
      textTransform: "lowercase",
    },
  });

const customStyles = {
  region: {
    color: "#909090",
    background: '#fff3f3',
    border: "1px solid #ffbbbb",
  },
  subscription: {
    color: "#909090",
    background: '#f1f1f1',
    border: "1px solid #cacaca",
  }
}

interface IProps {
  classes?: any;
  content: string;
  margin?: string;
  float?: "left" | "right" | "none";
  styleType: "region" | "subscription";
}

const InfoBox = ({ classes, content, margin, float, styleType }: IProps) => {
  const style = customStyles[styleType];
  return (
  <div 
  className={`${[classes.region]}`} 
  style={{ 
    margin: margin, 
    float: float,
    color: style.color,
    background: style.background,
    border: style.border 
    }} >
    <span className={classes.regionText}>{content}</span>
  </div >
)};

export default withStyles(styles)(InfoBox);
