import {observable, action} from 'mobx';
import AppItemStore from './ApiItemStore';
import {IAPIDetails} from '../entities/IAPIDetails';
import { Integer } from 'aws-sdk/clients/translate';

class RequestAppItemStore extends AppItemStore {
  @observable
  public apiDetails = {} as IAPIDetails

  @observable
  public selectedItemIndex = 0

  @observable
  public selectedItemIndexPlan = 0

  @observable
  public selectedMethods = new Map()

  @observable
  public openDocsModal = false

  @observable
  public openRevisionModal = false

  @action
  public addSelectedMethod = (key: string, data: any) => {
    this.selectedMethods.set(key, data)
  }

  @action
  public removeSelectedMethod = (key: string) => {
    this.selectedMethods.delete(key)
  }

  @action
  public setAPIDetails = (apiDetails: IAPIDetails) => {
    this.apiDetails = apiDetails;
  }

  @action
  public setSelectedItemIndex = (selectedItemIndex: Integer) => {
    this.selectedItemIndex = selectedItemIndex
  }

  @action
  public setSelectedMethods = (selectedMethods: Map<String, any>) => {
    this.selectedMethods = selectedMethods
  }

  @action
  public setOpenDocsModal = (openDocsModal: boolean) => {
    this.openDocsModal = openDocsModal
  }

  @action
  public setOpenRevisionModal = (openRevisionModal: boolean) => {
    this.openRevisionModal = openRevisionModal
  }

  @action
  public setSelectedItemIndexPlan = (selectedItemIndexPlan: Integer) => {
    this.selectedItemIndexPlan = selectedItemIndexPlan
  }
}

export default RequestAppItemStore;
