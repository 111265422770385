import {observable, action} from 'mobx';
import {IAPIList} from '../entities/IAPIList';
import {IApplicationDetails} from '../entities/IApplicationDetails';

class ConsumerMyApisStore {

  @observable 
  public isLoading = true;

  @action 
  public setLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  }
  
  @observable
  public success = true;

  @action
  public setSuccess(success: boolean) {
    this.success = success;
  }

  @observable
  public apiList = new Array<IAPIList>();

  @action
  public setAPIList = (apiList: IAPIList[]) => {
    this.apiList = apiList;
  } 

  @observable
  public appDetails: any;

  @action
  public setApplicationDetails(appDetails: IApplicationDetails) {
    this.appDetails = appDetails;
  }
}

export default ConsumerMyApisStore;