import RequestMethodService from '../services/RequestMethodService';

class BlockConsumerMethodUseCase {
  public execute = async (
    consumerId: string,
    applicationId: string,
    requestId: string,
    locked: boolean,
  ) => {
    const service = new RequestMethodService();
    const result = await service.requestMethods({
      applications: [
        {
          consumerId,
          applicationId,
          requests: [
            {
              requestId,
              approved: !locked,
            },
          ],
        },
      ],
    });
    return result;
  };
}

export default BlockConsumerMethodUseCase;
