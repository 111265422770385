import React from 'react';
import { Box } from '@material-ui/core';

interface IProps {
  method: string;
}

const MethodLabel = ({ method }: IProps) => {
  const colors = {
    GET: '#62AFFE',
    POST: '#49CC90',
    PUT: '#FCA12F',
    DELETE: '#FF3333',
  };

  return (
    <Box
      color="white"
      padding="0.5em"
      width="6em"
      display="flex"
      alignItems="center"
      justifyContent="center"
      fontSize="1.1em"
      bgcolor={(colors as any)[method.toUpperCase()] || '#888888'}
    >
      {method}
    </Box>
  );
};

export default MethodLabel;
