import SDKTypesService from '../services/SDKTypesService';

class SDKTypesUseCase {
  public execute = async() => {
    const sdkTypesService = new SDKTypesService()
    const result = await sdkTypesService.loadSDKTypes()
    return result;
  }
}

export default SDKTypesUseCase;
