import React, { Component } from 'react';
import { observer } from 'mobx-react';
import {
  createStyles,
  Theme,
  withStyles,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from '@material-ui/core';

import Error from '../error/Error';
import Loading from '../loading/Loading';
import MethodLabel from '../../ui/methodlabel/MethodLabel';
import ConsumerApplicationsListUseCase from '../../../../../core/lib/useCases/ConsumerApplicationsListUseCase';
import LockButton from '../lockbutton/LockButton';
import ConsumerBlockDetailsStore from '../../../../../core/lib/stores/ConsumerBlockDetailsStore';
import { IProviderAPIList } from '../../../../../core/lib/entities/IProviderAPIList';
import { FormattedMessage } from 'react-intl';
import SimpleTabs from '../simpletabs/SimpleTabs';
import IApplication, { IMethodApplication } from '../../../../../core/lib/entities/IApplication';
import BlockConsumerMethodUseCase from '../../../../../core/lib/useCases/BlockConsumerMethodUseCase';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
    },
    list: {
      marginLeft: -24,
      marginRight: -24,
      marginBottom: '2rem',
    },
    listItemRoot: {
      marginBottom: 1,
    },
    listItemContainer: {
      '&:nth-child(odd)': {
        backgroundColor: '#efefef',
      },
    },
    listItemText: {
      marginLeft: '0.5rem',
    },
  });

interface IProps {
  classes?: any;
  apis: IProviderAPIList[];
  consumerId: string;
  disabled?: boolean;
}

@observer
class ConsumerBlocksPanel extends Component<IProps> {
  private store = new ConsumerBlockDetailsStore();
  private consumerApplicationsListUseCase = new ConsumerApplicationsListUseCase();
  private blockConsumerMethodUseCase = new BlockConsumerMethodUseCase();

  public componentDidMount() {
    this.loadConsumer(this.props.consumerId);
  }

  public async loadConsumer(consumerId: string) {
    this.store.loadingStart();

    const result = await this.consumerApplicationsListUseCase.execute(consumerId);

    if (result.ok && result.data) {
      this.store.loadingSuccess(result.data);
    } else {
      this.store.loadingError(result);
    }
  }

  public getApiName(apiId: string): string {
    const { apis } = this.props;

    const foundApi = apis.find(api => api.id === apiId);

    if (foundApi) {
      return foundApi.name;
    }

    return apiId;
  }

  public handleLockChange = async (
    app: IApplication,
    method: IMethodApplication,
    locked: boolean
  ) => {
    const { consumerId } = this.props;

    await this.blockConsumerMethodUseCase.execute(
      consumerId,
      app.applicationId,
      method.requestId,
      locked
    );

    const result = await this.consumerApplicationsListUseCase.execute(consumerId);

    if (result.ok && result.data) {
      this.store.updateSuccess(result.data);
    } else {
      this.store.updateError(result);
    }
  };

  public render() {
    const { classes, disabled } = this.props;

    if (this.store.loading) {
      return <Loading />;
    }

    if (this.store.error) {
      return <Error />;
    }

    const applications = this.store.applications;

    return (
      <Box className={classes.container}>
        {applications &&
          applications.map(app => {
            if (!app.accesses) {
              return null;
            }

            return (
              <Box key={app.applicationId}>
                <Typography variant="h3">
                  <FormattedMessage id="blocks.application" />: {app.name}
                </Typography>
                {app.accesses &&
                  app.accesses.map(access => (
                    <SimpleTabs
                      key={access.apiId}
                      header={<Typography variant="h4">{this.getApiName(access.apiId)}</Typography>}
                      tabs={access.stages.map(stage => ({
                        key: stage.stageName,
                        title: stage.stageName,
                        content: (
                          <List className={classes.list}>
                            {stage.methods.map(method => (
                              <ListItem
                                key={`${method.httpMethod}${method.resourceId}`}
                                disableGutters
                                classes={{
                                  root: classes.listItemRoot,
                                  container: classes.listItemContainer,
                                }}
                              >
                                <ListItemIcon>
                                  <MethodLabel method={method.httpMethod} />
                                </ListItemIcon>
                                <ListItemText
                                  className={classes.listItemText}
                                  primary={method.path}
                                />
                                <ListItemSecondaryAction>
                                  <LockButton
                                    disabled={disabled}
                                    locked={!method.approved}
                                    onChange={(event, locked) =>
                                      this.handleLockChange(app, method, locked)
                                    }
                                  />
                                </ListItemSecondaryAction>
                              </ListItem>
                            ))}
                          </List>
                        ),
                      }))}
                    />
                  ))}
              </Box>
            );
          })}
      </Box>
    );
  }
}

export default withStyles(styles)(ConsumerBlocksPanel);
