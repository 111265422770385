import Api from '../frameworks/api/Api';

class ConsumerService {
  private Api = new Api();

  public getConsumers = () => {
    return this.Api.get('consumer');
  };

  public getConsumerApplications = (consumerId: string) => {
    return this.Api.get(`consumer/${consumerId}/applications`);
  };

  public updateConsumerAccess = (consumerId: string, isEnabled: boolean) => {
    return this.Api.post(`consumer/${consumerId}/access`, {
      isEnabled,
    });
  };
}

export default ConsumerService;
