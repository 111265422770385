import React, {Component} from 'react';
import { createStyles, Theme, Typography, withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Loading from '../../general/loading/Loading';
import ApplicationsListUseCase from '../../../../../core/lib/useCases/ApplicationsListUseCase';
import {observer, inject} from 'mobx-react';
import Stores from '../../../../../core/lib/stores/Stores';
import Error from '../../general/error/Error';
import {toJS} from 'mobx';
import ApiItem from '../../general/appitem/AppItem';
import APIRegister from '../../general/apiregister/APIRegister';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      width: "75%",
      flexGrow: 1,
      margin: "3rem auto",
      backgroundColor: "#eaeded",
      marginTop: "100px",
      marginBottom: "20px"
    },
    content: {
      width: "100vw",
      display: "flex",
      flexDirection: "column",
      overflow: "hidden auto",
      alignItems: "center"
    },
    register: {
      margin: "2rem 0"
    }
  });

interface IProps {
  classes: any,
  stores: Stores
}

@inject('stores')
@observer
class ConsumerHome extends Component<IProps> {
  public componentDidMount() {
    this.loadApplications();
  }

  public updateList = async () => {
    const {stores} = this.props;
    stores.consumerHomeStore.setLoading(true);
    this.loadApplications();
  }

  public loadApplications = async() => {
    const {stores} = this.props;
    stores.consumerHomeStore.setLoading(true);
    
    const useCase = new ApplicationsListUseCase();
    const result = await useCase.execute();
    stores.consumerHomeStore.setSuccess(result.ok);
    stores.consumerHomeStore.setLoading(false);
    stores.consumerHomeStore.setApplicationList(result.data as []);
  }

  public render() {
    const {classes, stores} = this.props;

    if (stores.consumerHomeStore.isLoading) {
      return <Loading/>
    }

    if (!stores.consumerHomeStore.success) {
      return <Error/>;
    }

    return (
      <div className={classes.container}>
        <Typography variant="h1">
        <FormattedMessage id="home.yourapps" />
        </Typography>
        {
          toJS(stores.consumerHomeStore.applicationList).map(app => (
            <div className={classes.containerapitem} key={app.applicationId}>
              <ApiItem
                key={app.applicationId}
                application={app} />
            </div>
          ))
        }
       <APIRegister
          apps={stores.consumerHomeStore.applicationList}
          updateList={this.updateList} />
      </div>
    );
  }

}

export default withStyles(styles)(ConsumerHome);
