import SaveStageConfigService from '../services/SaveStageConfigService';

class SaveStageConfigUseCase {
  public execute = async(apiId: string, payload: {}, subscription: string, region: string) => {
      
    const service = new SaveStageConfigService()
    const result = await service.save(apiId, payload, subscription, region)
    return result
  }
}

export default SaveStageConfigUseCase;
