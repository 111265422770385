import { toJS } from 'mobx';
import uuid from 'uuid';
import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { createStyles, Theme, withStyles, Select, MenuItem, Button } from '@material-ui/core';
import LaunchOutlined from '@material-ui/icons/LaunchOutlined';

import APIDetailsModal from '../apidetailsmodal/APIDetailsModal';
import { IAppDetailsAccesses, IMethod, IUsagePlan } from '../../../../../core/lib/entities/IApplicationDetails';
import IApplication from '../../../../../core/lib/entities/IApplication';
import CostTransform from '../costtransform/CostTransform';
import InfoBox from '../infoBox/InfoBox'
import SDKDownloaderModal from '../sdkdownloadmodal/SDKDownloaderModal';


interface IProps {
  appDetails: IAppDetailsAccesses,
  application: IApplication,
  classes: any,
  intl: any
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      marginTop: "10px",
      marginBottom: "10px",
      border: "solid 1px orange",
      borderRadius: "4px"
    },
    title: {
      display: "flex",
      backgroundColor: "#fba12f",
      minHeight: "40px",
      textTransform: "uppercase",
      color: "#fff",
      alignItems: "center",
      paddingLeft: "10px",
      textAlign: "left",
      fontSize: "18px",
      fontWeight: "bold",
      borderRadius: "2px",
      justifyContent: "space-between",
    },
    divapiinfos:{
      position: "relative",
    },
    apidescription: {
      marginTop: "10px",
      fontSize: "13px",
      marginLeft: "10px",
      color: "#888"
    },
    titlestages: {
      marginLeft: "10px",
      fontSize: "22px",
      fontWeight: "bold",
      marginTop: "10px",
      display: "flex",
      flexDirection: "column"
    },
    select: {
      fontWeight: "normal"
    },
    methods: {
      marginLeft: "10px",
      marginRight: "10px",
      marginTop: "20px"
    },
    httpmethod: {
      width: "50px",
      height: "40px",
      backgroundColor: "red",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      color: "#fff",
      fontWeight: "bold",
      fontSize: "12px",
      borderRadius: "4px",
      marginLeft: "4px"
    },
    post: {
      backgroundColor: "#49CC90"
    },
    get: {
      backgroundColor: "#62AFFE"
    },
    delete: {
      backgroundColor: "#FF3333"
    },
    put: {
      backgroundColor: "#FCA12F"
    },
    methoddeleted: {
      backgroundColor: "#b5b5b5"
    },
    methodbox: {
      width: "100%",
      borderRadius: "4px",
      marginBottom: "10px",
      height: "48px",
      display: "flex",
      alignItems: "center",
      cursor: "pointer"
    },
    methodboxdeleted: {
      backgroundColor: "#dcdcdc",
      border: "solid 1px #b5b5b5"
    },
    methodboxpost: {
      backgroundColor: "#EEF9F4",
      border: "solid 1px #49CC90"
    },
    methodboxget: {
      backgroundColor: "#F0F7FF",
      border: "solid 1px #62AFFE"
    },
    methodboxput: {
      backgroundColor: "#F0F7FF",
      border: "solid 1px #FCA12F"
    },
    methodboxdelete: {
      backgroundColor: "#ff9999",
      border: "solid 1px #FF3333"
    },
    operationname: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: "10px"
    },
    operationtitle: {
      fontSize: "14px",
      fontWeight: "bold"
    },
    deletedtitle: {
      color: "gray"
    },
    operationprice: {
      fontSize: "11px"
    },
    methodstatus: {
      marginLeft: "auto",
      marginRight: "10px",
      fontSize: "12px",
      fontWeight: "bold"
    },
    waiting: {
      color: "#000"
    },
    rejected: {
      color: "#f00"
    },
    deleted: {
      color: "#4c4c4c"
    },
    titlestagesbox: {
      display: "flex",
      flexDirection: "row"
    },
    boxbuttondetails: {
      marginLeft: "auto",
      marginRight: "10px"
    },
    buttonDetails: {
      backgroundImage: "linear-gradient(to bottom, #fcfcfd, #f5f6f8 9%, #c4c5c6)",
      textTransform: "none",
      border: "1px solid #ADB1B8",
      "&:hover": {
        boxShadow: "1px 1px 3px #ccc"
      },
    },
    boxstages: {
      display: "flex",
      flex: "1",
      flexDirection: "row"
    },
    boxbtnsdk: {
      alignSelf: "center",
      marginLeft: "auto",
      marginRight: "14px"
    }
  });

class APIDetails extends Component<IProps> {
  public state = { selectedItemIndex: 0, modalIsOpen: false, selectedMethod: {}, modalDownloaderIsOpen: false };

  public handleChange = (event: any) => {
    this.setState({ selectedItemIndex: event.target.value })
  }

  public renderOptions = () => {
    const { appDetails } = this.props;
    return (
      appDetails.stages.map((stage, index) => (
        <MenuItem
          value={index}
          key={index}>
          {stage.stageName.toUpperCase()}
        </MenuItem>
      )
      )
    );
  }

  public getMethodColor = (httpmethod: string, deleted?: boolean) => {
    const { classes } = this.props;

    if (deleted)
      return classes.methoddeleted;

    switch (httpmethod) {
      case "POST":
        return classes.post;
      case "GET":
        return classes.get;
      case "DELETE":
        return classes.delete;
      case "PUT":
        return classes.put;
    }
  }

  public getMethodBoxColor = (httpmethod: string, deleted?: boolean) => {
    const { classes } = this.props;

    if (deleted)
      return classes.methodboxdeleted;

    switch (httpmethod) {
      case "POST":
        return classes.methodboxpost;
      case "GET":
        return classes.methodboxget;
      case "DELETE":
        return classes.methodboxdelete;
      case "PUT":
        return classes.methodboxput;
    }
  }

  public renderMethod = (methods: IMethod[], plan: IUsagePlan) => {
    const { classes } = this.props;
    return (
      methods.map(method => (
        <div key={uuid()}
          className={classes.methodbox + " " + this.getMethodBoxColor(method.httpMethod, (method.deleted || method.usagePlanDeleted))}
          onClick={() => this.showMethodDetails(method)}>
          <div className={classes.httpmethod + " " + this.getMethodColor(method.httpMethod, (method.deleted || method.usagePlanDeleted))}>
            {method.httpMethod}
          </div>
          <div className={classes.operationname}>
            <div className={classes.operationtitle + " " + (method.deleted && classes.deletedtitle)}>
              {(method.operationName == null) ? <FormattedMessage id="apidata.undefined" /> : method.operationName}
            </div>
            <div className={classes.operationprice}>
              {!method.deleted &&
                <CostTransform
                  method={method}
                  plan={plan} />
              }
            </div>
          </div>
          <div className={classes.methodstatus} key={uuid()}>
            {method.approved === undefined && (
              <div className={classes.waiting}><FormattedMessage id="method.waiting" /> </div>
            )}

            {method.approved === false && (
              <div className={classes.rejected} key={uuid()}><FormattedMessage id="method.rejected" /></div>
            )}

            {method.deleted === true && (
              <div className={classes.deleted} key={uuid()}><FormattedMessage id="method.deleted" /></div>
            )}
            
            {method.usagePlanDeleted === true && (
              <div className={classes.deleted} key={uuid()}><FormattedMessage id="method.usagePlanDeleted" /></div>
            )}
          </div>
        </div>
      ))
    );
  }

  public showMethodDetails = (method: any) => {
    this.setState({ selectedMethod: method, modalIsOpen: true });
  }

  public handleClose = () => {
    this.setState({ modalIsOpen: false });
  }

  public openSDKDownloader = () => {
    this.setState({ modalDownloaderIsOpen: true })
  }

  public handleCloseDownloader = () => {
    this.setState({ modalDownloaderIsOpen: false })
  }

  public render() {
    const { appDetails, classes } = this.props;
    const { selectedItemIndex, modalIsOpen, selectedMethod, modalDownloaderIsOpen } = this.state;

    return (
      <div className={classes.container}>
        <div className={classes.title}>
          {appDetails.name}
          <div className={classes.divapiinfos}>
            {appDetails.subs_name &&
              <InfoBox 
              content={appDetails.subs_name} 
              margin="0 5px"
              float="left"
              styleType="subscription"
              ></InfoBox>
            }
            {appDetails.region &&
              <InfoBox 
              content={appDetails.region} 
              margin="0 5px"
              float="right"
              styleType="region"
              ></InfoBox>
            }
          </div>
        </div>
        <div className={classes.apidescription}>
          {appDetails.description}
        </div>
        <div className={classes.boxstages}>
          <div className={classes.titlestages}>
            <div className={classes.titlestagesbox}>
              <div><FormattedMessage id="apidata.stages" /></div>
            </div>
            <div>
              <Select
                value={selectedItemIndex}
                className={classes.select}
                onChange={this.handleChange}>
                {this.renderOptions()}
              </Select>
            </div>
          </div>
          <div className={classes.boxbtnsdk}>
            <Button
              className={classes.buttonDetails}
              onClick={this.openSDKDownloader}>
              <FormattedMessage id="api.downloadsdk" />
              <LaunchOutlined className={classes.launch} />
            </Button>
          </div>
        </div>
        <div className={classes.methods}>
          {
            this.renderMethod(toJS(appDetails.stages[selectedItemIndex].methods), appDetails.stages[selectedItemIndex].usagePlan)
          }
        </div>
        <APIDetailsModal
          isOpen={modalIsOpen}
          handleClose={this.handleClose}
          method={selectedMethod}
          endpoint={appDetails.stages[selectedItemIndex].endpoint}
          plan={appDetails.stages[selectedItemIndex].usagePlan} />

        <SDKDownloaderModal
          isOpen={modalDownloaderIsOpen}
          handleClose={this.handleCloseDownloader}
          appId={appDetails.apiId}
          stageName={toJS(appDetails.stages[selectedItemIndex]).stageName}
        />
      </div>
    )
  }
}

export default withStyles(styles)(injectIntl((APIDetails)));
