import APIListService from '../services/APIListService';

class APIListUseCase {
  public execute = async(
    subscription: string, 
    region: string) => {

    const apiList = new APIListService();
    const result = await apiList.getAPIList(subscription, region);
    return result;
  }
}

export default APIListUseCase;