import { inject } from 'mobx-react';
import React, { PureComponent } from 'react';
import Amplify, { Hub } from 'aws-amplify';
import { injectIntl, FormattedMessage } from 'react-intl';
import {
  Button,
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core';

import { AWS_CONFIG_AD } from '../../../../../core/lib/settings/Constants';
import GetCurrentSessionUseCase from '../../../../../core/lib/useCases/GetCurrentSessionUseCase';
import ImgClose from '../../../assets/img/close.png';
import Logo from '../logo/Logo'
import Loading from '../loading/Loading';
import HeaderLogin from '../header-login/HeaderLogin'

Amplify.Auth.configure(AWS_CONFIG_AD());

const styles = (theme: Theme) =>
  createStyles({
    divContent: {
      height: "100%"
    },
    toast: {
      display: "flex",
      top: "0",
      left: "0",
      width: "100%",
      boxShadow: "0 0 5px 0 rgba(0,0,0,0.3)",
      padding: "16px",
      backgroundColor: "#31465f",
      fontSize: "14px",
      color: "#fff",
    },
    error: {
      display: "flex",
      top: "0",
      left: "0",
      width: "100%",
      boxShadow: "0 0 5px 0 rgba(0,0,0,0.3)",
      padding: "16px",
      backgroundColor: "#ce2c2c",
      fontSize: "14px",
      color: "#fff",
    },
    toastCloseImg: {
      position: "absolute",
      right: "10px",
      width: "20px",
      cursor: "pointer",
    },
    divForm: {
      textAlign: "center",
      marginTop: "20px",
      margin: "5% auto 50px",
      height: "100%",
      verticalAlign: "middle"
    },
    divLogo: {
      margin: "auto",
      padding: "40px 0px",
      width: "400px",
    },
    divBox: {
      position: "relative",
      margin: "auto",
      marginTop: "20%",
      backgroundColor: "#fff",
      padding: "35px 40px",
      width: "460px",
      borderRadius: "6px",
      boxShadow: "1px 1px 4px 0 rgba(0,0,0,0.15)",
      boxSizing: "border-box",
    },
    divTitle: {
      display: "inline-grid",
      margin: "auto",
    },
    spanTitle: {
      color: "#7c7c7c",
      margin: "0px 0 10px 0",
      fontSize: "18px",
    },
    divButton: {

    },
    btnLogin: {
      minWidth: "153px",
      fontSize: "12px",
      verticalAlign: "middle",
      cursor: "pointer",
      color: "#fff",
      backgroundColor: "#ff9900",
      borderColor: "#ccc",
      textTransform: "uppercase",
      padding: "14px 0",
      letterSpacing: "1.1px",
      "&:hover": {
        background: "#ea8e04"
      }
    },
  });

interface IProps {
  children?: React.ReactNode;
  classes?: any;
  intl: any;
  stores?: any;
  title: string;
}

@inject('stores')
class UserLogin extends PureComponent<IProps> {
  public state = {
    authState: null,
    showToast: false,
    showError: false,
    errorMessage: "",
    isLoading: true,
  };

  componentDidMount() {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          this.validateLoggedUser()
          break;
        case "signOut":
          this.handleStateChange('signIn');
          break;
      }
    });
    this.validateLoggedUser()
  }

  public validateLoggedUser = async () => {
    try {
      const useCase = new GetCurrentSessionUseCase();
      const response = await useCase.execute();

      console.log("validateLoggedUser", response)
      if (response.success) {
        this.hideToast()
        this.handleStateChange('signedIn');
      }
      else {
        if (response.error === "error_get_subscriptions")
          this.showError("Erro ao consultar as subscriptions")
        else if (response.error === "no_subscriptions")
          this.showToast()
        this.handleStateChange('signIn');
      }
    }
    catch (err) {
      console.log(err)
      this.showError(err && err.message)
      this.handleStateChange('signIn');
    }
  }

  public showError = (err: string = "Ocorreu um erro") => {
    this.setState({ showError: true, errorMessage: err });
    this.setState({ showToast: false });
  }

  public showToast = () => {
    this.setState({ showError: false });
    this.setState({ showToast: true });
  }

  public hideError = () => {
    this.setState({ showError: false });
  }

  public hideToast = () => {
    this.setState({ showToast: false });
  }

  async signin() {
    this.setState({ isLoading: true });
    this.hideToast()
    const { stores } = this.props;
    stores.headerContextStore.setSelectedSubscription("")
    await Amplify.Auth.federatedSignIn();
  }

  public componentDidUpdate = () => {
    this.setState({ isLoading: false });
  };

  public handleStateChange = (authState: string) => {
    this.setState({ authState });
  };

  public render() {
    const { children, classes, title } = this.props;
    const {
      authState,
      showToast,
      isLoading,
      showError,
      errorMessage
    } = this.state;

    if (isLoading) {
      return <Loading />
    }

    console.log("LOGIN.RENDER",
      showToast,
      showError,
      authState)
    return (
      <div className={classes.divContent}>
        {authState !== 'signedIn' &&
          (
            <HeaderLogin title={title} />
          )}
        {showError && (
          <div className={classes.error}>
            {errorMessage}
            <a onClick={this.hideError} >
              <img src={ImgClose} alt="" className={classes.toastCloseImg} />
            </a>
          </div>
        )}
        {showToast && (
          <div className={classes.toast}>
            <FormattedMessage id="login.invalidgroup" />
            <a onClick={this.hideToast} >
              <img src={ImgClose} alt="" className={classes.toastCloseImg} />
            </a>
          </div>
        )}
        <React.Fragment>{authState === 'signedIn' ? children : null}</React.Fragment>

        {authState !== 'signedIn' &&
          (

            <div className={classes.divForm}>
              <div className={classes.divBox}>
                <div className={classes.divTitle}>
                  <span className={classes.spanTitle}>
                    <FormattedMessage id="login.welcomemessage" />
                  </span>
                </div>
                <div className={classes.divButton}>
                  <Button
                    onClick={() => this.signin()}
                    className={classes.btnLogin}>
                    <FormattedMessage id="login.button" />
                  </Button>
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}

export default withStyles(styles)(injectIntl(UserLogin));
