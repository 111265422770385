import APIDetailsService from '../services/APIDetailsService';

class APIDetailsUseCase {
  public execute = async (
    apiId: string, 
    subscription: string, 
    region:string,
    context?: any,
    ) => {
    const apiDetails = new APIDetailsService();
    const result = await apiDetails.getAPIDetails(apiId, subscription, region, context);
    return result;
  }
}

export default APIDetailsUseCase;