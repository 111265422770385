import {observable, action, computed} from 'mobx';
import { IRequests } from '../entities/IRequests';

class ProviderRequestsStore {
  @observable
  public isLoading = true;

  @action
  public setLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  }

  @observable
  public success = true;

  @action
  public setSuccess(success: boolean) {
    this.success = success;
  }

  @observable
  public requestList = {} as IRequests

  @action
  public setRequests = (requests: IRequests) => {
    this.requestList = requests
  }

  @observable
  public selectedFilter = "pending"

  @action
  public setSelectedFilter = (filter: string) => {
    this.selectedFilter = filter
  }
}

export default ProviderRequestsStore;
