import Api from '../frameworks/api/Api';
import uuid from 'uuid';

class ApplicationRegisterService {
  private Api = new Api();

  public registerApp = (appName: string) => {
    return this.Api.post("application", {name: appName, id: uuid()});
  }
}

export default ApplicationRegisterService;