import React from 'react';
import {
  createStyles,
  withStyles,
  Theme,
  Box,
  Typography,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import LazyExpansionPanel from '../../general/lazyexpansionpanel/LazyExpansionPanel';
import LockButton from '../../general/lockbutton/LockButton';
import { IProviderAPIList } from '../../../../../core/lib/entities/IProviderAPIList';
import APIBlocksPanel from '../apiblockspanel/APIBlocksPanel';

const styles = (theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    headerButton: {
      marginRight: theme.spacing(1),
    },
  });

interface IProps {
  classes?: any;
  api: IProviderAPIList;
  onLockApi: (apiId: string, locked: boolean, apiContext: string) => Promise<any>;
  apiContext: string;
}

class APIBlocks extends React.PureComponent<IProps> {
  public onLockApi = (event: any, locked: boolean) => {
    const { apiContext } = this.props;
    // prevent clicks on the lock button to open/close the expandable panel
    event.stopPropagation();

    return this.props.onLockApi(this.props.api.id, locked, apiContext);
  };

  public render() {
    const { api, classes } = this.props;

    return (
      <LazyExpansionPanel
        summary={
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Box className={classes.header}>
              <Box className={classes.headerButton}>
                <LockButton
                  locked={!api.isEnabled}
                  onChange={this.onLockApi} />
              </Box>
              <Typography variant="h3">{api.name}</Typography>
            </Box>
          </ExpansionPanelSummary>
        }
        details={
          <ExpansionPanelDetails>
            <APIBlocksPanel key={api.id} apiId={api.id} apiContext={api.context} />
          </ExpansionPanelDetails>
        }
      />
    );
  }
}

export default withStyles(styles)(APIBlocks);
