import { Auth } from 'aws-amplify';
import SubscriptionService from '../services/SubscriptionService';

class GetCurrentSessionUseCase {
  public execute = () => {
    return Auth.currentAuthenticatedUser()
      .then(async user => {
        const subscriptionService = new SubscriptionService();
        const subscriptions = await subscriptionService.getSubscriptions();
        console.log('currentAuthenticatedUser',
          user,
          subscriptions)
        if (subscriptions && !subscriptions.ok)
          return {
            success: false,
            error: "error_get_subscriptions"
          }
        if (!subscriptions ||
          !subscriptions.data ||
          (subscriptions.data as Array<string>).length === 0)
          return {
            success: false,
            error: "no_subscriptions"
          }
        return {
          success: true,
          data: user
        }

      })
      .catch(err => {
        console.log('currentAuthenticatedUser.err', err)
        if (err === "not authenticated")
          return {
            success: false,
            error: "not_authenticated"
          }
        throw err;
      })
  }
}

export default GetCurrentSessionUseCase