import React from 'react';
import { createStyles, Theme, withStyles, Tabs, Box, Tab } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '0.5rem',
    },
    tabsRoot: {
      minHeight: 'auto',
    },
    tabRoot: {
      minWidth: 'auto',
      minHeight: 'auto',
    },
  });

interface ITab {
  key: string;
  title: React.ReactElement<any> | string;
  content: React.ReactElement<any> | string;
}

interface IProps {
  classes?: any;
  header?: React.ReactElement<any>;
  tabs: ITab[];
}

interface IState {
  currentTabKey?: string;
}

class SimpleTabs extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const initialTabKey = props.tabs && props.tabs.length > 0 ? props.tabs[0].key : undefined;

    this.state = {
      currentTabKey: initialTabKey,
    };
  }

  public handleChange = (event: any, newValue: string) => {
    this.setState({
      currentTabKey: newValue,
    });
  };

  public render() {
    const { classes, header, tabs } = this.props;
    const { currentTabKey } = this.state;

    const currentTab = currentTabKey && tabs.find(tab => tab.key === currentTabKey);

    return (
      <Box>
        <Box className={classes.header}>
          {header || <span />}

          <Tabs
            value={currentTabKey}
            onChange={this.handleChange}
            classes={{ root: classes.tabsRoot }}
          >
            {tabs.map(tab => (
              <Tab
                key={tab.key}
                value={tab.key}
                label={tab.title}
                disableRipple
                classes={{ root: classes.tabRoot }}
              />
            ))}
          </Tabs>
        </Box>

        {currentTab && <Box>{currentTab.content}</Box>}
      </Box>
    );
  }
}

export default withStyles(styles)(SimpleTabs);
