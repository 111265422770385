import { observer, inject } from 'mobx-react';
import React, { PureComponent } from 'react';
import { createStyles, Link, Theme, Typography, withStyles } from '@material-ui/core';
import { Link as RouterLink, matchPath, RouteComponentProps, withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import {
  APP_BASE,
  EPaths,
  EAppBase,
} from '../../../../../core/lib/settings/Constants';
import LogoAws from '../../../../src/assets/img/awslogo.png';
import LogoutImg from '../../../../src/assets/img/logout.png';
import IntlBar from '../internationalization/IntlBar';
import ImgDash from '../../../../src/assets/svgs/dash.svg';
import ImgHome from '../../../../src/assets/svgs/home.svg';
import SelectGroupButton from '../selectGroupButton/SelectGroupButton'
import Logo from '../logo/Logo'
import GetSubscriptionsUseCase from '../../../../../core/lib/useCases/GetSubscriptionsUseCase';

const styles = (theme: Theme) =>
  createStyles({
    container: {

      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: '64px',
      backgroundColor: '#212E3E',
    },
    logo: {
      width: '30px',
      height: '30px',
      backgroundColor: '#FE9C19',
      margin: '0 30px',
    },
    header: {
      color: '#FAFAFA',
      flexGrow: 1,
    },
    mainLogo: {
      width: "65px",
    },
    btn: {
      margin: '10px 8px 5px 5px',
      cursor: 'pointer',
    },
    information: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginRight: '10px',
      width: '100%',
    },
    imglogo: {
      maxHeight: '32px',
    },
    title: {
      minWidth: '280px',
    },
    selectedgroup: {
      display: "inherit",
      color: "#888",
      paddingLeft: "30px",
    },
    grouptext: {
      paddingTop: "15px",
      paddingRight: "5px",
    },
    btnselectedgroup: {
      margin: "0 10px",
      border: "1px solid #ccc",
      backgroundColor: "#ccc",
      textDecoration: "underline",
      color: "#334d6d",
      "&:hover": {
        backgroundColor: "#334d6d",
        border: "1px solid #fff",
        color: "#ccc",
        textDecoration: "underline",
      }
    },
    imgchangegroup: {
      width: "20px",
      paddingLeft: "5px"
    }
  });

interface IProps extends RouteComponentProps {
  changeGroup?: any;
  classes?: any;
  title?: string;
  stores?: any;
}


@inject('stores')
@observer
class Header extends PureComponent<IProps> {
  public state = {
    groupList: []
  };

  componentDidMount() {
    const { stores } = this.props;

    this.setGroupList()
    this.selectRegion(stores.headerContextStore.selectedRegion || 'us-east-1')
  };

  public callChangeGroup = () => {
    const { changeGroup } = this.props;
    changeGroup()
  }
  public logout = async () => {
    await Auth.signOut();
  };

  public setGroupList = () => {
    this.getUserGroups()
      .then((data: any) => {
        if (data.length === 0)
          Auth.signOut()
        this.setState({ groupList: data })
      })
  }

  public getUserGroups = async () => {
    const useCase = new GetSubscriptionsUseCase();
    const response = await useCase.execute();

    return response;
  }

  private regionList = [
    "us-east-1",
    "us-east-2",
    "us-west-1",
    "us-west-2",
    "eu-north-1",
    "ap-south-1",
    "eu-west-3",
    "eu-west-2",
    "eu-west-1",
    "ap-northeast-3",
    "ap-northeast-2",
    "ap-northeast-1",
    "sa-east-1",
    "ca-central-1",
    "ap-southeast-1",
    "ap-southeast-2",
    "eu-central-1",
  ]

  public selectSubscription = (newSubscription: string) => {
    const { stores } = this.props;
    stores.headerContextStore.setSelectedSubscription(newSubscription);
  }

  public selectRegion = (newRegion: string) => {
    const { stores } = this.props;
    stores.headerContextStore.setSelectedRegion(newRegion);
  }


  public render() {
    const { classes, title, stores } = this.props;
    const { groupList } = this.state;
    return (
      <header className={classes.container}>
        <Link component={RouterLink} to={`${EPaths.ROOT}`}>
          <img src={LogoAws} alt="Logo AWS" className={classes.mainLogo} />
        </Link>

        <Typography variant="h1" className={classes.header}>
          <div className={classes.title}>{title || 'Open API Portal'}</div>
        </Typography>
        <IntlBar />

        <div className={classes.information}>
          <Logo width="170px" />
          {!matchPath(this.props.location.pathname, EPaths.REQUESTS) &&
            <div className={classes.selectedgroup}>
              <span className={classes.grouptext}>
              </span>
              <SelectGroupButton
                buttonName='btnSubscription'
                handleItemCLick={this.selectSubscription}
                options={groupList}
                selectedItem={stores.headerContextStore.selectedSubscription}
              />
              <SelectGroupButton
                buttonName='btnRegion'
                handleItemCLick={this.selectRegion}
                options={this.regionList}
                selectedItem={stores.headerContextStore.selectedRegion || 'us-east-1'}
              />
            </div>
          }
        </div>
        {APP_BASE === EAppBase.PROVIDER && (
          <Link
            component={RouterLink}
            className={classes.btn}
            to={
              matchPath(this.props.location.pathname, EPaths.DASHBOARD)
                ? EPaths.ROOT
                : EPaths.REQUESTS
            }
          >
            {matchPath(this.props.location.pathname, EPaths.DASHBOARD) ? (
              <img src={ImgHome} alt="" />
            ) : (
                <img src={ImgDash} alt="" />
              )}
          </Link>
        )}

        <Link onClick={this.logout} className={classes.btn}>
          <img src={LogoutImg} alt="Logout" />
        </Link>
      </header>
    );
  }
}

export default withStyles(styles)(withRouter(Header));
