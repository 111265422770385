import React from 'react';
import {IMethod, IUsagePlan} from '../../../../../core/lib/entities/IApplicationDetails';
import { injectIntl } from 'react-intl';

interface IProps {
  intl: any,
  method: IMethod,
  plan: IUsagePlan
}

class CostTransformUseCase extends React.Component<IProps> {
  private transform = (method: IMethod, plan: IUsagePlan) => {
    const {intl} = this.props;

    let value = "(";
    let cost = 0;

    if(!plan)
      return ""

    const methodPlan = method.usagePlans && method.usagePlans.find(mp => mp.usagePlanId === plan.id);
    if (methodPlan && methodPlan.value !== null && methodPlan.value !== undefined) {
      cost = methodPlan.value;
    } else if (method.value !== null && method.value !== undefined) {
      cost = method.value
    } else if (plan && plan.value !== null && plan.value !== undefined) {
      cost = plan.value;
    }

    if (cost === 0)  {
      value += intl.formatMessage({id: 'cost.free'});
    } else {
      value += "US$ " + cost;
    }

    value += " / " + plan.quota.limit +  " " + intl.formatMessage({id: 'cost.interval'}) + " ";

    switch (plan.quota.period.toUpperCase()) {
      case "DAY":
        value +=  intl.formatMessage({id: 'cost.day'})
      break;
      case "WEEK":
        value += intl.formatMessage({id: 'cost.week'})
      break;
      case "MONTH":
        value += intl.formatMessage({id: 'cost.month'})
      break;
    }

    value += ")";

    return value;
  }

  public render() {
    const {method, plan} = this.props;
    return (
      <span>
        {this.transform(method, plan)}
      </span>
    );
  }
}

export default injectIntl(CostTransformUseCase);
