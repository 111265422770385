import Api from '../frameworks/api/Api';

class RegisterAPIService {
  private Api = new Api();

  public registerApis = (
    rootId: string, 
    apis: {}, 
    subscription: string, 
    region: string,
    headers?: any,
    ) => {
    const query = {subscription, region}
    return this.Api.post(`application/${rootId}/access/request`, apis, query, headers);
  }
}

export default RegisterAPIService;