import ProviderAPIListService from '../services/ProviderAPIListService';

class ProviderAPIListUseCase {
  public execute = async (subscription: string, region: string) => {

    const providerApiListService = new ProviderAPIListService()
    const result = await providerApiListService.loadAPIList(subscription, region)
    return result
  }
}

export default ProviderAPIListUseCase;
