import React, { Component } from 'react';
import { IAPIList } from '../../../../../core/lib/entities/IAPIList';
import { IAPIDetails, IAPIStage } from '../../../../../core/lib/entities/IAPIDetails';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ImgArrowDown from '../../../assets/img/arrow_down.png';
import ImgArrowUp from '../../../assets/img/arrow_up.png';
import { Button, createStyles, Theme, withStyles, Typography, Select, MenuItem } from '@material-ui/core';
import Loading from '../../general/loading/Loading';
import Error from '../error/Error';
import APIDetailsUseCase from '../../../../../core/lib/useCases/APIDetailsUseCase';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import { IMethod, IUsagePlan, IApplicationDetailsRoot } from '../../../../../core/lib/entities/IApplicationDetails';
import RequestAPIMethod from '../requestapimethod/RequestAPIMethod';
import { FormattedMessage } from 'react-intl';
import uuid from 'uuid';
import LaunchOutlined from '@material-ui/icons/LaunchOutlined';
import DocsModal from '../docsmodal/DocsModal';
import RevisionModal from '../revisionmodal/RevisionModal';
import InfoBox from '../infoBox/InfoBox'
import RequestAppItemStore from '../../../../../core/lib/stores/RequestAppItemStore';
import ImgWarning from '../../../assets/img/warning.png';

interface IProps {
  api: IAPIList,
  appDetails: IApplicationDetailsRoot,
  classes: any,
  rootId: string,
  stores?: any,
  apiContext: string;
  onRegisterMethod: () => void
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      marginTop: "1rem"
    },
    notapprovedcontainer:{
      color: "#9c9c9c",
      backgroundColor: "#dcdcdc",
      border: "solid 1px #b5b5b5",
      marginTop: "1rem",
    },
    summaryContent: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    detailsContainer: {
      display: "flex",
      flexDirection: "column",
      borderTop: "2px solid #ccc"
    },
    btn: {
      display: 'inline-block',
      padding: 3,
      minHeight: 0,
      minWidth: 0,
      marginRight: "6px"
    },
    apidescription: {
      fontSize: "13px",
      color: "#555",
      marginTop: "10px"
    },
    stagetitle: {
      fontSize: "22px",
      fontWeight: "bold",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center"
    },
    stages: {
      marginTop: "20px"
    },
    methods: {
      marginTop: "20px"
    },
    buttonDocs: {
      backgroundImage: "linear-gradient(to bottom, #fcfcfd, #f5f6f8 9%, #c4c5c6)",
      border: "1px solid #ADB1B8",
      textTransform: "capitalize",
      "&:hover": {
        boxShadow: "1px 1px 3px #ccc"
      }
    },
    containerdocs: {
      marginLeft: "auto"
    },
    buttonDef: {
      borderRadius: "5px",
      margin: "8px",
      border: "solid 1px #ad8d40",
      boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.75)",
      height: "60%",
      fontSize: "12px",
      width: "400px",
      textTransform: "capitalize",
      backgroundImage: "linear-gradient(to bottom, #ffffff, #f7dea0 9%, #f1c24f)",
      "&:hover": {
        boxShadow: "1px 1px 3px #ccc"
      }
    },
    containersave: {
      marginTop: "40px",
      marginBottom: "20px",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    stageselector: {
      marginLeft: "10px",
      fontWeight: "normal"
    },
    boxPlans: {
      marginLeft: "30px",
      display: "flex",
      flexDirection: "row"
    },
    planselector: {
      marginLeft: "10px",
      fontWeight: "normal"
    },
    firstColumn: {
      width: "55%",
    },
    accountAlias: {
      color: "#7d7d7d",
      width: "35%",
      display: "flex",
      justifyContent: "flex-end",
    },
    notApproved: {
      background: "#f5dada",
      color: "#000",
      fontSize: "12px",
      marginLeft: "10px",
      margin: "auto",
      padding: "4px 5px",
      textAlign: "center",
      width: "max-content",
    },
    warningIcon: {
      height: "10px",
      padding: "0 5px",
    },
  });

@inject("stores")
@observer
class RequestApiItem extends Component<IProps> {
  state = { countSubscribes: undefined }

  private store = new RequestAppItemStore();

  private changeExpanded = async () => {
    this.store.setExpanded(!this.store.isExpanded);

    if ((!this.store.isLoading) && (this.store.isExpanded)) {
      this.store.setSuccess(true);
      this.loadApiDetails();
    }
  }

  private loadApiDetails = async () => {
    const { api, stores, apiContext } = this.props;
    const { selectedSubscription, selectedRegion } = stores.headerContextStore
    this.store.setLoading(true);
    const useCase = new APIDetailsUseCase();
    const result = await useCase.execute(api.id, selectedSubscription, selectedRegion, apiContext);
    this.store.setSuccess(result.ok);

    if (result.ok) {
      this.store.setAPIDetails(result.data as IAPIDetails);
    }

    this.store.setLoading(false);
  }

  private openDocsModal = () => {
    this.store.setOpenDocsModal(true);
  }

  private handleCloseDocsModal = () => {
    this.store.setOpenDocsModal(false)
  }

  private handleChange = (event: any) => {
    this.setState({ countSubscribes: undefined })
    this.store.setSelectedItemIndexPlan(0)
    this.store.setSelectedItemIndex(event.target.value)
  }

  private handleChangePlan = (event: any) => {
    this.store.setSelectedItemIndexPlan(event.target.value)

    const stage = this.store.apiDetails.stages[this.store.selectedItemIndex];
    const usagePlan = stage.usagePlans[event.target.value];

    this.store.selectedMethods.forEach((selected: any, id: any) => {
      const selectedMethodStage = selected.stage as IAPIStage;

      if (selectedMethodStage.stageName === stage.stageName) {
        this.store.addSelectedMethod(id, {
          ...selected,
          plan: usagePlan
        });
      }
    });
  }

  private renderStageOptions = () => {
    if (toJS(this.store.apiDetails).stages.length > 0) {
      return (
        toJS(this.store.apiDetails).stages.map((stage: any, index: any) => (
          <MenuItem
            value={index}
            key={index}>
            {stage.stageName.toUpperCase()}
          </MenuItem>
        )));
    } else {
      return (
        <div></div>
      )
    }
  }

  private renderPlanOptions = () => {
    if (toJS(this.store.apiDetails).stages[this.store.selectedItemIndex] != null) {
      if (toJS(this.store.apiDetails).stages[this.store.selectedItemIndex].usagePlans.length > 0) {
        return (
          toJS(this.store.apiDetails).stages[this.store.selectedItemIndex].usagePlans.map((plan: any, index: any) => (
            <MenuItem
              value={index}
              key={index}>
              {plan.name.toUpperCase()}
            </MenuItem>
          ))
        )
      } else {
        return (
          <div></div>
        )
      }
    } else {
      return (
        <div></div>
      )
    }
  }

  private renderMethod = (method: IMethod) => {
    const { appDetails, api } = this.props;

    const plans = this.store.apiDetails.stages[this.store.selectedItemIndex].usagePlans;

    let alreadyExists = false;

    // Search if method already has signed
    if (appDetails &&
      appDetails.application &&
      appDetails.application.accesses) {
      let currentAPI = appDetails.application.accesses.find(access => access.apiId === api.id);

      if (currentAPI) {
        let currentStage = this.store.apiDetails.stages[this.store.selectedItemIndex].stageName;
        var searchStage = currentAPI!.stages.find(stage => stage.stageName === currentStage);

        if (searchStage) {
          let searchMethod = searchStage!.methods.find(searchmethod => ((searchmethod.path = method.path) && (searchmethod.httpMethod === method.httpMethod) && (searchmethod.resourceId === method.resourceId)));

          // Method & Stage found
          if (searchMethod) {
            alreadyExists = true
          }
        }
      }
    }

    const id = (method.httpMethod + method.path + this.store.apiDetails.stages[this.store.selectedItemIndex].stageName).toLowerCase();
    const verifyChecked = this.store.selectedMethods.get(id);
    const isChecked = (verifyChecked !== null) && (verifyChecked !== undefined);

    if (alreadyExists && this.state.countSubscribes === undefined) {
      this.setState({ countSubscribes: true })
      this.store.setSelectedItemIndexPlan(this.getIndexPlanByName(searchStage!.usagePlan.name))
    }

    return (
      <RequestAPIMethod
        plan={plans[this.store.selectedItemIndexPlan]}
        method={method}
        key={method.operationName}
        disabled={alreadyExists}
        handleChange={this.handleChangeMethod}
        id={id}
        checked={isChecked} />
    );
  }

  private handleChangeMethod = (id: string, method: IMethod, plan: IUsagePlan, checked: boolean) => {
    if (checked) {
      this.store.addSelectedMethod(id, { id, method, plan, stage: toJS(this.store.apiDetails).stages[this.store.selectedItemIndex] });
    } else {
      this.store.removeSelectedMethod(id);
    }
  }

  private getCurrentStage = () => {
    if (toJS(this.store.apiDetails) &&
      (toJS(this.store.apiDetails).stages) &&
      (toJS(this.store.apiDetails).stages.length > 0)) {
      return toJS(this.store.apiDetails).stages[this.store.selectedItemIndex].stageName;
    } else {
      return "";
    }
  }

  private closeRevisionModal = () => {
    this.store.setOpenRevisionModal(false);
  }

  private openRevision = () => {
    this.store.setOpenRevisionModal(true);
  }

  private renderStages = () => {
    if (toJS(this.store.apiDetails).stages.length > 0) {
      return (
        toJS(this.store.apiDetails).stages[this.store.selectedItemIndex].methods.map((method: any) => (
          <div key={uuid()}>
            {this.renderMethod(method)}
          </div>
        ))
      )
    } else {
      return (<div></div>)
    }
  }

  private getIndexPlanByName = (planName: string) => {
    var planIndex = 0

    if ((toJS(this.store.apiDetails).stages !== undefined)) {
      if (toJS(this.store.apiDetails).stages[this.store.selectedItemIndex] !== undefined) {
        if (toJS(this.store.apiDetails).stages[this.store.selectedItemIndex].usagePlans !== undefined) {
          let stage = toJS(this.store.apiDetails).stages[this.store.selectedItemIndex]

          for (var i = 0; i < stage.usagePlans.length; i++) {
            let plan = stage.usagePlans[i]
            if (plan.name.toUpperCase() === planName.toUpperCase()) {
              planIndex = i;
              break
            }
          }
        }
      }
    }

    return planIndex
  }

  public render() {
    const { api, classes, stores } = this.props;
    const imgArrow = (this.store.isExpanded) ? ImgArrowUp : ImgArrowDown;

    return (
      <div className={classes.container }>
        <ExpansionPanel expanded={this.store.isExpanded} className={api.isApproved ? classes.expansionpanel: classes.notapprovedcontainer}>
          <ExpansionPanelSummary classes={{ content: classes.summaryContent }}>
            <div className={classes.firstColumn}>
              <Typography variant="h1">
                {api.name}
              </Typography>
            </div>
            <div className={classes.accountAlias} >
              <InfoBox
                content={stores.headerContextStore.selectedSubscription}
                margin="0 6px"
                styleType="subscription"
              ></InfoBox>
              <InfoBox
                content={api.region}
                styleType="region"
              ></InfoBox>
            </div>
            <div>
              {api.isApproved &&
                <Button className={classes.btn}>
                  <img src={imgArrow} alt="" onClick={this.changeExpanded} />
                </Button>
              }
              {!api.isApproved && 
                <div className={classes.notApproved}>
                  <img src={ImgWarning} alt="" className={classes.warningIcon} />
                  <FormattedMessage id="api.notapproved" />
                </div>
              }
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails classes={{ root: classes.detailsContainer }}>
            {this.store.isLoading && (<Loading />)}
            {!this.store.success && (<Error />)}
            {!this.store.isLoading && this.store.success && this.store.apiDetails.stages !== undefined && (
              <div>
                <div className={classes.apidescription}>
                  {api.description}
                </div>
                <div className={classes.stages}>
                  <div className={classes.stagetitle}>
                    <FormattedMessage id="apidata.stages" />:
                    <div className={classes.stageselector}>
                      <Select
                        value={this.store.selectedItemIndex}
                        className={classes.select}
                        onChange={this.handleChange}>
                        {this.renderStageOptions()}
                      </Select>
                    </div>
                    <div className={classes.boxPlans}>
                      <FormattedMessage id="method.plan" />:
                      <div className={classes.planselector}>
                        <Select
                          value={this.store.selectedItemIndexPlan}
                          className={classes.select}
                          onChange={this.handleChangePlan}
                          disabled={this.state.countSubscribes !== undefined}>
                          {this.renderPlanOptions()}
                        </Select>
                      </div>
                    </div>
                    <div className={classes.containerdocs}>
                      <Button
                        className={classes.buttonDocs}
                        onClick={this.openDocsModal}
                        disabled={(toJS(this.store.apiDetails).stages.length === 0)}>
                        <FormattedMessage id="appitem.accessdocs" />
                        <LaunchOutlined className={classes.launch} />
                      </Button>
                    </div>
                  </div>
                  <div className={classes.methods}>
                    {
                      this.renderStages()
                    }
                  </div>
                  <div className={classes.containersave}>
                    <Button
                      className={classes.buttonDef}
                      disabled={(this.store.selectedMethods.size < 1)}
                      onClick={this.openRevision}>
                      <FormattedMessage id="method.subscribebutton" /> {this.store.selectedMethods.size > 0 ? "(" + this.store.selectedMethods.size + ")" : ""}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <DocsModal
          isOpen={this.store.openDocsModal}
          handleClose={this.handleCloseDocsModal}
          appId={api.id}
          stage={this.getCurrentStage()} />

        <RevisionModal
          appId={api.id}
          isOpen={this.store.openRevisionModal}
          handleClose={this.closeRevisionModal}
          apis={this.store.selectedMethods}
          rootId={this.props.rootId}
          onRegisterMethod={this.props.onRegisterMethod}
          apiContext={this.props.apiContext}
        />

      </div>
    )
  }
}

export default withStyles(styles)(RequestApiItem);
