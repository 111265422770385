import React from 'react';
import { createStyles, Theme, withStyles, Grid, List, ListItem } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import { EPaths } from '../../../../../core/lib/settings/Constants';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      minHeight: '100%',
      backgroundColor: '#eaeded',
      // marginTop: '64px',
    },
    nav: {
      width: '100%',
      minHeight: '100%',
      backgroundColor: '#445264',
      color: '#ffffff',
    },
    navItem: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    selected: {
      backgroundColor: 'rgba(0, 0, 0, 0.10)',
      borderLeft: `3px solid ${theme.palette.secondary.main}`,
    },
    content: {
      padding: theme.spacing(3),
    },
  });

interface IProps {
  classes?: any;
  children?: any;
}

const NavMenu = ({ classes, children }: IProps) => (
  <Grid container className={classes.container}>
    <Grid item xs={3} sm={2} className={classes.nav}>
      <List component="nav">
        <ListItem
          button
          component={NavLink}
          to={EPaths.REQUESTS}
          className={classes.navItem}
          activeClassName={classes.selected}
        >
          Requests
        </ListItem>

        <ListItem
          button
          component={NavLink}
          to={EPaths.BLOCKS}
          className={classes.navItem}
          activeClassName={classes.selected}
        >
          Blocks
        </ListItem>
      </List>
    </Grid>
    <Grid item xs={9} sm={10} className={classes.content}>
      {children}
    </Grid>
  </Grid>
);

export default withStyles(styles)(NavMenu);
