import React from 'react';
import { createStyles, Theme, withStyles, Typography } from '@material-ui/core';
import ErrorImg from "../../../assets/img/error.png";
import { FormattedMessage } from 'react-intl';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      width: "100%",
      textAlign: "center",
      flexDirection: "column"
    },
    img: {
      width: "100px",
      height: "100px",
      opacity: 0.4
    }
  });

interface IProps {
  classes?: any;
}

const Error = (props: IProps) => (
  <div className={props.classes.root}>
    <div>
      <img src={ErrorImg} alt="Error" className={props.classes.img}/>
    </div>
    <div>
      <Typography variant="h1">
        <FormattedMessage id="error.loading" />
      </Typography> 
    </div>
        
  </div>
)

export default withStyles(styles)(Error);