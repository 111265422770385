import DownloadSdkService from '../services/DownloadSdkService';

class DownloadSdkUseCase {
  public execute = async(apiId: string, stage: string, sdkId: string, parameters: object = {}) => {
    const downloadSdk = new DownloadSdkService();
    const result = await downloadSdk.download(apiId, stage, sdkId, parameters);
    return result;
  }
}

export default DownloadSdkUseCase;