import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { createStyles, Theme, withStyles, Select, MenuItem, Input, FormControl } from '@material-ui/core';
import { injectIntl } from 'react-intl';


interface IProps {
  classes?: any,
  intl: any,
  handleChangeSearchKey: any,
  handleChangeType?: any,
  searchtype: string,
  hideType?: boolean
}

const styles = (theme: Theme) =>
  createStyles({
    topsearch: {
      fontSize: "20px",
      fontWeight: "normal",
      display: "flex",
      flex: "1",
      flexDirection: "column",
      marginTop: "20px"
    },
    searchfields: {
      display: "flex",
      flexDirection: "row",
      marginTop: "20px",
      backgroundColor: "#fff",
      padding: "10px",
      border: "solid 1px #aaa",
      borderRadius: "10px"
    },
    filter: {
      marginLeft: "10px"
    },
    searchkey: {
      width: "90%"
    },
    searchtype: {
      width: "10%"
    },
  });


class SearchApiList extends Component<IProps> {
  public render() {
    const { 
      classes,
      intl,
      handleChangeSearchKey,
      handleChangeType,
      searchtype,
      hideType
    } = this.props

    return (
      <div className={classes.topsearch}>
        <div><FormattedMessage id="provider.hometitle" /></div>
        <div className={classes.searchfields}>
          <Input
            className={classes.searchkey}
            placeholder={intl.formatMessage({ id: 'provider.apisearch' })}
            disableUnderline={true}
            onChange={handleChangeSearchKey} />
          {hideType && <div className={classes.fieldtype}>
            <FormControl
              style={{ minWidth: 170 }}>
              <Select
                className={classes.filter}
                value={searchtype}
                onChange={handleChangeType}>
                <MenuItem key="0" value="0"><FormattedMessage id="api.all" /></MenuItem>
                <MenuItem key="1" value="1"><FormattedMessage id="api.available" /></MenuItem>
                <MenuItem key="2" value="2"><FormattedMessage id="api.unavailable" /></MenuItem>
              </Select>
            </FormControl>
          </div>}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(injectIntl(SearchApiList));