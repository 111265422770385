import React, { Component } from 'react';
import { createStyles, Theme, withStyles, Link } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import Stores from '../../../../../core/lib/stores/Stores';
import Error from '../../general/error/Error';
import Loading from '../../general/loading/Loading';
import SearchApiList from '../../general/searchApiList/SearchApiList';
import APIListUseCase from '../../../../../core/lib/useCases/APIListUseCase';
import ApplicationDetailsUseCase from '../../../../../core/lib/useCases/ApplicationDetailsUseCase';
import { IAPIList } from '../../../../../core/lib/entities/IAPIList';
import { IApplicationDetails } from '../../../../../core/lib/entities/IApplicationDetails';
import { RouteComponentProps } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { toJS, intercept } from 'mobx';
import { EPaths } from '../../../../../core/lib/settings/Constants';
import RequestAppItem from '../../../components/general/requestapiitem/RequestAppItem';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      width: "75%",
      flexGrow: 1,
      margin: "3rem auto",
      backgroundColor: "#eaeded",
      marginTop: "100px",
      marginBottom: "20px"
    },
    content: {
      width: "100vw",
      display: "flex",
      flexDirection: "column",
      overflow: "hidden auto",
      alignItems: "center"
    },
    register: {
      margin: "2rem 0"
    },
    linkBack: {
      color: theme.palette.primary.light,
      fontSize: "12px",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline"
      }
    },
    appName: {
      fontSize: "22px",
      fontWeight: "bold",
      marginTop: "20px"
    }
  });

interface IProps extends RouteComponentProps<{ id: string }> {
  classes: any,
  stores: Stores
}


let disposerSubscription: any;
let disposerRegion: any;
@inject("stores")
@observer
class ConsumerMyApis extends Component<IProps> {
  public componentDidMount() {
    const { stores } = this.props
    disposerSubscription = intercept(stores.headerContextStore, "selectedSubscription", (change: any) => {
      console.log("selectedSubscription.intercept", change)
      this.loadAPIs(change.newValue);
      return change
    })
    
    disposerRegion = intercept(stores.headerContextStore, "selectedRegion", (change: any) => {
      console.log("selectedRegion.intercept")
      this.loadAPIs(undefined, change.newValue);
      return change
    })
   
    console.log("componentDidMount")

    this.loadAPIs()
  }

  public componentWillUnmount() {
    console.log("componentWillUnmount")
    disposerSubscription()
    disposerRegion()
  }

  public state = {
    filteredList: [] as IAPIList[]
  }

  public loadAPIs = async (newSubscription?: string, newRegion?: string) => {
    const { stores } = this.props;
    stores.consumerMyApisStore.setLoading(true);

    const { selectedSubscription, selectedRegion} = stores.headerContextStore

    const useCase = new APIListUseCase();
    const result = await useCase.execute(
      newSubscription || selectedSubscription, 
      newRegion || selectedRegion)

    if (result.ok) {
      stores.consumerMyApisStore.setAPIList(result.data as IAPIList[]);
      this.setState({ filteredList: result.data });
      const appId = this.props.match.params.id;
      const useCase = new ApplicationDetailsUseCase();
      const resultAppDetails = await useCase.execute(appId);

      stores.consumerMyApisStore.setSuccess(result.ok);

      if (resultAppDetails.ok) {
        stores.consumerMyApisStore.setApplicationDetails(resultAppDetails.data as IApplicationDetails);
      }
    } else {
      stores.consumerMyApisStore.setSuccess(false);
    }

    stores.consumerMyApisStore.setLoading(false);
  }

  private onRegisterMethods = () => {
    console.log("onRegisterMethods")
    this.loadAPIs();
  }

  private handleChangeSearchKey = (event: any) => {
    const { stores } = this.props;
    const search = event.target.value;
    const apiList = stores.consumerMyApisStore.apiList;

    this.setState({
      filteredList: apiList.filter(api => api.name.toLowerCase().indexOf(search.toLowerCase()) > -1) 
    })
  }

  public render() {
    const { classes, stores } = this.props;
    const { filteredList } = this.state;

    if (stores.consumerMyApisStore.isLoading) {
      return <Loading />
    }

    if (!stores.consumerMyApisStore.success) {
      return (
        <div className={classes.container}>
          <Link
            className={classes.linkBack}
            component={RouterLink}
            to={EPaths.ROOT}>
            &lt;&nbsp;<FormattedMessage id="backbutton" />
          </Link>
          <Error />
        </div>
      )
    }

    return (
      <div className={classes.container}>
        <Link
          className={classes.linkBack}
          component={RouterLink}
          to={EPaths.ROOT}>
          &lt;&nbsp;<FormattedMessage id="backbutton" />
        </Link>
        <div className={classes.appName}>
          {stores.consumerMyApisStore.appDetails && 
          stores.consumerMyApisStore.appDetails.application && 
          toJS(stores.consumerMyApisStore.appDetails.application.name)}
        </div>
        <SearchApiList
          handleChangeSearchKey={this.handleChangeSearchKey}
          searchtype={stores.providerHomeStore.searchtype}
        ></SearchApiList>
        <div>
          {
            toJS(filteredList.map(api => (
              <RequestAppItem
                key={api.id}
                api={api}
                apiContext={api.context}
                appDetails={toJS(stores.consumerMyApisStore.appDetails)}
                rootId={this.props.match.params.id}
                onRegisterMethod={this.onRegisterMethods} />
            )))
          }
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(ConsumerMyApis);