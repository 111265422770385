import {observable, action} from 'mobx';
import { persist } from "mobx-persist";

class HeaderContextStore {

  @persist @observable
  public selectedSubscription = "";

  @persist @observable
  public selectedRegion = "";

  @action
  public setSelectedSubscription = (selectedSubscription: string) => {
    this.selectedSubscription = selectedSubscription;
  }
  
  @action
  public setSelectedRegion = (selectedRegion: string) => {
    this.selectedRegion = selectedRegion;
  }
}

export default HeaderContextStore
