import React from 'react';
import {
  createStyles,
  withStyles,
  Theme,
  Box,
  Typography,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import LockButton from '../lockbutton/LockButton';
import LazyExpansionPanel from '../lazyexpansionpanel/LazyExpansionPanel';
import { IConsumer } from '../../../../../core/lib/entities/IConsumer';
import ConsumerBlocksPanel from '../consumerblockspanel/ConsumerBlocksPanel';
import { IProviderAPIList } from '../../../../../core/lib/entities/IProviderAPIList';
import BlockConsumerUseCase from '../../../../../core/lib/useCases/BlockConsumerUseCase';

const styles = (theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    headerButton: {
      marginRight: '1rem',
    },
  });

interface IProps {
  classes?: any;
  apis: IProviderAPIList[];
  consumer: IConsumer;
  onLockConsumer: (consumer: IConsumer, locked: boolean) => Promise<any>;
}

class ConsumerBlocks extends React.PureComponent<IProps> {
  private blockConsumerUseCase = new BlockConsumerUseCase();

  public onLockConsumer = async (event: any, locked: boolean) => {
    // prevent clicks on the lock button to open/close the expandable panel
    event.stopPropagation();

    return this.props.onLockConsumer(this.props.consumer, locked);
  };

  public render() {
    const { consumer, apis, classes } = this.props;

    return (
      <LazyExpansionPanel
        summary={
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Box className={classes.header}>
              <Box className={classes.headerButton}>
                <LockButton locked={!consumer.isEnabled} onChange={this.onLockConsumer} />
              </Box>
              <Typography variant="h3">{consumer.name}</Typography>
            </Box>
          </ExpansionPanelSummary>
        }
        details={
          <ExpansionPanelDetails>
            <ConsumerBlocksPanel
              consumerId={consumer.consumerId}
              apis={apis}
              disabled={!consumer.isEnabled}
            />
          </ExpansionPanelDetails>
        }
      />
    );
  }
}

export default withStyles(styles)(ConsumerBlocks);
