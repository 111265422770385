import React, {Component} from 'react';
import { createStyles, Theme, withStyles, Dialog } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { IUsagePlan } from '../../../../../core/lib/entities/IApplicationDetails';
import APITooltipDetails from '../apitooltip/APITooltipDetails';
import CostTransform from '../costtransform/CostTransform';
import uuid from 'uuid';

interface IProps {
  isOpen: boolean,
  handleClose: () => void,
  classes?: any,
  method: any,
  plan: any,
  endpoint: string[],
  intl: any
}

const styles = (theme: Theme) =>
  createStyles({
    dialogTitle: {
      height: "44px",
      backgroundColor: "#222e3e",
      textAlign: "center",
      color: "#ffffff",
      alignItems: "center",
      justifyContent: "center",
      display: "flex"
    },
    dialogContainer: {
      width: "100%",
      backgroundColor: "#d8d8d8"
    },
    labelTop: {
      display: "flex",
      fontSize: "18px",
      color: "#ffffff",
      width: "100%",
      justifyContent: "center"
    },
    content: {
      height: "160px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "16px",
      fontWeight: "bold"
    },
    buttonDef: {
      borderRadius: "5px",
      margin: "8px",
      border: "solid 1px #ad8d40",
      boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.75)",
      height: "60%",
      fontSize: "12px",
      width: "163px",
      textTransform: "capitalize",
      backgroundImage: "linear-gradient(to bottom, #ffffff, #f7dea0 9%, #f1c24f)",
      "&:hover": {
        boxShadow: "1px 1px 3px #ccc"
      }
    },
    footerActions: {
      display: "flex",
      justifyContent: "center"
    },
    contentcontainer: {
      width: "550px",
      minHeight: "130px",
      backgroundColor: "#fff",
      boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.5)",
      marginTop: "10px",
      borderRadius: "8px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column"
    },
    httpmethod: {
      width: "50px",
      height: "40px",
      backgroundColor: "red",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      color: "#fff",
      fontWeight: "bold",
      fontSize: "12px",
      borderRadius: "4px",
      marginLeft: "4px"
    },
    post: {
      backgroundColor: "#49CC90"
    },
    get: {
      backgroundColor: "#62AFFE"
    },
    delete: {
      backgroundColor: "#FF3333"
    },
    put: {
      backgroundColor: "#FCA12F"
    },
    methodbox: {
      width: "95%",
      borderRadius: "4px",
      marginTop: "10px",
      marginBottom: "10px",
      height: "48px",
      display: "flex",
      alignItems: "center"
    },
    methodboxpost: {
      backgroundColor: "#EEF9F4",
      border: "solid 1px #49CC90"
    },
    methodboxget: {
      backgroundColor: "#F0F7FF",
      border: "solid 1px #62AFFE"
    },
    methodboxput: {
      backgroundColor: "#F0F7FF",
      border: "solid 1px #FCA12F"
    },
    methodboxdelete: {
      backgroundColor: "##ff9999",
      border: "solid 1px #FF3333"
    },
    operationname: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: "10px",
      width: "90%"
    },
    operationtitle: {
      fontSize: "14px",
      fontWeight: "bold",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    operationprice: {
      fontSize: "11px",
      float: "left"
    },
    endpointdata: {
      fontSize: "12px",
      fontWeight: "normal"
    },
    boxendpoint: {
      width: "95%",
      marginTop: "10px",
      marginBottom: "10px"
    },
    costdetails: {
      fontSize: "13px",
      textTransform: "uppercase",
      float: "right"
    }
  });

class APIDetailsModal extends Component<IProps> {
  public getMethodColor = (httpmethod: string) => {
    const {classes} = this.props;

    switch (httpmethod) {
      case "POST":
        return classes.post;
      case "GET":
        return classes.get;
      case "DELETE":
        return classes.delete;
      case "PUT":
        return classes.put;
    }
  }

  public getMethodBoxColor = (httpmethod: string) => {
    const {classes} = this.props;

    switch (httpmethod) {
      case "POST":
        return classes.methodboxpost;
      case "GET":
        return classes.methodboxget;
      case "DELETE":
        return classes.methodboxdelete;
      case "PUT":
        return classes.methodboxput;
    }
  }

  private renderEndPoints = (endpoints: string[], path: string) => {
    return (
      endpoints.map((endpoint) => (
        <div key={uuid()}>{endpoint}{path}</div>
      ))
    );
  }

  public render() {
    const {classes, isOpen, handleClose, method, endpoint, plan} = this.props;

    return (
      <Dialog classes={{paper: classes.dialogContainer}} open={isOpen} fullWidth={true}>
          <DialogTitle className={classes.dialogTitle}>
          <div className={classes.labelTop}>
            <FormattedMessage id="apidata.methoddetails" />
          </div>
        </DialogTitle>

        <DialogContent className={classes.content}>
          <div>
            <div className={classes.contentcontainer}>
              <div className={classes.methodbox + " " + this.getMethodBoxColor(method.httpMethod)}>
                  <div className={classes.httpmethod + " " +  this.getMethodColor(method.httpMethod)}>
                      {method.httpMethod}
                  </div>
                  <div className={classes.operationname}>
                    <div className={classes.operationtitle}>
                      {(method.operationName == null) ? <FormattedMessage id="apidata.undefined" /> :  method.operationName}
                    </div>
                    <div>
                      <div className={classes.operationprice}>
                      <CostTransform
                        method={method}
                        plan={plan} />
                      </div>
                      <div className={classes.costdetails}>
                        {plan && (plan as IUsagePlan).name}
                        <APITooltipDetails plan={plan}/>
                      </div>
                    </div>
                  </div>
              </div>

              <div className={classes.boxendpoint}>
                <div>Endpoint</div>
                <div className={classes.endpointdata}>
                  {this.renderEndPoints(endpoint, method.path)}
                </div>
              </div>
            </div>
          </div>
        </DialogContent>

        <DialogActions className={classes.footerActions}>
          <Button
            className={classes.buttonDef}
            onClick={handleClose}>
            Ok
          </Button>
        </DialogActions>

      </Dialog>
    );
  }

}

export default withStyles(styles)(injectIntl(APIDetailsModal));
