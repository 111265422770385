import Api from '../frameworks/api/Api';

class DownloadDocService {
  private Api = new Api();

  public download = (apiId: string, stage: string, type: string, extensions: string, format: string) => {
    return this.Api.get(`api/${apiId}/stage/${stage}/export?type=${type}&extensions=${extensions}&format=${format}`);
  }
}

export default DownloadDocService;