import ApplicationDetailsService from '../services/ApplicationDetailsService';

class ApplicationDetailsUseCase {
  public execute = async(appId: string) => {
    const appDetails = new ApplicationDetailsService();
    const result = await appDetails.getApplicationDetails(appId);
    return result;
  }
}

export default ApplicationDetailsUseCase;