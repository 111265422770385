import Api from '../frameworks/api/Api';

class AppCredentialsService {
  private Api = new Api();

  public getCredentials = (applicationId: string) => {
    return this.Api.get(`application/${applicationId}/credential`)
  }
}

export default AppCredentialsService;