import Api from '../frameworks/api/Api';

class AppResetApiKeyService {
  private Api = new Api();

  public reset = (applicationId: string) => {
    return this.Api.get(`application/${applicationId}/credential/reset/apikey`);
  }
}

export default AppResetApiKeyService;