import Api from '../frameworks/api/Api';

class SDKTypesService {
  private Api = new Api();

  public loadSDKTypes = () => {
    return this.Api.get("sdk/type")
  }

}

export default SDKTypesService
