import Api from '../frameworks/api/Api';

class SubscriptionService {
  private Api = new Api();

  public getSubscriptions = (query?: any) => {
    return this.Api.get("api/subs", query)
  }
}

export default SubscriptionService;