import ApplicationsService from '../services/ApplicationsService';

class ApplicationsListUseCase {
  public execute = async() => {
    const appList = new ApplicationsService();
    const result = await appList.getApplicationList();
    return result;
  }
}

export default ApplicationsListUseCase;