import { createStyles, Theme, withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import React, { Component } from 'react';
import { ReactComponent as InfoIcon } from '../../../assets/svgs/ic-info.svg';

const styles = (theme: Theme) =>
  createStyles({
    htmlTooltip: {
      backgroundColor: "#000",
      color: "rgba(0, 0, 0, 0.87)",
      minWidth: 220,
      maxWidth: 220
    },
    arrow: {
      position: "absolute",
      fontSize: 6,
      width: "3em",
      height: "3em",
      "&::before": {
        content: '""',
        margin: "auto",
        display: "block",
        width: 0,
        height: 0,
        borderStyle: "solid"
      }
    },
    htmlPopper: {
      '&[x-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: "-0.95em",
        width: "3em",
        height: "1em",
        "&::before": {
          borderWidth: "1em 1em 0 1em",
          borderColor: `#000 transparent transparent transparent`
        }
      }
    },
    infoIcon: {
      margin: "0 5px"
    }
  });

interface IProps {
  classes?: any;
  children?: any;
}
interface IState {
  arrowRef: any;
}

class APITooltip extends Component<IProps, IState> {
  public state = {
    arrowRef: null
  };

  public handleArrowRef = (node: any) => {
    this.setState({ arrowRef: node });
  };

  public render() {
    const { classes, children } = this.props;

    return (
      <Tooltip
        placement="top"
        classes={{
          popper: classes.htmlPopper,
          tooltip: classes.htmlTooltip
        }}
        PopperProps={{
          popperOptions: {
            modifiers: {
              arrow: {
                enabled: Boolean(this.state.arrowRef),
                element: this.state.arrowRef
              }
            }
          }
        }}
        title={
          <>
            {children}
            <span className={classes.arrow} ref={this.handleArrowRef} />
          </>
        }
      >
        <InfoIcon className={classes.infoIcon} />
      </Tooltip>
    );
  }
}

export default withStyles(styles)(APITooltip);
