import AppResetPasswordService from '../services/AppResetPasswordService';

class AppResetPasswordUseCase {
  public execute = async (applicationId: string) => {
    const appResetPass = new AppResetPasswordService();
    const result = await appResetPass.reset(applicationId);
    return result;
  }
}

export default AppResetPasswordUseCase;