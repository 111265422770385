import React, {Component} from 'react';
import {IMethod, IUsagePlan} from '../../../../../core/lib/entities/IApplicationDetails';
import { createStyles, Theme, withStyles, Checkbox, FormControlLabel } from '@material-ui/core';
import CostTransform from '../costtransform/CostTransform';
import { FormattedMessage, injectIntl } from 'react-intl';
import APITooltipDetails from '../apitooltip/APITooltipDetails';

interface IProps {
  method: IMethod,
  plan: IUsagePlan,
  disabled: boolean,
  handleChange: (id: string, method: IMethod, plan: IUsagePlan, checked: boolean) => void,
  id: string,
  checked: boolean,
  classes: any,
  intl: any
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      marginTop: "1rem"
    },
    httpmethod: {
      width: "60px",
      height: "54px",
      backgroundColor: "red",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      color: "#fff",
      fontWeight: "bold",
      fontSize: "14px",
      borderRadius: "4px",
      marginLeft: "4px"
    },
    post: {
      backgroundColor: "#49CC90"
    },
    get: {
      backgroundColor: "#62AFFE"
    },
    delete: {
      backgroundColor: "#FF3333"
    },
    put: {
      backgroundColor: "#FCA12F"
    },
    methodbox: {
      width: "100%",
      borderRadius: "4px",
      marginBottom: "10px",
      height: "64px",
      display: "flex",
      alignItems: "center"
    },
    methodboxpost: {
      backgroundColor: "#EEF9F4",
      border: "solid 1px #49CC90"
    },
    methodboxget: {
      backgroundColor: "#F0F7FF",
      border: "solid 1px #62AFFE"
    },
    methodboxput: {
      backgroundColor: "#F0F7FF",
      border: "solid 1px #FCA12F"
    },
    methodboxdelete: {
      backgroundColor: "##ff9999",
      border: "solid 1px #FF3333"
    },
    operationname: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: "10px"
    },
    operationtitle: {
      fontSize: "14px",
      fontWeight: "bold"
    },
    operationprice: {
      fontSize: "11px",
      display: "flex",
      flexDirection: "row"
    },
    operationdetails: {
      fontSize: "12px",
      display: "flex",
      flexDirection: "row"
    },
    operationpath: {
      fontWeight: "bold",
      marginRight: "4px"
    },
    tooltip: {
      marginLeft: "6px"
    },
    planselection: {
      marginLeft: "auto",
      marginRight: "10px",
      display: "flex",
      flexDirection: "row"
    },
    planchoose: {
      fontWeight: "bold",
      fontSize: "16px",
      marginRight: "20px",
      display: "flex",
      alignItems: "center"
    },
    planselector: {
      marginLeft: "10px",
      fontWeight: "normal",
      fontSize: "14px"
    },
    chk: {
      fontSize: "15px"
    }
  });

class RequestAPIMethod extends Component<IProps> {
  public getMethodColor = (httpmethod: string) => {
    const {classes} = this.props;

    switch (httpmethod) {
      case "POST":
        return classes.post;
      case "GET":
        return classes.get;
      case "DELETE":
        return classes.delete;
      case "PUT":
        return classes.put;
    }
  }

  public getMethodBoxColor = (httpmethod: string) => {
    const {classes} = this.props;

    switch (httpmethod) {
      case "POST":
        return classes.methodboxpost;
      case "GET":
        return classes.methodboxget;
      case "DELETE":
        return classes.methodboxdelete;
      case "PUT":
        return classes.methodboxput;
    }
  }

  public changeCheckPlan = (event: any) => {
    const {handleChange, method, id, disabled, plan} = this.props;
    if (!disabled) {
      handleChange(id, method, plan, event.target.checked);
    }
  }

  private isChecked = () => {
    const {checked, disabled} = this.props;

    if (!disabled) {
      if ((checked !== null) && (checked !== undefined)) {
        return checked;
      } else {
        return false;
      }
    } else {
      return disabled;
    }
  }

  public render() {
    const {method, classes, plan, disabled, intl} = this.props;

    return (
      <div key={method.operationName}
          className={classes.methodbox + " " + this.getMethodBoxColor(method.httpMethod)}>
            <div className={classes.httpmethod + " " +  this.getMethodColor(method.httpMethod)}>
                {method.httpMethod}
            </div>
            <div className={classes.operationname}>
              <div className={classes.operationtitle}>
                {(method.operationName == null) ? <FormattedMessage id="apidata.undefined" /> :  method.operationName}
              </div>
              <div className={classes.operationdetails}>
                <div className={classes.operationpath}>
                  {(method.httpMethod == null) ? <FormattedMessage id="apidata.undefined" /> : method.httpMethod}
                </div>
                <div>
                  {method.path} {(method.properties === null ? "" : method.properties.summary === null ? "" : " - " + method.properties.summary)}
                </div>
              </div>
              <div className={classes.operationprice}>
                <CostTransform
                  method={method}
                  plan={plan} />
                <div className={classes.tooltip} >
                  <APITooltipDetails
                    plan={plan} />
                </div>
              </div>
            </div>
            <div className={classes.planselection}>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      className={classes.chk}
                      disabled={disabled}
                      onChange={this.changeCheckPlan}
                      checked={this.isChecked()} />
                  }
                  label={intl.formatMessage({ id: "method.subscribe" })} />
              </div>
            </div>
        </div>
    );
  }
}

export default withStyles(styles)(injectIntl((RequestAPIMethod)));
