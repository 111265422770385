import { observable, action } from 'mobx';

import IApplication from '../entities/IApplication';

class ConsumerBlockDetailsStore {
  @observable
  public loading = false;

  @observable
  public error: any = null;

  @observable
  public applications: IApplication[] = [];

  @action
  public loadingStart() {
    this.loading = true;
    this.error = null;
  }

  @action
  public loadingSuccess(applications: IApplication[]) {
    this.loading = false;
    this.error = null;
    this.applications = applications;
  }

  @action
  public loadingError(error?: any) {
    this.loading = false;
    this.error = error || true;
  }

  @action
  public updateSuccess(applications: IApplication[]) {
    this.applications = applications;
  }

  @action
  public updateError(error?: any) {
    this.loading = false;
    this.error = error || true;
  }
}

export default ConsumerBlockDetailsStore;
