import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { createStyles, Theme, withStyles, Dialog } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CostTransform from '../costtransform/CostTransform';
import { FormattedMessage } from 'react-intl';
import APITooltipDetails from '../apitooltip/APITooltipDetails';
import { IMethod, IUsagePlan } from '../../../../../core/lib/entities/IApplicationDetails';
import classNames from 'classnames';
import RegisterAPIUseCase from '../../../../../core/lib/useCases/RegisterAPIUseCase';
import { IAPIStage } from '../../../../../core/lib/entities/IAPIDetails';

const styles = (theme: Theme) =>
  createStyles({
    dialogTitle: {
      height: "44px",
      backgroundColor: "#222e3e",
      textAlign: "center",
      color: "#ffffff",
      alignItems: "center",
      justifyContent: "center",
      display: "flex"
    },
    dialogContainer: {
      width: "100%",
      backgroundColor: "#d8d8d8"
    },
    labelTop: {
      display: "flex",
      fontSize: "18px",
      color: "#ffffff",
      width: "100%",
      justifyContent: "center"
    },
    content: {
      height: "420px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "16px",
      fontWeight: "bold"
    },
    buttonDef: {
      borderRadius: "5px",
      margin: "8px",
      border: "solid 1px #ad8d40",
      boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.75)",
      height: "60%",
      fontSize: "12px",
      width: "163px",
      textTransform: "capitalize",
      backgroundImage: "linear-gradient(to bottom, #ffffff, #f7dea0 9%, #f1c24f)",
      "&:hover": {
        boxShadow: "1px 1px 3px #ccc"
      },
      "&.-cancel": {
        backgroundColor: "#c4c5c6",
        border: "solid 1px #adb1b8",
        backgroundImage: "linear-gradient(to bottom, #fcfcfd, #f5f6f8 9%, #c4c5c6)"
      }
    },
    footerActions: {
      display: "flex",
      justifyContent: "center"
    },
    containercontent: {
      width: "100%",
      minHeight: "380px",
      backgroundColor: "#fff",
      boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.5)",
      marginTop: "10px",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column"
    },
    title: {
      marginLeft: "10px",
      marginTop: "10px",
      fontSize: "22px",
      fontWeight: "normal",
      height: "40px",
      display: "flex",
    },
    methoditems: {
      marginLeft: "10px",
      marginRight: "10px",
      height: "250px",
      marginTop: "10px",
      overflowY: "scroll"
    },
    footerdetails: {
      height: "40px",
      marginLeft: "10px",
      marginRight: "10px",
      backgroundColor: "#000",
      marginTop: "10px",
      color: "#fff",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      borderRadius: "4px",
      fontWeight: "normal",
      fontSize: "14px"
    },
    httpmethod: {
      minWidth: "60px",
      height: "54px",
      backgroundColor: "red",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      color: "#fff",
      fontWeight: "bold",
      fontSize: "14px",
      borderRadius: "4px",
      marginLeft: "4px"
    },
    post: {
      backgroundColor: "#49CC90"
    },
    get: {
      backgroundColor: "#62AFFE"
    },
    delete: {
      backgroundColor: "#FF3333"
    },
    put: {
      backgroundColor: "#FCA12F"
    },
    methodbox: {
      width: "100%",
      borderRadius: "4px",
      marginBottom: "10px",
      height: "64px",
      display: "flex",
      alignItems: "center"
    },
    methodboxpost: {
      backgroundColor: "#EEF9F4",
      border: "solid 1px #49CC90"
    },
    methodboxget: {
      backgroundColor: "#F0F7FF",
      border: "solid 1px #62AFFE"
    },
    methodboxput: {
      backgroundColor: "#F0F7FF",
      border: "solid 1px #FCA12F"
    },
    methodboxdelete: {
      backgroundColor: "##ff9999",
      border: "solid 1px #FF3333"
    },
    operationname: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: "10px"
    },
    operationtitle: {
      fontSize: "14px",
      fontWeight: "bold"
    },
    operationprice: {
      fontSize: "11px",
      display: "flex",
      flexDirection: "row"
    },
    operationdetails: {
      fontSize: "12px",
      display: "flex",
      flexDirection: "row",
      fontWeight: "normal",
      whiteSpace: "nowrap"
    },
    operationpath: {
      fontWeight: "bold",
      marginRight: "4px"
    },
    operationstage: {
      fontWeight: "bold",
      marginRight: "4px",
      marginLeft: "10px"
    },
    tooltip: {
      marginLeft: "6px"
    }
  });

interface IProps {
  appId: string,
  apis: Map<string, any>,
  isOpen: boolean,
  handleClose: () => void,
  classes?: any,
  rootId: string,
  onRegisterMethod: () => void,
  stores?: any,
  apiContext: string,
}

@inject("stores")
@observer
class RevisionModal extends Component<IProps> {
  public state = { disableBtns: false };

  public getMethodColor = (httpmethod: string) => {
    const { classes } = this.props;

    switch (httpmethod) {
      case "POST":
        return classes.post;
      case "GET":
        return classes.get;
      case "DELETE":
        return classes.delete;
      case "PUT":
        return classes.put;
    }
  }

  public getMethodBoxColor = (httpmethod: string) => {
    const { classes } = this.props;

    switch (httpmethod) {
      case "POST":
        return classes.methodboxpost;
      case "GET":
        return classes.methodboxget;
      case "DELETE":
        return classes.methodboxdelete;
      case "PUT":
        return classes.methodboxput;
    }
  }

  private registerAPIs = async () => {
    const { appId, rootId, stores, apiContext } = this.props;

    const apis = Array.from(this.props.apis.values());

    const stages = apis.reduce((result, api) => {
      const method = api.method as IMethod;
      const plan = api.plan as IUsagePlan;
      const stage = api.stage as IAPIStage;

      // ensure that the array exists for the desired key (stageName)
      if (!result[stage.stageName]) {
        result[stage.stageName] = {
          stageName: stage.stageName,
          usagePlanId: plan.id,
          methods: []
        };
      }

      // push the method to the array of methods for the stage
      result[stage.stageName].methods.push({
        resourceId: method.resourceId,
        httpMethod: method.httpMethod
      });

      return result;
    }, {});



    let payload = {
      accesses: [{
        apiId: appId, stages: Array.from(Object.values(stages))
      }],
      context: apiContext
    };

    this.setState({ disableBtns: true });

    const { selectedSubscription, selectedRegion } = stores.headerContextStore

    const useCase = new RegisterAPIUseCase();
    await useCase.execute(rootId, payload, selectedSubscription, selectedRegion);
    this.setState({ disableBtns: false });
    this.props.onRegisterMethod();
  }

  private renderMethods = () => {
    const { apis, classes } = this.props;

    if(!apis)
      return []
    return Array.from(apis.values()).map(api => {
      let method = api.method as IMethod;
      let plan = api.plan as IUsagePlan;
      let stage = api.stage as IAPIStage;

      return (
        <div key={api.id}>
          <div key={method.operationName}
            className={classes.methodbox + " " + this.getMethodBoxColor(method.httpMethod)}>
            <div className={classes.httpmethod + " " + this.getMethodColor(method.httpMethod)}>
              {method.httpMethod}
            </div>
            <div className={classes.operationname}>
              <div className={classes.operationtitle}>
                {(method.operationName == null) ? <FormattedMessage id="apidata.undefined" /> : method.operationName}
              </div>
              <div className={classes.operationdetails}>
                <div className={classes.operationpath}>
                  <FormattedMessage id="method.path" />
                </div>
                <div>
                  {method.path} - ({method.properties.summary})
                </div>
                <div className={classes.operationstage}>
                  <FormattedMessage id="apidata.stage" />
                </div>
                <div>
                  {stage.stageName}
                </div>
              </div>
              <div className={classes.operationprice}>
                <CostTransform
                  method={method}
                  plan={plan} />
                <div className={classes.tooltip} >
                  <APITooltipDetails
                    plan={plan} />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }
  public render() {
    const { classes, isOpen, handleClose } = this.props;

    return (
      <Dialog classes={{ paper: classes.dialogContainer }} open={isOpen} fullWidth={true} maxWidth='md'>
        <DialogTitle className={classes.dialogTitle}>
          <div className={classes.labelTop}>
            <FormattedMessage id="api.revisiontitle" />
          </div>
        </DialogTitle>

        <DialogContent className={classes.content}>
          <div className={classes.containercontent}>
            <div className={classes.title}>
              <FormattedMessage id="api.subcribedmethod" />
            </div>
            <div className={classes.methoditems}>
              {
                this.renderMethods()
              }
            </div>
            <div className={classes.footerdetails}>
              <FormattedMessage id="api.submission" />
            </div>
          </div>
        </DialogContent>

        <DialogActions className={classes.footerActions}>
          <Button
            onClick={handleClose}
            className={classNames(classes.buttonDef, "-cancel")}
            disabled={this.state.disableBtns}>
            <FormattedMessage id="api.btncancel" />
          </Button>

          <Button
            className={classes.buttonDef}
            onClick={this.registerAPIs}
            disabled={this.state.disableBtns}>
            <FormattedMessage id="api.btnconfirm" />
          </Button>
        </DialogActions>

      </Dialog>
    );
  }
}

export default withStyles(styles)(RevisionModal);
