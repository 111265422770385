import ApplicationRegisterService from '../services/ApplicationRegisterService';

class ApplicationRegisterUseCase {
  public execute = async (appName: string) => {
    const appRegister = new ApplicationRegisterService();
    const result = await appRegister.registerApp(appName);
    return result;
  }
}

export default ApplicationRegisterUseCase