import { create } from 'apisauce';
import { API_BASE, APP_BASE, EAppBase, costumerXApiKey, providerXApiKey } from '../../settings/Constants';
import Amplify from 'aws-amplify';

class Api {
  private createHeaders = async () => {
    const { idToken } = await Amplify.Auth.currentSession();

    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken.jwtToken}`,
      "x-api-key": APP_BASE === EAppBase.CONSUMER ? costumerXApiKey : providerXApiKey
    };
  }

  private api = async (headers?: any) => {
    const defaultHeaders = await this.createHeaders()
    return create({
      baseURL: API_BASE,
      headers: { ...defaultHeaders, ...headers }
    })
  }

  public get = async (URL: string, query?: any, headers?: any) => {
    const api = await this.api(headers);
    return api.get(URL, query);
  }

  public post = async (URL: string, body?: any, query?: any, headers?: any) => {
    const api = await this.api(headers);
    return api.post(URL, body, { params: query });
  }

  public put = async (URL: string) => {
    const api = await this.api();
    return api.put(URL);
  }

  public delete = async (URL: string) => {
    const api = await this.api();
    return api.delete(URL);
  }
}

export default Api;