import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import {
  createStyles,
  Theme,
  withStyles,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';

import Error from '../../general/error/Error';
import Loading from '../../general/loading/Loading';
import MethodLabel from '../../ui/methodlabel/MethodLabel';
import APIBlockDetailsStore from '../../../../../core/lib/stores/APIBlockDetailsStore';
import APIDetailsUseCase from '../../../../../core/lib/useCases/APIDetailsUseCase';
import { IAPIDetails, IAPIStage } from '../../../../../core/lib/entities/IAPIDetails';
import { IMethod } from '../../../../../core/lib/entities/IApplicationDetails';
import LockButton from '../lockbutton/LockButton';
import BlockApiMethodUseCase from '../../../../../core/lib/useCases/BlockApiMethodUseCase';
import SimpleTabs from '../simpletabs/SimpleTabs';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
    },
    list: {
      marginLeft: -24,
      marginRight: -24,
    },
    listItemRoot: {
      marginBottom: 1,
    },
    listItemContainer: {
      '&:nth-child(odd)': {
        backgroundColor: '#efefef',
      },
    },
    listItemText: {
      marginLeft: '0.5rem',
    },
  });

interface IProps {
  classes?: any;
  apiId: string;
  stores?: any;
  apiContext: string;
}

@inject("stores")
@observer
class APIBlocksPanel extends Component<IProps> {
  private store = new APIBlockDetailsStore();
  private apiDetailsUseCase = new APIDetailsUseCase();
  private blockApiMethodUseCase = new BlockApiMethodUseCase();

  public componentDidMount() {
    this.loadAPIDetails();
  }

  public async loadAPIDetails() {
    const {apiId, stores, apiContext} = this.props;
    const { selectedSubscription, selectedRegion} = stores.headerContextStore

    this.store.loadingStart();

    const result = await this.apiDetailsUseCase.execute(apiId, selectedSubscription, selectedRegion, apiContext);
    if (result.ok) {
      this.store.loadingSuccess(result.data as IAPIDetails);
    } else {
      this.store.loadingError(result);
    }
  }

  public handleLockChange = async (stage: IAPIStage, method: IMethod, locked: boolean) => {
    const { apiId, stores, apiContext } = this.props;
    const { selectedSubscription, selectedRegion} = stores.headerContextStore

    await this.blockApiMethodUseCase.execute(
      apiId,
      stage.stageName,
      method.httpMethod,
      method.resourceId,
      locked,
      selectedSubscription, 
      selectedRegion, 
      apiContext
    );

    const result = await this.apiDetailsUseCase.execute(apiId, selectedSubscription, selectedRegion, apiContext);
    if (result.ok) {
      this.store.loadingSuccess(result.data as IAPIDetails);
    } else {
      this.store.loadingError(result);
    }
  };

  public render() {
    const { classes } = this.props;

    if (this.store.loading) {
      return <Loading />;
    }

    if (this.store.error) {
      return <Error />;
    }

    const stages = this.store.api && this.store.api.stages;

    if (!stages) {
      return null;
    }

    return (
      <Box className={classes.container}>
        <SimpleTabs
          tabs={stages.map(stage => ({
            key: stage.stageName,
            title: stage.stageName,
            content: (
              <List className={classes.list}>
                {stage.methods &&
                  stage.methods.map(method => (
                    <ListItem
                      key={`${method.httpMethod}${method.resourceId}`}
                      disableGutters
                      classes={{
                        root: classes.listItemRoot,
                        container: classes.listItemContainer,
                      }}
                    >
                      <ListItemIcon>
                        <MethodLabel method={method.httpMethod} />
                      </ListItemIcon>
                      <ListItemText className={classes.listItemText} primary={method.path} />
                      <ListItemSecondaryAction>
                        <LockButton
                          apiContext={this.props.apiContext}
                          locked={!method.isEnabled}
                          onChange={(event, locked) => this.handleLockChange(stage, method, locked)}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
              </List>
            ),
          }))}
        />
      </Box>
    );
  }
}

export default withStyles(styles)(APIBlocksPanel);
