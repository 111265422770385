import { Button, createStyles, Theme } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { ReactComponent as SvgApiGateway } from '../../../assets/svgs/exporttype.svg';
import { ReactComponent as SvgPostman } from '../../../assets/svgs/postman.svg';
import { ReactComponent as SvgSwagger } from '../../../assets/svgs/swagger.svg';

interface IProps {
  classes?: any;
  icon: any;
  onClickButtonCard: any;
}

const styles = (theme: Theme) =>
  createStyles({
    card: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "none"
    },
    buttonSilver: {
      border: "1px solid #ADB1B8",
      textTransform: "capitalize",
      backgroundImage: "linear-gradient(to bottom, #fcfcfd, #f5f6f8 9%, #c4c5c6)"
    }
  });

class CardExportType extends Component<IProps> {
  public getIcon = (icon: any) => {
    if (icon === "swagger") {
      return <SvgSwagger />;
    }

    if (icon === "postman") {
      return <SvgPostman />;
    }

    if (icon === "apigateway") {
      return <SvgApiGateway />;
    }
  };

  public onClick = (format: string, extensions: string) => () => {
    this.props.onClickButtonCard(format, extensions);
  };

  public render() {
    const { classes, icon } = this.props;

    return (
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>{this.getIcon(icon)}</CardContent>

        <CardActions>
          <Button className={classes.buttonSilver} onClick={this.onClick("json", icon)}>
            JSON
          </Button>
          <Button className={classes.buttonSilver} onClick={this.onClick("yaml", icon)}>
            YAML
          </Button>
        </CardActions>
      </Card>
    );
  }
}
export default withStyles(styles)(CardExportType);