import RequestMethodService from '../services/RequestMethodService';

class RequestMethodUseCase {
  public execute = async(payload: {}) => {
    const service = new RequestMethodService()
    const result = await service.requestMethods(payload)
    return result
  }
}

export default RequestMethodUseCase;
