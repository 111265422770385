import {observable, action} from 'mobx';
import {IProviderAPIList} from '../entities/IProviderAPIList';

class ProviderHomeStore {

  @observable
  public isLoading = true;

  @action
  public setLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  }

  @observable
  public success = true;

  @action
  public setSuccess(success: boolean) {
    this.success = success;
  }

  @observable
  public searchkey = ""

  @action
  public setSearchKey = (searchKey: string) => {
    this.searchkey = searchKey
  }

  @observable
  public searchtype = "0"

  @action
  public setSearchType = (searchType: string) => {
    this.searchtype = searchType
  }

  @observable
  public apis = new Array<IProviderAPIList>();

  @action
  public setAPIList = (apiList: Array<IProviderAPIList>) => {
    this.apis = apiList
  }
}

export default ProviderHomeStore
