import Api from '../frameworks/api/Api';

class APIDetailsService {
  private Api = new Api();

  public getAPIDetails = (
    apiId: string,
    subscription: any,
    region: string,
    context: string,
  ) => {
    const query = { subscription, region, context }
    return this.Api.get(`api/${apiId}`, query);
  };

  public updateAPIAccess = (
    apiId: string,
    isEnabled: boolean,
    subscription: any,
    region: string,
    context: string,
  ) => {
    const query = { subscription, region }
    return this.Api.post(`api/${apiId}/access`, {
      isEnabled,
      context
    },
      query);
  };

  public updateAPIMethodAccess = (
    apiId: string,
    stageName: string,
    httpMethod: string,
    resourceId: string,
    isEnabled: boolean,
    subscription: string,
    region: string,
    context: string,
  ) => {
    return this.Api.post(`api/${apiId}/stage/${stageName}/${resourceId}/${httpMethod}/access`, {
      isEnabled,
      context
    }, {
      subscription,
      region
    });
  };
}

export default APIDetailsService;
