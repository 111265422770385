import RequestListService from "../services/RequestListService"

class RequestListUseCase {
  public execute = async(
    status: string, 
    subscription: string, 
    region: string
    ) => {
    const service = new RequestListService()
    const result = await service.loadRequests(status, subscription, region)
    return result
  }
}

export default RequestListUseCase;
