import React, {Component} from 'react';
import { Button, createStyles, InputBase, Theme, withStyles } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import SimpleModal from '../simplemodal/SimpleModal';
import IApplication from '../../../../../core/lib/entities/IApplication';
import ApplicationRegisterUseCase from '../../../../../core/lib/useCases/ApplicationRegisterUseCase';

interface IProps {
  classes: any,
  apps: Array<IApplication>,
  intl: any,
  updateList: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      marginTop: "1rem",
      minHeight: "70px",
      maxHeight: "70px",
      backgroundColor: "#fff",
      boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.5)",
      display: "flex",
      alignItems: "center",
      padding: 25
    },
    title: {
      fontSize: 14,
      fontWeight: "bold",
      paddingRight: "10px"
    },
    inputBase: {
      width: 250,
      border: "1px solid #ccc",
      padding: "0px 5px",
      borderRadius: 8,
      fontSize: theme.typography.body1.fontSize
    },
    button: {
      margin: "8px",
      border: "solid 1px #ad8d40",
      boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.75)",
      height: "60%",
      borderRadius: "5px",
      fontSize: "12px",
      width: "163px",
      textTransform: "capitalize",
      backgroundImage: "linear-gradient(to bottom, #ffffff, #f7dea0 9%, #f1c24f)",
      marginLeft: "auto",
      "&:hover": {
        boxShadow: "1px 1px 3px #ccc"
      }
    }
  });

class APIRegister extends Component<IProps> {
  public state = {
    appName: "",
    openWarning: false
  };

  public onChange = (event: any) => {
    if (event.target.value.length <= 30) {
      this.setState({ appName: event.target.value });
    }
  }

  public validateAppName = () => {
    const {apps} = this.props;
    const {appName} = this.state;

    let result = apps.find(item => item.name.toLowerCase().trim() === appName.toLowerCase().trim())

    if (result !== undefined) {
      this.setState({openWarning: true, appName: ""});
    } else {
      this.registerApp(appName);
    }
  }

  public registerApp = async (appName: string) => {
    const useCase = new ApplicationRegisterUseCase();
    const result = await useCase.execute(appName)
    const {updateList} = this.props;

    if (result.ok) {
      updateList();
    }
  }

  public closeModal = () => {
    this.setState({openWarning: false});
  }

  public render() {
    const {classes, intl} = this.props;
    const { appName, openWarning } = this.state;

    return (
      <div className={classes.container}>
        <div className={classes.title}>
          <FormattedMessage id="apiregister.title" />
        </div>

        <InputBase 
          placeholder={intl.formatMessage({ id: "apiregister.placeholder" })}
          className={classes.inputBase}
          onChange={this.onChange}
          value={this.state.appName} />

        <Button 
          className={classes.button}
          disabled={!appName}
          onClick={this.validateAppName}>
          <FormattedMessage id="apiregister.btntitle" />
        </Button>

        <SimpleModal 
          msg={intl.formatMessage({ id: "apiregister.modal.msg" })}
          title={intl.formatMessage({ id: "apiregister.modal.title" })}
          isOpen={openWarning}
          handleClose={this.closeModal} />
      </div>
    );
  }
}

export default withStyles(styles)(injectIntl(APIRegister));