import { observable } from 'mobx';
import { create } from 'mobx-persist';

import ApiItemStore from '../stores/ApiItemStore';
import ConsumerHomeStore from '../stores/ConsumerHomeStore';
import ConsumerMyApisStore from '../stores/ConsumerMyApisStore';
import HeaderContextStore from './HeaderContextStore';
import InternationalizationStore from './InternationalizationStore';
import IntlBarStore from './IntlBarStore';
import ProviderHomeStore from './ProviderHomeStore';
import ProviderManagerStore from './ProviderManagerStore';
import ProviderRequestsStore from './ProviderRequestsStore';
import ProviderBlocksStore from './ProviderBlocksStore';

const hydrate = create();
class Stores {
  constructor() {
    Promise.all([
      hydrate('subscriptionContext', this.headerContextStore),
    ])
    .then(() => {});
  }

  @observable
  public intlConfigStore = new InternationalizationStore(navigator.language);

  @observable
  public intlBarStore = new IntlBarStore(navigator.language);

  @observable
  public consumerHomeStore = new ConsumerHomeStore();
  
  @observable
  public apiItemStore = new ApiItemStore();

  @observable
  public consumerMyApisStore = new ConsumerMyApisStore();

  @observable
  public headerContextStore = new HeaderContextStore();

  @observable
  public providerHomeStore = new ProviderHomeStore();

  @observable
  public providerManagerStore = new ProviderManagerStore();

  @observable
  public providerRequestsStore = new ProviderRequestsStore();

  @observable
  public providerBlocksStore = new ProviderBlocksStore();
  
}

export default Stores;
