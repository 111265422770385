import Api from '../frameworks/api/Api';

class ApplicationsService {
  private Api = new Api();
  
  public getApplicationList = () => {
    return this.Api.get("application");
  }
}

export default ApplicationsService;