import {observable, action} from 'mobx';
import {IAPIDetails, IAPIStage} from '../entities/IAPIDetails';

class ProviderManagerStore {

  @observable
  public isLoading = true;

  @action
  public setLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  }

  @observable
  public success = true;

  @action
  public setSuccess(success: boolean) {
    this.success = success;
  }

  @observable
  public apiDetails = {} as IAPIDetails

  @action
  public setAPIDetails = (apiDetails: IAPIDetails) => {
    this.apiDetails = apiDetails

    if ((this.apiDetails.stages) && (this.apiDetails.stages.length > 0)) {
      this.setSelectedStage(this.apiDetails.stages[0])
    }
  }

  @observable
  public selectedStage = {} as IAPIStage

  @action
  public setSelectedStage = (stage: IAPIStage) => {
    this.selectedStage = stage
  }
}

export default ProviderManagerStore;
