import { observable, action } from 'mobx';

import { IAPIDetails } from '../entities/IAPIDetails';

class APIBlockDetailsStore {
  @observable
  public loading = false;

  @observable
  public error: any = null;

  @observable
  public api?: IAPIDetails = undefined;

  @action
  public loadingStart() {
    this.loading = true;
    this.error = null;
  }

  @action
  public loadingSuccess(api: IAPIDetails) {
    this.loading = false;
    this.error = null;
    this.api = api;
  }

  @action
  public loadingError(error?: any) {
    this.loading = false;
    this.error = error || true;
  }
}

export default APIBlockDetailsStore;
