import React, {Component} from 'react';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import BrazilIcon from '../../../assets/svgs/brazil.svg';
import SpainIcon from '../../../assets/svgs/spain.svg';
import UnitedStatesIcon from '../../../assets/svgs/united-states.svg';
import {inject, observer} from 'mobx-react';
import {toJS} from 'mobx';

const styles = (theme: Theme) =>
  createStyles({
    default: {
      backgroundColor: "#fff",
      padding: "0",
      boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.5)",
      position: "absolute",
      top: "64px",
      right: "0",
      marginRight: "5vw",
      width: "129px",
      height: "36px",
      alignItems: "center",
      display: "flex"
    },
    radioContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "space-around",
      flexDirection: "row"
    }
});

interface IProps {
  stores?: any,
  classes: any
}

@inject('stores')
@observer
class IntlBar extends Component<IProps> {
  public onChangeLocale = (locale: string) => {
    const {stores} = this.props;
    stores.intlConfigStore.setLanguage(locale);
    stores.intlBarStore.setLocale(locale);
  }
  
  public render() {
    const {classes, stores} = this.props;
    return (
      <div className={classes.default}>
        <div className={classes.radioContainer}>
          <img src={BrazilIcon} alt="Português" style={toJS(stores.intlBarStore.ptBR_class)} onClick={() => this.onChangeLocale('pt-BR')} />
          <img src={SpainIcon} alt="Spanish"  style={toJS(stores.intlBarStore.es_class)} onClick={() => this.onChangeLocale('es')}/>
          <img src={UnitedStatesIcon} alt="English" style={toJS(stores.intlBarStore.en_class)} onClick={() => this.onChangeLocale('en-US')}/>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(IntlBar);