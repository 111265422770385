import React from 'react';
import { createStyles, Theme, withStyles, Paper, InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      marginBottom: '1rem',
    },
    input: {
      paddingLeft: theme.spacing(1),
      marginLeft: theme.spacing(1),
      flex: 1,
      fontSize: '0.84rem',
    },
    icon: {
      fontSize: '2.6rem',
      padding: 10,
      background: theme.palette.secondary.main,
      color: 'white',
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
  });

interface IProps {
  classes?: any;
  placeholder?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchBox = ({ classes, placeholder, value, onChange }: IProps) => (
  <Paper className={classes.root}>
    <InputBase
      className={classes.input}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
    <SearchIcon className={classes.icon} />
  </Paper>
);

export default withStyles(styles)(SearchBox);
