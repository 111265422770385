import RegisterAPIService from '../services/RegisterAPIService';

class RegisterAPIUseCase {
  public execute = async (rootId: string, data: {}, subscription: string, region: string) => {
    const registerAPIService = new RegisterAPIService();
    const result = await registerAPIService.registerApis(rootId, data, subscription, region);
    return result;
  }

}

export default RegisterAPIUseCase;