import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { EPaths } from '../../../core/lib/settings/Constants';
import Login from '../components/general/login/Login';
import ProviderHome from '../components/provider/home/ProviderHome';
import ProviderManager from '../components/provider/manager/ProviderManager';
import ProviderRequests from '../components/provider/requests/ProviderRequests';
import ProviderBlocks from '../components/provider/blocks/ProviderBlocks';
import SelectSubscriptionModal from '../components/general/selectSubscriptionModal/SelectSubscriptionModal';
import Header from '../components/general/headers/Header';

const title = "Provider - Open API Portal"
const ProviderRoutes = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Provider - Open API Portal</title>
      </Helmet>
      <Login title={title}>
        <SelectSubscriptionModal>
          <Header title={title} />
          <Switch>
            <Route exact={true} path={EPaths.ROOT} component={ProviderHome} />
            <Route path={EPaths.LOGOUT} component={ProviderHome} />
            <Route path={EPaths.AUTH} component={ProviderHome} />
            <Route path={`${EPaths.API_MANAGER}/:id`} component={ProviderManager} />
            <Route exact={true} path={EPaths.REQUESTS} component={ProviderRequests} />
            <Route exact={true} path={EPaths.BLOCKS} component={ProviderBlocks} />
          </Switch>
        </SelectSubscriptionModal>
      </Login>
    </React.Fragment>
  )
};

export default ProviderRoutes;
