import Api from '../frameworks/api/Api';

class RequestListService {
  private Api = new Api();

  public loadRequests = (
    status: string,
    subscription: string,
    region: string) => {
    return this.Api.get("access/request", {
      status,
      subscription,
      region
    })
  }
}

export default RequestListService;
