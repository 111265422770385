import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { intercept } from 'mobx';
import { createStyles, Theme, withStyles, Typography, Box, Grid } from '@material-ui/core';

import Stores from '../../../../../core/lib/stores/Stores';
import Error from '../../general/error/Error';
import Loading from '../../general/loading/Loading';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import ProviderAPIListUseCase from '../../../../../core/lib/useCases/ProviderAPIListUseCase';
import { IProviderAPIList } from '../../../../../core/lib/entities/IProviderAPIList';
import APIBlocks from '../../general/apiblocks/APIBlocks';
import ConsumerListUseCase from '../../../../../core/lib/useCases/ConsumerListUseCase';
import { IConsumer } from '../../../../../core/lib/entities/IConsumer';
import ConsumerBlocks from '../../general/consumerblocks/ConsumerBlocks';
import SearchBox from '../../general/searchbox/SearchBox';
import NavMenu from '../../general/navmenu/NavMenu';
import BlockConsumerUseCase from '../../../../../core/lib/useCases/BlockConsumerUseCase';
import BlockApiUseCase from '../../../../../core/lib/useCases/BlockApiUseCase';

const styles = (theme: Theme) =>
  createStyles({
    sectionTitle: {
      marginBottom: '1em',
    },
    apiHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    apiHeaderButton: {
      marginRight: '1rem',
    },
  });

interface IProps {
  classes?: any;
  stores: Stores;
  intl: IntlShape;
}

@inject('stores')
@observer
class ProviderBlocks extends Component<IProps> {
  public state = { };

  private providerAPIListUseCase = new ProviderAPIListUseCase();
  private consumerListUseCase = new ConsumerListUseCase();
  private blockConsumerUseCase = new BlockConsumerUseCase();
  private blockApiUseCase = new BlockApiUseCase();

  public componentDidMount() {
    const { stores } = this.props
    intercept(stores.headerContextStore, "selectedSubscription", (change: any) => {
      this.loadData(change.newValue)
      return change
    })
    
    intercept(stores.headerContextStore, "selectedRegion", (change: any) => {
      this.loadData(undefined, change.newValue)
      return change
    })
    this.loadData();
  }

  public async loadData(newSubscription?: string, newRegion?: string) {
    const { stores } = this.props;
    stores.providerBlocksStore.loadingStart();
    const { selectedSubscription, selectedRegion} = stores.headerContextStore

    const results = await Promise.all([
      await this.providerAPIListUseCase.execute(
        newSubscription || selectedSubscription, 
        newRegion || selectedRegion),
      await this.consumerListUseCase.execute(),
    ]);

    console.log("RESULTS", results)
    if (results[0].ok && results[1].ok) {
      stores.providerBlocksStore.loadingSuccess(
        results[0].data as IProviderAPIList[],
        results[1].data as IConsumer[]
      );
    } else {
      stores.providerBlocksStore.loadingError(results);
    }
  }

  public consumerFilter = (consumer: IConsumer) => {
    const { stores } = this.props;
    const store = stores.providerBlocksStore;

    if (!store.consumerSearchTerm) {
      return true;
    }

    const terms = store.consumerSearchTerm.split(' ');
    const regex = new RegExp(`(${terms.join('|')})`, 'i');
    return regex.test(consumer.name);
  };

  public handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { stores } = this.props;
    const store = stores.providerBlocksStore;

    store.changeConsumerSearchTerm(event.target.value);
  };

  public handleLockConsumer = async (consumer: IConsumer, locked: boolean) => {
    const { stores } = this.props;

    await this.blockConsumerUseCase.execute(consumer.consumerId, locked);

    const result = await this.consumerListUseCase.execute();
    if (result.ok) {
      stores.providerBlocksStore.updateConsumers(result.data as IConsumer[]);
    } else {
      stores.providerBlocksStore.loadingError(result);
    }
  };

  public handleLockApi = async (apiId: string, locked: boolean, apiContext: string) => {
    const { stores } = this.props;
    const { selectedSubscription, selectedRegion} = stores.headerContextStore

    await this.blockApiUseCase.execute(apiId, locked, selectedSubscription, selectedRegion, apiContext);

    const result = await this.providerAPIListUseCase.execute(selectedSubscription, selectedRegion);
    if (result && result.ok) {
      stores.providerBlocksStore.updateApis(result.data as IProviderAPIList[]);
    } else {
      stores.providerBlocksStore.loadingError(result);
    }
  };

  public render() {
    const { classes, stores, intl } = this.props;
    const store = stores.providerBlocksStore;

    if (store.loading) {
      return <Loading />;
    }

    if (store.error) {
      return <Error />;
    }

    return (
      <NavMenu>
        <Grid container spacing={3} className={classes.content}>
          <Grid item xs={12} md={6}>
            <Typography variant="h2" className={classes.sectionTitle}>
              <FormattedMessage id="blocks.globalBlocks" />
            </Typography>

            <Box>
              {store.apis &&
                store.apis.map(api => (
                  <APIBlocks apiContext={api.context} key={api.id} api={api} onLockApi={this.handleLockApi} />
                ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h2" className={classes.sectionTitle}>
              <FormattedMessage id="blocks.consumerBlocks" />
            </Typography>

            <SearchBox
              placeholder={intl.formatMessage({ id: 'blocks.searchConsumers' })}
              value={store.consumerSearchTerm}
              onChange={this.handleSearchChange}
            />

            <Box>
              {store.consumers &&
                store.consumers
                  .filter(this.consumerFilter)
                  .map(consumer => (
                    <ConsumerBlocks
                      key={consumer.consumerId}
                      apis={store.apis}
                      consumer={consumer}
                      onLockConsumer={this.handleLockConsumer}
                    />
                  ))}
            </Box>
          </Grid>
        </Grid>
      </NavMenu>
    );
  }
}

export default injectIntl(withStyles(styles)(ProviderBlocks));
