import React, { Component } from 'react';
import { createStyles, Theme, CssBaseline, withStyles } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { HelmetProvider } from 'react-helmet-async';

import { APP_BASE, EAppBase } from './core/lib/settings/Constants';
import { mainTheme } from './web/src/assets/themes/Theme';
import AmplifyVocabularies from './core/lib/frameworks/translations/amplify/AmplifyVocabularies';
import ProviderRoutes from './web/src/routes/ProviderRoutes';
import ConsumerRoutes from './web/src/routes/ConsumerRoutes';

const styles = (theme: Theme) => createStyles({});

interface IProps {
  stores?: any;
  classes?: any;
}

@inject('stores')
@observer
class App extends Component<IProps> {
  public componentDidMount() {
    AmplifyVocabularies.config();
  }

  public render() {
    const components: { [key: string]: any } = {
      [EAppBase.CONSUMER]: ConsumerRoutes,
      [EAppBase.PROVIDER]: ProviderRoutes,
    };

    const RootComponent: any = components[APP_BASE] || components[EAppBase.CONSUMER];

    return (
      <ThemeProvider theme={mainTheme}>
        <CssBaseline />

        <IntlProvider
          locale={this.props.stores.intlConfigStore.locale}
          messages={this.props.stores.intlConfigStore.messages}
        >
          <HelmetProvider>
            <Router>
              <RootComponent />
            </Router>
          </HelmetProvider>
        </IntlProvider>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(App);
