import React, { Component } from 'react';
import { createStyles, Theme, withStyles, Link, Button, Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps, Color } from '@material-ui/lab/Alert';
import { inject, observer } from 'mobx-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Error from '../../general/error/Error';
import Loading from '../../general/loading/Loading';
import { EPaths } from '../../../../../core/lib/settings/Constants';
import { RouteComponentProps } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import ApiDetailsUseCase from '../../../../../core/lib/useCases/APIDetailsUseCase';
import { IAPIDetails, IAPIStage } from '../../../../../core/lib/entities/IAPIDetails';
import StageManager from '../../general/stagemanager/StageManager';
import InfoBox from '../../general/infoBox/InfoBox'
import uuid from 'uuid';
import StageManagerStore from '../../../../../core/lib/stores/StageManagerStore';
import SaveStageConfigUseCase from '../../../../../core/lib/useCases/SaveStageConfigUseCase';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      width: "75%",
      flexGrow: 1,
      margin: "3rem auto",
      backgroundColor: "#eaeded",
      marginTop: "100px",
      marginBottom: "20px"
    },
    content: {
      width: "100vw",
      display: "flex",
      flexDirection: "column",
      overflow: "hidden auto",
      alignItems: "center"
    },
    register: {
      margin: "2rem 0"
    },
    linkBack: {
      color: theme.palette.primary.light,
      fontSize: "12px",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline"
      }
    },
    appName: {
      fontSize: "22px",
      fontWeight: "bold",
      marginTop: "20px"
    },
    apititle: {
      display: "flex",
      marginTop: "30px",
      fontSize: "26px",
      fontWeight: "bold"
    },
    apidescription: {
      marginTop: "10px",
      fontSize: "13px",
      color: "#555"
    },
    apistagestitle: {
      marginTop: "40px",
      fontSize: "20px"
    },
    tabselection: {
      display: "flex",
      flexDirection: "row",
      marginTop: "20px",
      backgroundColor: "orange",
      borderRadius: "4px"
    },
    tabContainer: {
      flex: "1"
    },
    tabbuttonoff: {
      width: "100%",
      fontSize: "18px",
      color: "#fff",
      borderBottom: "solid 2px orange",
      fontWeight: "normal"
    },
    tabbuttonon: {
      width: "100%",
      fontSize: "18px",
      color: "#000",
      borderBottom: "solid 2px #000",
      fontWeight: "bold"
    },
    tabcontent: {
      backgroundColor: "#fff",
      width: "100%",
      padding: "20px",
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px",
      marginBottom: "80px"
    }
  });

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface IProps extends RouteComponentProps<{ id: string }> {
  intl: any,
  classes: any,
  stores: any,
  location: any,
}

@inject("stores")
@observer
class ProviderManager extends Component<IProps> {
  public state = {
    openedAlert: false,
    messageAlert: "",
    severityAlert: "success"
  }

  public componentDidMount() {
    this.loadAPIDetails()
  }

  private renderTabs = (stage: IAPIStage) => {
    const { classes, stores } = this.props

    return (
      <div>
        <Button
          className={(stores.providerManagerStore.selectedStage.stageName === stage.stageName) ? classes.tabbuttonon : classes.tabbuttonoff}
          onClick={() => this.loadTab(stage)}>
          {stage.stageName}
        </Button>
      </div>
    )
  }

  private openAlert = (message: string, severity: string = "success") => {
    this.setState({
      openedAlert: true,
      messageAlert: message,
      severityAlert: severity
    })
  };

  private closeAlert = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ openedAlert: false })
  };
  private handleSaveData = async (data: StageManagerStore) => {
    const { stores, intl } = this.props
    const { selectedSubscription } = stores.headerContextStore

    var payload = this.buildCurrentStage(data);

    const useCase = new SaveStageConfigUseCase()
    const result = await useCase.execute(this.props.match.params.id, payload, selectedSubscription, this.region())

    if (result.ok) {
      this.openAlert(intl.formatMessage({ id: 'apidata.save.success' }))
      this.loadAPIDetails()
    } else {
      this.openAlert(intl.formatMessage({ id: 'apidata.save.error' }), "error")
    }
  }

  private buildCurrentStage = (data: StageManagerStore) => {
    const { stores } = this.props
    const stageName = stores.providerManagerStore.selectedStage.stageName
    const apiContext = this.apiContext();

    if (data.methodvalues.length === 0) {
      return { stageName: stageName, usagePlans: Array<any>(), methods: Array<any>(), context: apiContext }
    } else {
      var usagePlans = Array<any>()

      data.plans.map(p => {
        usagePlans.push({ usagePlanId: p.id })
        return null
      })

      var methods = Array<{ httpMethod: string; resourceId: string; usagePlans: Array<{ usagePlanId: string; value: number }> }>()

      data.methodvalues.map(m => {
        const [httpMethod, resourceId, planId] = m.key.split("|")
        const value = (m.value as any) === '' ? null : Number(m.value)

        const getMethod = () => {
          const exists = methods.find(m => m.resourceId === resourceId && m.httpMethod === httpMethod)
          if (exists)
            return exists
          return null
        }

        const method = getMethod()
        const plans = (method && method.usagePlans) || new Array<{ usagePlanId: string; value: number }>()

        if (value != null) {
          plans.push({
            usagePlanId: planId,
            value
          })
        }

        if (!method) {
          methods.push({
            httpMethod: httpMethod,
            resourceId: resourceId,
            usagePlans: plans
          })
        }
      })

      return {
        stages: [
          {
            stageName: stageName,
            usagePlans: usagePlans,
            methods: methods
          }
        ],
        context: apiContext,
      }
    }
  }

  private loadTab = (stage: IAPIStage) => {
    const { stores } = this.props
    stores.providerManagerStore.setSelectedStage(stage)
  }

  public loadAPIDetails = async () => {
    const { stores } = this.props
    const { selectedSubscription } = stores.headerContextStore
    stores.providerManagerStore.setLoading(true)

    const appId = this.props.match.params.id
    const apiContext = this.apiContext();
    const region = this.region();

    const useCase = new ApiDetailsUseCase()
    const result = await useCase.execute(appId, selectedSubscription, region, apiContext)

    if (result.ok) {
      stores.providerManagerStore.setSuccess(true)
      stores.providerManagerStore.setAPIDetails(result.data as IAPIDetails)
    } else {
      stores.providerManagerStore.setSuccess(false)
    }

    stores.providerManagerStore.setLoading(false)
  }

  getLocationState = (propName: string) => {
    if (this.props.location &&
      this.props.location.state)
      return this.props.location.state[propName]
    return ""
  }

  public apiContext = () => this.getLocationState("apiContext")
  public subscription = () => this.getLocationState("subscription")
  public region = () => this.getLocationState("region")

  public render() {
    const { classes, stores } = this.props
    const { openedAlert, messageAlert, severityAlert } = this.state;

    if (stores.providerManagerStore.isLoading) {
      return <Loading />
    }

    if (!stores.providerManagerStore.success) {
      return <Error />;
    }
    console.log(openedAlert, messageAlert, severityAlert)

    return (
      <div className={classes.container}>
        <Snackbar
          open={openedAlert}
          autoHideDuration={6000}
          onClose={this.closeAlert}
        >
          <Alert onClose={this.closeAlert} severity={severityAlert as Color}>
            {messageAlert}
          </Alert>
        </Snackbar>
        <Link
          className={classes.linkBack}
          component={RouterLink}
          to={EPaths.ROOT}>
          &lt;&nbsp;<FormattedMessage id="backbutton" />
        </Link>
        <div className={classes.apititle}>
          {stores.providerManagerStore.apiDetails.name}
          {
            this.subscription() &&
            <InfoBox
              margin="0px 0px 0px 20px"
              content={this.subscription()}
              styleType="subscription"
            ></InfoBox>
          }
          {
            stores.providerManagerStore.apiDetails.region &&
            <InfoBox
              margin="0px 0px 0px 20px"
              content={stores.providerManagerStore.apiDetails.region}
              styleType="region"
            ></InfoBox>
          }
        </div>
        <div className={classes.apidescription}>
          {stores.providerManagerStore.apiDetails.description !== undefined && stores.providerManagerStore.apiDetails.description !== "" && (stores.providerManagerStore.apiDetails.description)}
          {stores.providerManagerStore.apiDetails.description === undefined && (<div><FormattedMessage id="api.nodescription" /></div>)}
        </div>
        <div className={classes.apistagestitle}>
          <FormattedMessage id="apidata.stages" />
        </div>
        <div className={classes.tabselection}>
          {
            stores.providerManagerStore.apiDetails.stages &&
            stores.providerManagerStore.apiDetails.stages.map((stage: any) => (
              <div className={classes.tabContainer} key={stage.stageName}>{this.renderTabs(stage)}</div>
            ))
          }
        </div>
        <div className={classes.tabcontent}>
          <StageManager
            key={uuid()}
            stage={stores.providerManagerStore.selectedStage}
            appId={this.props.match.params.id}
            handleSave={this.handleSaveData} />
        </div>
      </div>
    )
  }
}

export default injectIntl(withStyles(styles)(ProviderManager));
