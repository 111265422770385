import ConsumerService from '../services/ConsumerService';
import IApplication from '../entities/IApplication';
import { ApiResponse } from 'apisauce';

class ConsumerApplicationsListUseCase {
  public execute = async (consumerId: string) => {
    const service = new ConsumerService();
    const result = await service.getConsumerApplications(consumerId);
    return result as ApiResponse<IApplication[], any>;
  };
}

export default ConsumerApplicationsListUseCase;
