import Api from '../frameworks/api/Api';

class ApplicationDetailsService {
  private Api = new Api();

  public getApplicationDetails = (appId: string, headers?: any) => {
    return this.Api.get(`application/${appId}`, null, headers);
  }
}

export default ApplicationDetailsService;