import React, {Component} from 'react';
import { createStyles, Theme, withStyles, Dialog, Select, MenuItem } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CardExportType from './CardExportType';
import DownloadDocUseCase from '../../../../../core/lib/useCases/DownloadDocUseCase';
import { FormattedMessage } from 'react-intl';
import DownloadManager from '../../../../../core/lib/frameworks/downloadmanager/DownloadManager';

interface IProps {
  appId: string,
  stage?: string,
  isOpen: boolean,
  handleClose: () => void,
  classes?: any
}

const styles = (theme: Theme) =>
  createStyles({
    dialogTitle: {
      height: "44px",
      backgroundColor: "#222e3e",
      textAlign: "center",
      color: "#ffffff",
      alignItems: "center",
      justifyContent: "center",
      display: "flex"
    },
    dialogContainer: {
      width: "100%",
      backgroundColor: "#d8d8d8"
    },
    labelTop: {
      display: "flex",
      fontSize: "18px",
      color: "#ffffff",
      width: "100%",
      justifyContent: "center"
    },
    content: {
      height: "300px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "16px",
      fontWeight: "bold"
    },
    buttonDef: {
      borderRadius: "5px",
      margin: "8px",
      border: "solid 1px #ad8d40",
      boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.75)",
      height: "60%",
      fontSize: "12px",
      width: "163px",
      textTransform: "capitalize",
      backgroundImage: "linear-gradient(to bottom, #ffffff, #f7dea0 9%, #f1c24f)",
      "&:hover": {
        boxShadow: "1px 1px 3px #ccc"
      }
    },
    footerActions: {
      display: "flex",
      justifyContent: "center"
    },
    containercontent: {
      width: "550px",
      minHeight: "270px",
      backgroundColor: "#fff",
      boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.5)",
      marginTop: "10px",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column"
    },
    exporttype: {
      fontSize: "22px",
      fontWeight: "normal",
      marginTop: "20px",
      marginLeft: "20px",
      display: "flex",
      flexDirection: "row"
    },
    selecttype: {
      marginLeft: "20px"
    },
    exportypes: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-around"
    }
  });

class DocsModal extends Component<IProps> {
  public state = {selectedFormat: "swagger"};

  private changeType = (event: any) => {
    this.setState({selectedFormat: event.target.value});
  }

  public onClickExport = async (format: string, extensions: string) => {
    const {appId, stage} = this.props;
    const {selectedFormat} = this.state;

    const useCase = new DownloadDocUseCase();
    const result = await useCase.execute(appId, stage!, selectedFormat, extensions, format);

    let data = result.data;

    if (format === "json") {
      data = JSON.stringify(data);
    }

    const downloadManager = new DownloadManager();
    downloadManager.download(data as string, `${selectedFormat}-${extensions}.${format}`);
  }

  public render() {
    const {classes, isOpen, handleClose} = this.props;

    return (
      <Dialog classes={{paper: classes.dialogContainer}} open={isOpen} fullWidth={true}>
          <DialogTitle className={classes.dialogTitle}>
          <div className={classes.labelTop}>
            <FormattedMessage id="api.exporttitle" /> 
          </div>
        </DialogTitle>

        <DialogContent className={classes.content}>
          <div className={classes.containercontent}>
            <div className={classes.exporttype}>
              <FormattedMessage id="api.exportwith" /> 
              <Select
                value={this.state.selectedFormat}
                className={classes.selecttype}
                onChange={this.changeType}>
                  <MenuItem value={"swagger"}>Swagger</MenuItem>
                  <MenuItem value={"oas30"}>OpenAPI3</MenuItem>
              </Select>
            </div>
            <div className={classes.exportypes}>
              <CardExportType
                onClickButtonCard={this.onClickExport}
                icon={"apigateway"} />

              <CardExportType
                onClickButtonCard={this.onClickExport}
                icon={"postman"} />
            </div>
          </div>
        </DialogContent>

        <DialogActions className={classes.footerActions}>
          <Button
            className={classes.buttonDef}
            onClick={handleClose}>
            Ok
          </Button>
        </DialogActions>

      </Dialog>
    );
  }
}

export default withStyles(styles)(DocsModal);