import Api from '../frameworks/api/Api';

class AppResetPasswordService { 
  private Api = new Api();

  public reset = (applicationId: string) => {
    return this.Api.get(`application/${applicationId}/credential/reset/password`);
  }
}

export default AppResetPasswordService;