import React, {Component} from 'react';
import {observer} from 'mobx-react';
import { createStyles, Theme, withStyles, Dialog, Select, MenuItem } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import Loading from '../../general/loading/Loading';
import Error from '../../general/error/Error';
import DownloadManager from '../../../../../core/lib/frameworks/downloadmanager/DownloadManager';
import DownloadSdkUseCase from '../../../../../core/lib/useCases/DownloadSdkUseCase';
import SDKTypesUseCase from '../../../../../core/lib/useCases/SDKTypesUseCase';
import { ISDKType } from '../../../../../core/lib/entities/ISDKType';
import SdkDownloaderStore from '../../../../../core/lib/stores/SdkDownloaderStore';
import base64decode from '../../../../../core/lib/utils/base64decode';

const styles = (theme: Theme) =>
  createStyles({
    dialogTitle: {
      height: "44px",
      backgroundColor: "#222e3e",
      textAlign: "center",
      color: "#ffffff",
      alignItems: "center",
      justifyContent: "center",
      display: "flex"
    },
    dialogContainer: {
      width: "100%",
      backgroundColor: "#d8d8d8"
    },
    labelTop: {
      display: "flex",
      fontSize: "18px",
      color: "#ffffff",
      width: "100%",
      justifyContent: "center"
    },
    content: {
      minHeight: "250px",
      margin: '1rem',
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "16px",
      fontWeight: "bold"
    },
    buttonCancel: {
      borderRadius: "5px",
      margin: "8px",
      border: "1px solid #ADB1B8",
      boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.75)",
      height: "60%",
      fontSize: "12px",
      width: "163px",
      textTransform: "capitalize",
      backgroundImage: "linear-gradient(to bottom, #fcfcfd, #f5f6f8 9%, #c4c5c6)",
      "&:hover": {
        boxShadow: "1px 1px 3px #ccc"
      }
    },
    buttonPrimary: {
      borderRadius: "5px",
      margin: "8px",
      border: "solid 1px #ad8d40",
      boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.75)",
      height: "60%",
      fontSize: "12px",
      width: "163px",
      textTransform: "capitalize",
      backgroundImage: "linear-gradient(to bottom, #ffffff, #f7dea0 9%, #f1c24f)",
      "&:hover": {
        boxShadow: "1px 1px 3px #ccc"
      }
    },
    footerActions: {
      display: "flex",
      justifyContent: "center"
    },
    containercontent: {
      width: "100%",
      padding: '1rem',
      minHeight: "220px",
      backgroundColor: "#fff",
      boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.5)",
      marginTop: "10px",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column",
      fontWeight: "normal",
      textAlign: "center",
      alignItems: "center",
      justifyContent: "center"
    },
    langtitle: {
      fontWeight: "bold",
      fontSize: "22px",
      marginBottom: "10px"
    },
    form: {
      width: '100%',
      padding: '1rem'
    },
    formcontrol: {
      width: '100%',
      textAlign: 'left'
    },
    errorMessage: {
      color: theme.palette.error.main
    }
  });

interface IProps {
  classes?: any,
  handleClose: () => void,
  appId: string,
  isOpen: boolean,
  stageName: string
}

@observer
class SDKDownloaderModal extends Component<IProps> {
  private store = new SdkDownloaderStore()
  private sdkTypesUseCase = new SDKTypesUseCase()
  private downloadSdkUseCase = new DownloadSdkUseCase()
  private downloadManager = new DownloadManager()

  private async loadSdkTypes() {
    this.store.loadingStart()

    const result = await this.sdkTypesUseCase.execute()

    if (result.ok) {
      const data = result.data as ISDKType

      this.store.setAvailableSdks(data.items);

      this.store.loadingComplete()
    }
    else {
      this.store.loadingError()
    }
  }

  private onChangeSdk = (event: any) => {
    this.store.setSelectedSdkId(event.target.value)
  }

  private onClickDownload = async () => {
    const { appId, stageName } = this.props
    const { selectedSdk, parameterValues } = this.store

    if (!selectedSdk) {
      return null;
    }

    this.store.downloadingStart()

    const result = await this.downloadSdkUseCase
      .execute(appId, stageName, selectedSdk.id, parameterValues)

    if (result.ok) {
      await this.downloadManager.download(base64decode(result.data as string), `${appId}-${stageName}-${selectedSdk.id}.zip`)
      this.store.downloadingCompleted()
    }
    else {
      this.store.downloadingError()
    }
  }

  private renderOptions = () => {
    const { availableSdks } = this.store

    return availableSdks.map(sdk => (
      <MenuItem
        key={sdk.id}
        value={sdk.id}
      >
        {sdk.friendlyName}
      </MenuItem>
    ));
  }

  private renderParameters = () => {
    const { classes } = this.props
    const { selectedSdk, parameterValues, setParameterValue } = this.store

    if (!selectedSdk || !selectedSdk.configurationProperties) {
      return null;
    }

    return selectedSdk.configurationProperties.map(param => (
      <Grid
        key={param.name}
        item
        xs={6}
      >
        <FormControl className={classes.formcontrol}>
          <TextField
            label={`${param.friendlyName}${param.required ? '*' : ''}`}
            value={parameterValues.get(param.name) || ''}
            onChange={(event: any) => setParameterValue(param.name, event.target.value)}
          />
        </FormControl>
      </Grid>
    ));
  }

  public componentDidMount() {
    if (this.props.isOpen) {
      this.loadSdkTypes()
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.loadSdkTypes()
    }
  }

  public render() {
    const { classes, isOpen, handleClose } = this.props
    const { loading, error, downloading, downloadError, selectedSdkId, readyForDownload } = this.store

    return (
      <Dialog classes={{paper: classes.dialogContainer}} open={isOpen} fullWidth={true}>
        <DialogTitle className={classes.dialogTitle}>
          <div className={classes.labelTop}>
            <FormattedMessage id="api.downloadsdk" />
          </div>
        </DialogTitle>

        <DialogContent className={classes.content}>
          {loading && <Loading />}
          {error && <Error />}
          {!loading && !error && (
            <div className={classes.containercontent}>
              <div className={classes.langtitle}>
                <FormattedMessage id="sdk.info" />
              </div>

              {downloadError && (
                <p className={classes.errorMessage}>
                  <FormattedMessage id="sdk.downloadError" />
                </p>
              )}

              <div className={classes.form}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <FormControl className={classes.formcontrol}>
                      <InputLabel id="sdk-type">
                        <FormattedMessage id="sdk.type" />
                        <span>*</span>
                      </InputLabel>
                      <Select
                        labelId="sdk-type"
                        onChange={this.onChangeSdk}
                        value={selectedSdkId}
                      >
                        {this.renderOptions()}
                      </Select>
                    </FormControl>
                  </Grid>

                  {this.renderParameters()}
                </Grid>
              </div>
            </div>
          )}
        </DialogContent>

        <DialogActions className={classes.footerActions}>
          <Button
            className={classes.buttonCancel}
            onClick={handleClose}
          >
            <FormattedMessage id="api.btncancel" />
          </Button>
          <Button
            className={classes.buttonPrimary}
            disabled={!readyForDownload || downloading}
            onClick={this.onClickDownload}
          >
            {downloading
              ? <FormattedMessage id="sdk.preparing" />
              : <FormattedMessage id="api.downloadsdk" />}
          </Button>
        </DialogActions>

      </Dialog>
    );
  }
}

export default withStyles(styles)(SDKDownloaderModal);
