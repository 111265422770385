import { I18n } from '@aws-amplify/core';

class AmplifyVocabularies {
  public dict = {
      'pt-BR': {
          'Sign In': 'Entrar',
          'Sign Up': 'Cadastrar-se',
          'Username': 'Nome de Usuário',
          'Sign in to your account': 'Entrar na sua conta',
          'Enter your username': "Digite o seu nome de usuário",
          'Password': 'Senha',
          'Enter your password': 'Digite sua senha',
          'Forget your password': 'Esqueceu sua senha ?',
          'Reset password': 'Recuperar senha',
          'Create account': 'Criar conta',
          'Phone Number': 'Número do celular',
          'Create a new account': 'Criar uma conta nova',
          'Create Account': 'Criar conta',
          'Sign in': 'Fazer login',
          'Reset your password': 'Recuperação de senha',
          'Back to Sign In': 'Voltar para login',
          'Send Code': 'Enviar código',
          'Forget your password? ': 'Esqueceu sua senha? ',
          'No account? ': 'Não tem uma conta? ',
          'Have an account? ': 'Já tem uma conta? ',
          'Username cannot be empty': 'Informe seu nome de usuário e senha',
          'User does not exist': 'Usuário não cadastrado',
          'Incorrect username or password': 'Nome de usuário ou senha inválidos',
          'null invocation failed due to configuration.': 'Não foi possível fazer o login. Verifique seus dados.'
      },
      'es': {
        'Sign In': 'Iniciar sesión',
        'Sign Up': 'Regístrate',
        'Username': 'Nombre de usuario',
        'Sign in to your account': 'Iniciar sesión en su cuenta',
        'Enter your username': "Ingrese su nombre de usuario",
        'Password': 'Contraseña',
        'Enter your password': 'Ingresa tu contraseña',
        'Reset password': 'Restablecer la contraseña',
        'Create account': 'Crear cuenta',
        'Phone Number': 'Número de teléfono',
        'Create a new account': 'Crea una cuenta nueva',
        'Create Account': 'Crear cuenta',
        'Sign in': 'Iniciar sesión',
        'Reset your password': 'Restablecer su contraseña',
        'Back to Sign In': 'Regresar a Iniciar sesión',
        'Send Code': 'Enviar código',
        'Forget your password? ': '¿Olvidaste tu contraseña? ',
        'No account? ': '¿No tienes una cuenta? ',
        'Have an account? ': '¿Ya tienes una cuenta? ',
        'Username cannot be empty': 'Ingrese su nombre de usuario y contraseña',
        'User does not exist': 'Usuario no registrado',
        'Incorrect username or password': 'Nombre de usuario o contraseña inválidos',
        'null invocation failed due to configuration.': 'No se puede iniciar sesión. Verifica tus datos.'
      }
  };

  public config() {
    I18n.putVocabularies(this.dict);
  }
  
}

export default new AmplifyVocabularies();